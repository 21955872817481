.container {
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--content-primary-a);
}

.lottie {
  width: 200px;
  height: 200px;
}
