.item {
  background-color: var(--elevation-low);
  border-radius: 8px;
}

.summary {
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  padding: 16px 16px 16px 32px;
}

.handle {
  position: absolute;
  top: 22px;
  left: 8px;
}

.title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  padding: 0 32px 16px 32px;
}

.quantity-amount {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}

.quantity-amount > div {
  flex: 1;
}

.quantity-input {
  flex: 1;

  > input {
    margin-right: -1px;
    border-right-width: 0;
    border-radius: 4px 0 0 4px;

    &:focus {
      border-radius: 4px;
    }

    &:focus-within {
      z-index: 1;
      border-right: 1px solid var(--border-accent-high);
    }
  }
}

.unit-select {
  flex: 1;

  /* Compensate for the lack of label */
  margin-top: 28px;

  input {
    width: 100%;
    border-radius: 0 4px 4px 0;

    &:focus {
      border-radius: 4px;
    }

    &:focus-within {
      z-index: 1;
      border-right: 1px solid var(--border-accent-high);
    }
  }
}

.product-new {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-new-icon {
  flex-shrink: 0;
}

.product-new-label {
  display: flex;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-new-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loading-option::after {
  display: none;
}

.product {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.product-title {
  flex: 1;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-price {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.product-missing-details-container {
  display: inline-flex;
  align-items: center;
}

.product-missing-details-label {
  composes: body-2 from global;
  margin-right: 8px;
  color: var(--content-secondary);
}

.warning-icon {
  width: 12px;
  height: 12px;
  color: var(--content-warning);
}

.product-internal-note {
  flex-basis: 100%;
  overflow: hidden;
  color: var(--content-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
}
