.tooltip {
  cursor: default;

  &.with-margin {
    margin-right: 12px;
    margin-bottom: 7px;
  }
}

.content {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.content > span {
  display: inline-block;
}

.icon {
  position: relative;
  color: var(--content-tertiary);
}
