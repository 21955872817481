.illustration {
  position: relative;
  z-index: -1;
  margin: -40px -40px 24px;
  overflow: hidden;
  border-radius: 23px 23px 0 0;
}

.closeButton svg {
  color: var(--content-primary-a-static);
}
