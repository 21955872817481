.trigger {
  padding: 8px 12px;
  font-size: var(--body-1-font-size);
  font-weight: var(--body-1-font-weight);
  line-height: 22px;
  color: var(--content-primary-a);
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
  transition: border-color 0.5s;

  &::placeholder {
    line-height: 24px;
    color: var(--content-tertiary);
    opacity: 1;
  }

  &:not(.trigger--readonly).trigger[data-disabled] {
    cursor: not-allowed;
    background-color: var(--elevation-low);
    opacity: 0.45;
  }

  &.trigger--readonly {
    cursor: default;
    background-color: var(--elevation-low);
  }
}

.trigger[data-invalid='true'] {
  border-color: var(--border-error);
}

.trigger[data-pressed] {
  border-color: var(--border-accent-high);
}

.trigger[data-focused] {
  border-color: var(--border-accent-high);
  box-shadow: 0 0 0 4px var(--border-accent-low);
}
