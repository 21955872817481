.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding-right: 16px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}

.with-hover:hover {
  box-shadow: var(--shadow-high);
}

.with-actions {
  gap: 24px;
}

.beneficiary-card-button {
  &:hover {
    cursor: pointer;
  }

  /* Make the entire card clickable: https://inclusive-components.design/cards/#thepseudocontenttrick */
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
}
