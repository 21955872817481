.timeline {
  --item-margin: 2px;
  --media-size: 16px;
  --media-line-gap: 6px;
  --media-padding: 4px;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style-type: none;
}
