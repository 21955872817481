.bodyLoading {
  display: grid;
  grid-template-columns: 432px 1fr;
  width: 100%;
  overflow: hidden;
}

.bodyContent {
  width: 100%;
  height: 100%;
}

.bodyMembers {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: auto;
  border-right: 1px solid var(--border-tertiary);
}

.mandateListItem {
  display: flex;
  padding: 16px;
}

.placeholder {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  text-align: center;
}

.icon {
  flex-shrink: 0;
  margin-right: 16px;
}

.bodyDetails {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  padding: 32px 48px;
}

.bodyDetailsTitle {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
}

.bodyDetailsHeader {
  padding-top: 8px;
}

.bodyDetailsIdsSubtitle {
  margin-bottom: 48px;
}

.bodyDetailsOverviewSubtitle {
  margin: 80px 0 24px;
}

.bodyDetailsOverviewContent {
  margin-bottom: 48px;
}

.bodyDetailsCtas {
  display: flex;
  justify-content: space-between;
}

.bodyDetailsCtasLeft {
  display: flex;
}

.bodyDetailsCtasRight {
  display: flex;
  float: left;
}

.rightEdit {
  margin-left: 16px;
}
