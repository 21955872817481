.progress-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  width: 100%;
  padding: 0;
}

.progress-labels {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}

.progress-text {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.flew-row {
  display: flex;
  flex-direction: row;
}

.progress-bar {
  width: 100%;
  border-radius: 2px;

  :global(.progress-bar__bar) {
    background: var(--content-warning);
  }
}

.check-icon {
  width: 12px;
  height: 12px;
  margin-top: 4px;
  margin-left: 8px;
}
