.skeleton {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 504px;
  max-width: 504px;
  background-color: var(--elevation-low);
}

.skeletonCvvDate {
  display: flex;
  justify-content: space-between;
}
