.search-input {
  width: 100%;
}

.toolbar-row {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.errorState {
  text-align: center;

  div {
    margin: 0 auto;
  }
}
