.wrapper {
  composes: body-1 from global;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 56px;
}

.content {
  display: flex;
  gap: 16px;
  align-items: center;
}

.avatar {
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.count {
  color: var(--content-secondary);
}
