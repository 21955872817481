.wrapper {
  background: var(--background-secondary);
  border-radius: 4px;
}

.progress-bar {
  height: 4px;
  background: var(--background-primary);
  border-radius: 4px;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.purple {
  background-color: var(--background-accent-high);
}
