.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--elevation-low);
}

.benefits-list {
  display: flex;
  flex-direction: column;
  width: 504px;
  height: 100%;
  min-height: fit-content;
  margin: 80px auto 48px;
}

.btn-keep-current {
  align-self: center;
}
