.icons {
  display: flex;
  align-items: center;
  align-self: end;
  gap: 8px;

  & > img {
    width: 27px;
    height: 20px;
  }
}
