.row {
  display: flex;
  align-items: center;
}

.avatar-image {
  width: 42px;
  height: 42px;
  margin-right: 8px;
  border-radius: 50%;
}

.link {
  text-decoration: none;
}

.name {
  composes: body-2 from global;
  color: var(--content-primary-a);
}

.team {
  composes: body-2 from global;
  color: var(--content-secondary);
}
