.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.illustration {
  width: 240px;
}

.illustration-processing {
  width: 220px;
}

.illustration-error {
  width: 90px;
}
