.illustration {
  width: 120px;
  height: 120px;
}

.illustrationWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 auto 24px;
}

.faqLink {
  a {
    color: var(--content-accent);
  }
}
