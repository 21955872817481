.payment-methods {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payment-methods-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  list-style: none;
}

.payment-method label {
  box-sizing: border-box;
}

.feature-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  list-style: none;
  background-color: var(--elevation-low);
  border-radius: 8px;

  li {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    padding: 0 8px;

    svg {
      flex-shrink: 0;
    }
  }
}

.cta {
  width: fit-content;
  margin-top: 8px;
}
