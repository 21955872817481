.card-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 96px;
  padding: 16px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
}

.card-container:hover {
  box-shadow: var(--shadow-high);
}

.card-container:focus-visible {
  box-shadow: 0 0 0 4px var(--border-accent-low);
}

.application-logo {
  box-sizing: border-box;
  display: block;
  margin-right: 16px;
  overflow: hidden;
  font-size: 16px;
  border-radius: 100%;
}

.application-name {
  overflow: hidden;
}

.application-title {
  composes: title-4 from global;
  overflow: hidden;
  color: var(--content-primary-a);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spinner {
  margin-left: auto;
}
