.amount {
  composes: body-1 from global;
}

.positiveHighlight,
.plus-sign {
  color: var(--content-success);
}

.declined {
  color: var(--content-tertiary);
  text-decoration-line: line-through;
}

.declined .plus-sign {
  color: var(--content-tertiary);
}

.with-local-amount {
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.amount-foreign {
  composes: body-2 from global;
  color: var(--content-secondary);

  .plus-sign {
    color: var(--content-secondary);
  }
}
