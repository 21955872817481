.list-item {
  display: grid;
  grid-template-areas: 'checkmark  detail';
  grid-template-columns: 12px 1fr;
  column-gap: 12px;
  align-items: center;
  color: var(--content-primary-a);
  list-style: none;
}

.checkmark {
  grid-area: checkmark;
  width: 12px;
  height: 12px;
  color: var(--content-secondary);
}
