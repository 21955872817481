.wrapper {
  position: relative;
  z-index: var(--z-index-default);
  display: flex;
  align-items: center;
  background-color: var(--elevation-default);
}

.per-page-container {
  display: flex;
  align-items: center;
}

.options {
  display: flex;
  align-items: center;
  width: auto;
  height: 24px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.option {
  height: 100%;
  padding: 0 8px;
  color: var(--content-primary-a);

  &:first-child,
  &:first-child:hover::after {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
  }

  &:last-child,
  &:last-child:hover::after {
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0;
  }

  &:not(:first-child) {
    border-left: 1px solid var(--border-tertiary);
  }

  &:global(.btn--icon-only) {
    width: 28px;
  }

  &.active {
    background-color: var(--background-tertiary);
  }

  &:focus-visible {
    position: relative;
    border-radius: 4px;
  }
}

.description {
  color: var(--content-primary-a);
}

.description-per-page {
  margin-left: 16px;
}

.summary {
  margin-right: 16px;
  margin-left: auto;
  color: var(--content-primary-a);
}
