.row {
  height: 64px;
  vertical-align: middle;
}

.col {
  border-bottom: 1px solid var(--border-tertiary);
}

.debitor-container {
  display: flex;
  align-items: center;
}

.col-9 {
  width: 37.5%;
}

.col-12 {
  width: 50%;
}

.avatar {
  width: 60px;
  height: 50px;
}
