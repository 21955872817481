.row {
  height: 64px;
  vertical-align: middle;
}

.row:hover {
  cursor: pointer;
  background-color: var(--elevation-low);
}

.row-active {
  background-color: var(--background-tertiary);
}

.row-active:hover {
  cursor: default;
  background-color: var(--elevation-low);
}

.cell {
  composes: body-2 from global;
  padding: 20px 0;
  color: var(--content-primary-a);
  text-align: right;
  white-space: nowrap;
  border-bottom: 1px solid var(--border-tertiary);

  .terminated & {
    color: var(--content-tertiary);
  }
}

.cell:first-child {
  text-align: left;
}

.cell:last-child {
  padding-right: 48px;
}

.amount {
  composes: body-1 from global;

  .terminated & {
    text-decoration: line-through;
  }
}

.item-name-cell {
  padding: 0 0 0 48px;
}

.status-icon {
  width: 32px;
  height: 32px;
}

.item-name-container {
  display: flex;
  align-items: center;
  max-width: 390px;
  padding: 0 25px 0 0;
  text-overflow: ellipsis;
}

.item-info {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin-left: 12px;
  overflow: hidden;
}

.name {
  composes: body-1 from global;
  overflow: hidden;
  color: var(--content-primary-a);
  text-overflow: ellipsis;
  white-space: nowrap;

  .terminated & {
    color: var(--content-tertiary);
  }
}

.status-displayed {
  composes: caption from global;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;

  .terminated & {
    color: var(--content-tertiary);
  }
}
