.container {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

.datePicker {
  width: 137px;
}
