.list-item {
  .image {
    background-image: var(--icon-light-path);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.list-item.not-available {
  color: var(--content-secondary);
  text-decoration: line-through;

  .image {
    opacity: var(--disabled-opacity);
  }
}

:global(.dark-theme) .image {
  background-image: var(--icon-dark-path);
}
