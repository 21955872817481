.checks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 48px 0;
  background-color: var(--elevation-default);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 520px;
  margin: 0 auto;
}

.title {
  display: flex;
  width: 100%;
  color: var(--content-primary-a);
}

.description {
  color: var(--content-primary-a);
}

.wrapper {
  width: 100%;
}

.aside {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  background-color: var(--elevation-low);
}
