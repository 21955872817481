.step-wrapper {
  min-width: 0;
  padding: 0;
  margin: 0 0 16px 0;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}

.divider {
  margin: 0;
  border: 0;
  border-top: 1px solid var(--border-tertiary);
}

.ruleset-steps-container {
  padding: 24px 16px;
}

.ruleset-header {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 16px 24px;
}

.delete-button {
  position: absolute;
  top: 12px;
  right: 16px;
}

.tip {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.tip-text {
  color: var(--content-secondary);
}

.tooltip-icon {
  display: flex;
  color: var(--content-secondary);
}
