.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 37px 0 0;
  text-align: left;
  white-space: nowrap;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}

.empty {
  width: 48px;
  min-width: 48px;
  padding: 0;
}

.header-type {
  width: calc((256 + 24) / 1053 * 100%);
}

.header-rules {
  min-width: calc((397 + 24) / 1053 * 100%);
}

.header-date {
  width: calc((200 + 24) / 1053 * 100%);
}

.header-action {
  width: calc((32) / 1053 * 100%);
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
  vertical-align: middle;
}

.header-content.active {
  color: var(--content-primary-a);
}

/* sidebar open view */
.row-sidebar .header-cell {
  padding: 0;
}

.row-sidebar .header-cell.empty {
  padding: 0;
}

.row-sidebar .header-type {
  width: calc((224 + 24) / 668 * 100%);
}

.row-sidebar .header-rules {
  min-width: calc((269 + 24) / 668 * 100%);
}

.row-sidebar .header-action {
  width: calc((32) / 668 * 100%);
}

/* animation */
.hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.animated .animated-cell {
  animation: fadein-item 1.3s ease;
}

@keyframes fadein-item {
  0% {
    opacity: 0;
  }

  1% {
    display: none;
  }

  68% {
    display: table-cell;
  }

  69% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
