.pdf-preview-carousel {
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  position: relative;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.pdf-preview-carousel iframe {
  visibility: visible !important;
}

.pdf-preview-carousel-viewport {
  overflow: hidden auto;
  height: 100%;
}

.pdf-preview-carousel-container {
  display: flex;
  margin-left: calc(var(--slide-spacing) * -1);
  height: 100%;
}

.pdf-preview-carousel-slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
  width: 100%;
  height: 100%;
  display: flex;
}
