.header-cell {
  composes: caption-bold from global;
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  padding: 11px 32px 11px 0;
  color: var(--content-secondary);
  text-align: left;
  white-space: nowrap;
  background: var(--elevation-default);
  border-top: 0;
  border-bottom: 1px solid var(--border-tertiary);
}

.header-cell-name {
  width: 40vw;
  padding-left: 48px;
}

.header-cell:last-child {
  padding-right: 48px;
  text-align: right;
}

.header-cell-sortable {
  cursor: pointer;
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
}

.header-content-active {
  color: var(--content-primary-a);
}

.header-row-animated {
  animation: slide-in 0.3s ease-out;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
