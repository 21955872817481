.breadcrumbs {
  composes: title-4 from global;
  margin-bottom: 16px;
  color: var(--content-secondary);
}

.multiple {
  display: flex;
  gap: 4px;
  align-items: center;
}
