.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--content-secondary);
}

section.section {
  border-bottom: 1px solid var(--border-tertiary);
}

.info {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  margin: 0;
  color: var(--content-secondary);
}

.title {
  padding: 4px 0;
}

.feeName {
  max-width: 187px;
  overflow: hidden;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.amount {
  color: var(--content-primary-a);
  white-space: nowrap;
}
