.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.group {
  display: grid;
  grid-template-columns: 90% 10%;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  overflow: hidden;
  font-size: var(--body-1-font-size);
  font-weight: var(--body-1-font-weight);
  color: var(--content-primary-a);
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
  transition: border-color 0.5s;

  &[data-focus-within] {
    border-color: var(--border-accent-high);
    outline: none;
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }

  &[data-disabled] {
    cursor: not-allowed;
    background-color: var(--elevation-low);
    opacity: 0.45;
  }

  &[data-invalid] {
    border-color: var(--border-error);
  }
}

.cmc7-input,
.rlmc-input {
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;

  &::placeholder {
    color: var(--content-tertiary);
    opacity: 1;
  }

  &[data-disabled] {
    cursor: not-allowed;
    background-color: var(--elevation-low);
    opacity: 0.45;
  }
}

.rlmc-input {
  text-align: right;
}

.error {
  color: var(--content-error);
}
