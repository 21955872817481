.vat-section {
  padding: 0 0 16px;
}

.vat-title {
  composes: caption-bold from global;
  margin-bottom: 12px;
  color: var(--content-secondary);
}

.vat-table-title {
  composes: caption from global;
  color: var(--content-secondary);
}

.amount-excluding-vat {
  composes: caption from global;
  text-align: left;
  vertical-align: baseline;
}

.vat-rate {
  composes: caption from global;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.vat-amount {
  composes: caption from global;
  text-align: right;
  vertical-align: baseline;
}

/**
  Negative margins compensates table's first and last line border spacing for Single VAT case
  https://www.figma.com/file/RHC4zqtfO1dxWy84SbHail/branch/yD7Ok8vfZNZYa2HwrmmLEG/History---web?type=design&node-id=11277-151731&mode=design&t=JxxjL9fmZ07zXU5p-0
**/
.vat-table {
  width: 100%;
  margin: -12px 0; /* We have added the negative margins here to compensate for the border spacing */
  border-spacing: 0 12px;
}

.vat-table.multiple {
  margin-bottom: 0;
  border-bottom: 1px solid var(--border-tertiary);
}

.vat-table-header {
  height: 16px;
}

.vat-line {
  height: 24px;
}

.total-vat-amount {
  composes: caption from global;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  margin-top: 12px;
  margin-bottom: 0;
}

.total-vat-amount-title {
  color: var(--content-secondary);
}

.empty-state {
  composes: body-1 from global;
  color: var(--content-secondary);
}
