.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin: 80px 64px;
}

.content {
  width: 504px;
}
