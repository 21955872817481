.step-container {
  flex: 1;
  overflow-y: auto;
}

.content {
  width: 504px;
  height: 100%;
  margin: 48px auto 80px auto;
  color: var(--content-primary-a);
}

.selector {
  display: flex;
  margin-top: 16px;
}

.lottie-illustration {
  position: relative;
  left: calc(50% - 120px / 2);
  width: 120px;
  height: 120px;
  margin: 34px 0;
}

.info-text-1 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--content-primary-a);
  text-align: center;
}

.info-text-2 {
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.mt-16 {
  margin-top: 16px;
}
