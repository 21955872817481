.section {
  padding: 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.title {
  composes: caption-bold from global;
  padding-bottom: 18px;
  color: var(--content-secondary);
}

.label-lists {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.label-list {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 40px;
  padding: 8px 0;
}

.list-name {
  composes: body-1 from global;
  width: 160px;
}

.empty-label {
  composes: body-2 from global;
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--content-tertiary);
}

.popover-trigger {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;

  svg {
    color: var(--content-secondary);
  }
}

.toggle-button {
  align-self: flex-start;
  padding: 0;
  padding-bottom: 1px;
  margin-top: 12px;
  border-bottom: 1px solid var(--content-primary-a);
}

.empty-label-lists {
  composes: body-2 from global;
  color: var(--content-secondary);

  button {
    padding: 0 0 1px;
    margin-top: 16px;
    border-bottom: 1px solid var(--content-primary-a);
  }
}
