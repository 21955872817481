.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.timeline-item {
  position: relative;
  display: grid;
  grid-template-columns: calc(var(--bubble-size) + var(--bubble-title-gap)) 1fr;
  align-items: start;
  justify-items: start;

  &:last-of-type {
    &::before {
      display: none;
    }
  }
}

.timeline-item::before {
  position: absolute;
  top: calc(var(--bubble-size) + var(--bubble-line-gap));
  bottom: var(--bubble-line-gap);
  left: calc(var(--bubble-size) / 2);
  display: block;
  content: '';

  &:last-of-type {
    display: none;
  }
}

.variant-dashed {
  &::before {
    border-left: 1px dashed var(--border-secondary);
  }
}

.variant-solid {
  &::before {
    border-left: 1px solid var(--border-secondary);
  }
}

.timeline-media-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--elevation-default);
  border-radius: 50%;
}

.timeline-item-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
