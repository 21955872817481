.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: space-between;
  width: 290px;
  max-width: 290px;
  padding: 24px;
  color: var(--content-primary-a);
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
  transition: box-shadow var(--transition-duration-short) ease-in-out;

  &:hover {
    box-shadow: var(--shadow-high);
  }
}

.illustration {
  width: 40px;
  height: 40px;
}

.feature-list {
  padding-left: 20px;
  margin: 0;
  list-style-type: square;
}

@media only screen and (width <= 777px) {
  .container {
    height: 100%;
  }
}
