.container {
  display: flex;
}

.avatar {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border: 1px solid var(--border-tertiary);
  border-radius: 50%;
}
