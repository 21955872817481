.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: var(--elevation-default);
}

.divider {
  border-bottom: 1px solid var(--border-tertiary);
}
