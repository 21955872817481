.wrapper {
  padding: 16px;
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;
}

.item {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.item:not(:last-child) {
  margin-bottom: 16px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;

  &:hover {
    background-color: var(--background-secondary);
    border: 1px solid var(--content-primary-a);
  }
}

.details {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.link-color {
  width: 256px;
  padding: 0;
  overflow: hidden;
  color: var(--content-primary-a);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
}

.credit-note-amount {
  color: var(--content-success);
}
