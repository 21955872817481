.category-container {
  padding: 12px 16px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.subcategory-container {
  display: flex;
  gap: 12px;
  align-items: center;
  height: 56px;
  padding-right: 16px;
  padding-left: 60px;
  border-top: 1px solid var(--border-tertiary);
}

.container-with-error {
  padding-top: 24px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;

  label {
    display: none;
  }
}

.text-field {
  flex-grow: 1;
}

.button-group {
  display: flex;
  flex-shrink: 0;
  gap: 8px;
}

.with-error {
  margin-bottom: 20px;
}

.create-button .button-content {
  display: flex;
  gap: 8px;
  align-items: center;
}

.popup-content {
  ul {
    padding-left: 16px;
  }
}
