.eligibility-loss-banner {
  display: flex;
  padding: 24px;
  margin-right: 32px;
  margin-bottom: 32px;
  margin-left: 32px;
  background-color: var(--elevation-low);
  border-radius: 8px;
}

.container {
  flex-grow: 1;
}

.illustration {
  width: auto;
  height: 118px;
}
