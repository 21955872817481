.container {
  display: flex;
  flex-direction: row;
}

.member-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: -4px;
  background-color: var(--background-secondary);
  border-radius: 20px;

  &:first-child {
    margin-left: 0;
  }

  img {
    border-radius: 20px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &.invitables {
    color: var(--content-tertiary);
  }
}

.remaining-memberships-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: -4px;
  color: var(--content-primary-a);
  background-color: var(--background-secondary);
  border-radius: 20px;
}
