.list {
  composes: title-4 from global;
  padding: 0;
}

.stepContent {
  position: relative;
  left: -24px;
  width: 100%;
  width: calc(100% + 24px);
}

.step {
  margin-bottom: 32px;
  margin-left: 24px;
}

.stepTitle {
  composes: title-4 from global;
  margin-bottom: 8px;
}

.secondStep {
  composes: body-1 from global;
  color: var(--content-secondary);
}

.dropzoneDisabled button {
  cursor: wait;
}
