@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.overlay > .full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(var(--z-index-above) + 2);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--elevation-high);
  box-shadow: var(--shadow-high);

  @media (prefers-reduced-motion) {
    --overlay-sidebar-animation-duration: 0ms;
  }

  &[data-entering] {
    animation: slideUp var(--overlay-sidebar-animation-duration) ease-out;
  }

  &[data-exiting] {
    animation: slideUp var(--overlay-sidebar-animation-duration) reverse ease-in-out;
  }
}

.dialog {
  height: 100%;

  &:focus-visible {
    box-shadow: none;
  }
}
