.section {
  padding: 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.header {
  display: grid;
  grid-template-areas: 'icon title' 'subtitle subtitle';
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: min-content 1fr;
  column-gap: 8px;
  margin-bottom: 8px;
}

.icon {
  grid-area: icon;
  width: 16px;
  height: 16px;
}

.title {
  composes: caption-bold from global;
  grid-area: title;
  color: var(--content-secondary);
}

.subtitle {
  composes: body-2 from global;
  grid-area: subtitle;
  color: var(--content-secondary);
}

.content {
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}
