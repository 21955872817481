.insurance {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 40px;
  grid-column-gap: 24px;
  margin-bottom: 40px;
  text-align: left;
}

.content-insurance-text {
  text-align: left;
}

.insurance-item h4 {
  margin-bottom: 4px;
}

.insurance-item p {
  color: var(--content-secondary);
}

.insurance-item-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
}

:global(.premium-theme) .insurance-item p {
  /* X Card related core token */
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  color: var(--gray400);
}
