.toggle-button {
  display: flex;
  gap: 12px;
  padding: 0;
}

.participants-container.drop-shadow {
  box-shadow: var(--shadow-low);
}

.comments-container {
  padding: 8px 18px;
}
