@keyframes headerFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animatedHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 504px;
  margin: 80px auto 0;
  text-align: center;

  /* Animation */
  opacity: 1;
  animation: headerFadeIn 0.5s ease-in;
}

.fullScreenAnimation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.placeholderAnimation {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 856px;
  transform: translateX(-50%);
}

.introScreen {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
