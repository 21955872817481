.container {
  @property --col-content-width {
    syntax: '<length>';
    inherits: false;
  }

  width: 100%;
  height: 100%;
  color: var(--content-primary-a);

  .width-mixin {
    width: calc(var(--col-content-width) + 32px);
  }

  .col-quantity {
    --col-content-width: 48px;
    display: revert;
  }

  .col-vat {
    --col-content-width: 80px;
    display: revert;
  }

  .col-total {
    --col-content-width: 104px;
  }

  .table {
    width: 100%;
    border-collapse: collapse;

    tbody tr:nth-child(even) {
      background-color: var(--elevation-low);
    }
  }

  .col-item {
    max-width: 99%;
  }

  .title {
    margin-bottom: 8px;
  }

  .subtitle {
    display: block;
    margin-bottom: 24px;
  }

  .itemisation {
    border-top: 1px solid var(--border-tertiary);
  }

  .col-quantity,
  .col-vat {
    display: none;
  }

  @media only screen and (width >= 768px) {
    .col-vat {
      display: revert;
    }
  }

  @media only screen and (width >= 1369px) {
    .title {
      margin-bottom: 24px;
    }

    .subtitle {
      display: none;
    }

    .itemisation {
      border: none;
    }
  }

  /* Preview mode needs to apply desktop styles regardless of screen size */
  &.preview {
    .col-vat {
      display: revert;
    }

    .col-item {
      --col-content-width: 220px;
    }

    .title {
      margin-bottom: 24px;
    }

    .subtitle {
      display: none;
    }

    .itemisation {
      border: none;
    }
  }
}
