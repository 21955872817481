.type {
  composes: body-1 from global;
  width: 100%;
  padding: 28px 48px;
  text-align: center;
}

.refunded,
.declined,
.reversed {
  color: var(--content-error);
}

.canceled,
.disputing,
.pending {
  color: var(--content-secondary);
}
