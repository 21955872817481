/* buttons and arrows should not be tokenized - they are the same on light and dark mode */
/* stylelint-disable style-lint-plugin-qonto/require-theme-token */

.arrow {
  color: var(--primary-black);
  width: 16px;
  height: 16px;
}

.left-arrow {
  left: 45px;
}

.right-arrow {
  right: 45px;
}

.button {
  cursor: pointer;
  background-color: var(--primary-white);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 45%;
  z-index: 1;
  box-shadow: var(--shadow-high);
}
