.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.wrapper--small {
  max-width: 120px;
  max-height: 120px;
}

.wrapper--medium {
  max-width: 200px;
  max-height: 200px;
}
