.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 24px;
}

.title {
  composes: body-1 from global;
  flex-grow: 1;
  padding-left: 16px;
  color: var(--content-primary-a);
  text-align: center;
}
