.container {
  display: flex;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;

  button {
    width: 24px;
    height: 24px;
  }
}

.label {
  display: flex;
  gap: 6px;
  align-items: center;
  height: 24px;
  padding: 4px 6px;
  border-right: 1px solid var(--border-tertiary);
}

.color-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.dismiss-button {
  svg {
    width: 12px;
    height: 12px;
  }
}
