.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    background-color: var(
      --elevation-default
    ); /* required so that card image is not semi-transparent */

    border-radius: 13px; /* required for the background to fit the card image */

    .image,
    .infos {
      opacity: 0.4;
    }
  }

  &.fully-masked {
    user-select: none;
  }
}

.image {
  width: 320px;
  height: 200px;
  overflow: visible;
}

.infos {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: auto 24px 24px 40px;
  grid-template-columns: 68px 68px auto;
  row-gap: 12px;
  column-gap: 12px;
  width: 100%;
  height: 100%;
  padding: 16px;
  margin: 0;
  font-variant-numeric: tabular-nums;
  color: var(--content-primary-a-static);

  &.black {
    color: var(--content-primary-b-static);
  }
}

.infos-holder {
  grid-row: 2 / span 1;
  grid-column: 1 / span 3;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.infos-pan {
  grid-row: 3 / span 1;
  grid-column: 1 / span 3;
}

.infos-exp-date {
  grid-row: 4 / span 1;
  grid-column: 1 / span 1;
  justify-self: start;
}

.infos-cvv {
  grid-row: 4 / span 1;
  grid-column: 2 / span 1;
  justify-self: start;
}

.infos-exp-date,
.infos-cvv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.infos-caption {
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  opacity: 0.5;
}

.description-details {
  margin: 0;
}

.copy-btn {
  display: flex;
  align-items: center;
  padding: 0;
  line-height: 24px;
  color: var(--content-primary-a-static);
  border-radius: 4px;

  svg {
    opacity: 0;

    path {
      color: var(--content-primary-a-static);
    }
  }

  &.black {
    color: var(--content-primary-b-static);

    svg path {
      fill: var(--content-primary-b-static);
    }

    &:hover {
      --overlay-primary-a-color: var(--primary-white);
    }
  }

  &:hover {
    --overlay-primary-a-color: var(--primary-black);

    svg {
      opacity: 1;
    }
  }
}
