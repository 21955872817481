.wrapper {
  display: inline-grid;
  align-items: center;
  width: 104px;
  height: 40px;
}

.search-input {
  position: relative;
  grid-area: 1 / 1;
  text-overflow: ellipsis;
  border-width: 1px 0 1px 0 !important;
  border-radius: 0 !important;
  transition: border-radius 0.1s;

  &:focus-visible,
  &:focus {
    box-shadow: none;
  }
}

.arrow-container {
  position: absolute;
  right: 0;
  display: flex;
  grid-area: 1 / 2;
  align-items: center;
  padding: 12px 8px;
  pointer-events: none;
  user-select: none;
}
