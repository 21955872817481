.marketingFeaturesTable {
  tr:not(:last-child) td {
    border-bottom: 1px dashed var(--border-tertiary);
  }

  td {
    padding: 8px 0;

    &.checkmark {
      text-align: center;
    }
  }

  th {
    padding: 4px 20px;
    text-align: center;

    &.tableTitle {
      /* Implements fit-content behavior within a table cell */
      width: 1%;
      white-space: nowrap;
    }

    &.paid {
      background-color: var(--background-free-trial);
      border-radius: 4px;
    }
  }
}
