.section {
  padding: 21px 16px;
}

.header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 8px;
  align-items: center;
}

.title-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.title {
  color: var(--content-secondary);
  word-break: break-word;
}
