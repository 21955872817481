.discount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--content-secondary);

  .discounted-price {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

.highlighted {
  color: var(--content-accent);
}

.stroke {
  text-decoration: line-through;
}
