.icon {
  flex-shrink: 0;
  margin-left: 4px;
  color: var(--content-secondary);

  svg {
    width: 8px;
    height: 8px;

    &.active {
      color: var(--content-primary-a);
    }

    &.ascending {
      transform: rotate(-180deg);
    }
  }
}
