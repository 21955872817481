.flex-row {
  display: flex;
  flex-direction: row;
}

.border-field {
  flex-grow: 1;
  margin-right: -1px;
}

.border-field > input {
  position: relative;
  z-index: var(--z-index-default);
  width: 100%;
  border-radius: 4px 0 0 4px;
  transition: border-color 0.5s;

  &:focus {
    /* ATTENTION: it needs to change the z-index to 3 only on focus, as */

    /* on normal state has z-index 1, but the input next to it has 2 */

    /* therefore the only solution is that on focus one of the input has an higher z-index */

    /* as the 2 inputs overlap on one side */
    z-index: var(--z-index-high);
    border-radius: 4px;
  }

  &:focus-within {
    border-right: 1px solid var(--border-accent-high);
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
  }
}

.border-left {
  &:global(.power-select-customs-trigger.block) {
    border-left: 1px solid var(--border-error);

    &:focus-within {
      border-left-color: var(--border-accent-high);
    }
  }
}

.dropdown {
  width: 80px;

  &:global(.power-select-customs-trigger) {
    z-index: var(--z-index-mid);
    border-radius: 0 4px 4px 0 !important;
    transition: border-radius 0.1s;
  }

  &:global(.ember-basic-dropdown-trigger--left) {
    border-radius: 4px !important;
  }

  &:global(.power-select-customs-trigger.block) {
    &:focus-visible {
      border-radius: 4px !important;
    }

    &:focus-within {
      border-left-color: var(--border-accent-high);
    }
  }
}

.dropdown-content {
  z-index: var(--z-index-mid);
}

.disabled {
  opacity: 0.45;
}
