.accordion {
  display: block;
  margin-bottom: 20px;
  border-radius: 4px;
  border-bottom: 1px solid var(--border-tertiary);
}

.accordion__header {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
}

.accordion__header__clickable {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  color: var(--content-primary-a);
  cursor: pointer;
}

.accordion__label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.accordion__label_icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.accordion__label_icon svg {
  width: 16px;
  height: 16px;
  margin: 8px;
}

.accordion__arrow {
  width: 16px;
  height: 16px;
  transition: 0.2s ease-in-out;
}

.accordion__header__clickable[aria-expanded='true'] .accordion__arrow {
  transform: rotateZ(180deg);
}

.accordion__content {
  overflow: hidden;
  color: var(--content-primary-a);
}

.accordion__content__inner {
  padding: 16px 0;
}

@media (prefers-reduced-motion) {
  .accordion__arrow {
    transition: none;
  }
}
