.amount {
  &.credit {
    color: var(--content-success);
  }
}

.subtitle {
  composes: body-2 from global;
  color: var(--content-secondary);
}
