.infobox {
  width: 100%;
  border-top: 1px solid var(--border-tertiary);
}

.infolist {
  width: 100%;
  padding: 16px;
  margin: 0;
}
