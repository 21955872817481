.check-header {
  width: 100%;
}

.infobox {
  composes: header-section from global;
  width: 100%;
}

.counterparty-name {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
