.row {
  height: 64px;
}

.cell {
  padding: 0 37px 0 0;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);
}

.cellContent {
  display: flex;
  align-items: center;
}
