.option-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.option-item__subtitle {
  font-size: 12px;
  line-height: 16px;
  color: var(--content-secondary);
  text-wrap: wrap;
}

.option-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  width: 100%;
}

.disabledColor {
  color: var(--content-tertiary);
}

.selector {
  :global(.tooltip-target) {
    color: var(--content-primary-a);
  }
}
