.card-box {
  display: flex;
  flex-shrink: 0;
  gap: 24px;
  align-items: center;
  width: 100%;
  padding: 16px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;

  .card-icon {
    flex-shrink: 0;
    width: 32px;
    height: 20px;
  }

  .card-name {
    display: flex;
  }

  .card-container {
    display: flex;
    flex: 1 0 0;
    align-items: center;
    justify-content: space-between;
  }

  .dot {
    margin: 0 8px;
  }

  .card-holder-name {
    color: var(--content-secondary);
  }
}

.status-text {
  &.paused {
    color: var(--content-warning);
  }

  &.deleted {
    color: var(--content-error);
  }
}
