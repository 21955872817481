.icon {
  margin-right: 16px;
}

.cell {
  padding-right: 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.cell-content {
  display: flex;
  align-items: center;
}
