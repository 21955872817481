.wrapper {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 16px;

  button {
    width: 100%;
  }
}

.success {
  color: var(--content-primary-b-static);
  background: var(--background-success-high);
}
