.row {
  height: 64px;
}

.cell {
  color: var(--content-primary-a);
  border-bottom: 1px solid var(--border-tertiary);
}

.cell-content {
  display: flex;
  flex-direction: column;
}

.align-right {
  margin-left: auto;
}

.align-left {
  margin-right: auto;
}
