.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: var(--content-primary-a);
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}