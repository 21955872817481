.empty-state-education {
  padding: 48px;
}

.header {
  text-align: center;
}

.actions {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.footer {
  padding-top: 24px;
  text-align: center;
  border-top: 1px solid var(--border-tertiary);
}

.footer-subtitle {
  color: var(--content-secondary);
}
