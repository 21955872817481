.cell {
  composes: caption-bold from global;
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding-right: 24px;
  color: var(--content-secondary);
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--elevation-default);
  border-top: 1px solid var(--border-tertiary);
  border-bottom: 1px solid var(--border-tertiary);
}

.empty {
  width: 48px;
}

.no-border {
  border-top: 0;
}

.label {
  composes: caption-bold from global;
  padding-inline: 0;
  color: var(--content-secondary);
}

.label.active {
  color: var(--content-primary-a);
}

.hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  visibility: hidden;
}

@media only screen and (width <= 1367px) {
  .cell {
    border-top: none;
  }
}
