.container {
  color: var(--content-primary-a);
  background-color: var(--elevation-default);
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
}

.description {
  min-height: 72px;
}

.priceContainer {
  height: 64px;
}


.ctaDescription {
  margin: 8px 0 24px 0;
  text-align: center;
  color: var(--content-secondary);
}

.bottomSection {
  margin-top: auto;
}

@media only screen and (max-width: 777px) {
  .container {
    padding: 24px 16px;
  }
}
