.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 234px;
  padding: 16px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);

  &.compact {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 96px;
  }
}

.card-container:hover {
  box-shadow: var(--shadow-high);
}

.card-container:focus-visible {
  box-shadow: 0 0 0 4px var(--border-accent-low);
}

.card-header .application-logo {
  box-sizing: border-box;
  display: block;
  margin-right: 12px;
  overflow: hidden;
  font-size: 16px;
  border-radius: 100%;

  .compact & {
    margin-right: 16px;
  }
}

.application-name {
  overflow: hidden;
}

.application-bic {
  composes: caption-2 from global;
  color: var(--content-secondary);
}

.card-header {
  composes: mb-16 from global;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;

  .compact & {
    width: auto;
    margin-bottom: 0;
  }
}

.application-title {
  composes: title-4 mb-8 from global;
  overflow: hidden;
  color: var(--content-primary-a);
  text-overflow: ellipsis;
  white-space: nowrap;

  .compact & {
    margin-bottom: 0;
  }
}

.application-categories {
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  gap: 4px;
  width: 100%;
}

.application-card-tag {
  composes: tag from global;
}

.short-description {
  composes: body-2 from global;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
