.row {
  vertical-align: middle;
}

.cell {
  padding: 20px 0;
  border-bottom: 1px solid var(--border-tertiary);
}

.cell:not(:last-child) {
  padding-right: 16px;
}

.empty {
  width: 48px;
  min-width: 48px;
}
