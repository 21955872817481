.details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: unset;
}

.details-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.details-row-label {
  margin: unset;
  color: var(--content-secondary);
}

.details-row-text {
  justify-self: flex-end;
  margin: unset;
}
