.container {
  display: flex;
  flex-direction: column;
  width: 504px;
  text-align: center;
  align-items: center;
}

.lottie {
  width: 200px;
  height: 200px;
  margin: 0 0 32px 0;
}

.title {
  color: var(--content-primary-a);
  margin: 0 0 16px 0;
}

.subtitle {
  color: var(--content-primary-a);
  margin: 0 0 32px 0;
}
