.wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;

  [data-highlight] {
    display: none;
  }
}

.check-image {
  position: relative;
  max-width: 640px;
  margin: auto;
}

.tooltip {
  position: absolute;
  top: 16%;
  right: 23%;
  max-width: 400px;
  padding: 12px 16px;
  color: var(--content-primary-b-static);
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  background-color: var(--primary-black);
  border-radius: 4px;
}

.emitter-name-highlighted [data-highlight='emitter-name'],
.emitted-date-highlighted [data-highlight='emitted-date'],
.amount-highlighted [data-highlight='amount'],
.check-id-highlighted [data-highlight='check-id'] {
  display: initial;
}
