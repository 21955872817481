.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 48px;
  color: var(--content-primary-a);
  background-color: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 4px;
}

.step {
  padding: 2px 8px;
  color: var(--content-secondary);

  &:hover {
    background: var(--state-primary-a-hover);
    border-radius: 4px;
  }
}

.stepActive,
.stepDisabled {
  cursor: default;

  &:hover {
    background: none;
  }
}

.stepActive {
  color: var(--content-primary);
}

.stepDisabled {
  color: var(--content-tertiary);
}
