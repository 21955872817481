.toggle-button-group {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
}

.toggle-button {
  composes: body-1 from global;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 8px;
  color: var(--content-primary-a);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;

  &[data-selected='true'] {
    color: var(--content-primary-b-static);
    background-color: var(--background-accent-high);
    border: 1px solid var(--border-accent-high);
  }
}

.default-group,
.other-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.other-group {
  width: 100%;
}

.vat-input-wrapper {
  composes: input-field from global;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  vertical-align: top;
  cursor: text;
  transition: border-color 0.5s;

  &:focus-within {
    border-color: var(--border-accent-high);
    outline: none;
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }

  &.error {
    border-color: var(--border-error);
  }

  &.inactive {
    color: var(--content-tertiary);
    background: var(--elevation-low);
    border-color: var(--border-secondary);
  }

  input {
    width: 100%;
    padding: 0;
    padding-right: 2px;
    line-height: 22px;
    appearance: none;
    resize: none;
    background: none;
    border: none;
  }
}

.vat-input {
  border: 1px solid var(--border-tertiary);
  border-radius: 3px;
  transition: border-color 0.5s;

  &:focus-visible,
  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: var(--content-tertiary);
    opacity: 1;
  }

  .disabled {
    cursor: not-allowed;
  }
}

.percentage-sign {
  line-height: 22px;
  color: var(--content-tertiary);
  pointer-events: none;
  user-select: none;
  opacity: 1;
}

.percentage-sign.active {
  color: var(--content-primary-a);
}

.auto-width-input-wrapper {
  position: relative;
}

.auto-width-hidden-value {
  position: absolute;
  visibility: hidden;
}
