.company-list-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 440px;
  margin-bottom: 32px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.divider {
  display: flex;
  gap: 16px;
  align-items: center;
  color: var(--content-tertiary);
  text-align: center;

  &::after,
  &::before {
    flex: 1;
    content: '';
    border: 0.5px solid var(--border-tertiary);
  }
}

.manual-btn {
  align-self: center;
}

.search-btn {
  align-self: flex-end;

  &.has-error {
    align-self: center;
    margin-top: 8px;
  }
}

.search-container {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.search-input {
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
}
