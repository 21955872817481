.success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* same as Button */
  gap: 8px;
}

.successIcon {
  color: var(--content-success);
}
