.container {
  /* Fix to sr-only class positioned absolutely that causes page to scroll
  See bug ticket: https://www.notion.so/qonto/Double-scrollbar-on-Insurances-page-bf55eef069fc43eba23ab1017a75593d */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.highlight {
  display: flex;
  align-items: center;
  color: var(--content-accent);
}
