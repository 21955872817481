.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 16px 0 16px;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}

.checkbox-cell {
  width: 40px;
}

.amount-cell {
  text-align: right;
}

.creditor-cell {
  padding-left: 0;
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
}

.align-right {
  padding-right: 0;
  text-align: right;
}

.active {
  color: var(--content-primary-a);
}

.active > .header-content {
  color: var(--content-primary-a);
}

.sort-icon {
  display: inline;
  width: 8px;
  height: 8px;
}

.active .sort-icon {
  color: var(--content-primary-a);
}
