.status-avatar {
  composes: mr-16 from global;
  width: 32px;
  height: 32px;

  svg {
    right: -4px;
    bottom: -4px;
    width: 16px;
    height: 16px;
  }
}

.cell {
  padding-right: 24px;
  white-space: nowrap;
  border-bottom: 1px solid var(--border-tertiary);

  @media only screen and (width < 1368px) {
    border-bottom: none;
  }
}

.cell-amount {
  font-variant-numeric: tabular-nums;

  &.ghost {
    text-decoration: line-through;
  }

  &.validated {
    color: var(--content-success);
  }
}

.cell-content {
  display: flex;
  align-items: center;
}

.status-pin {
  flex-shrink: 0;
  justify-content: center;
  width: 6px;
  height: 6px;
  margin-left: 8px;
  background-color: var(--background-warning-high);
  border-radius: 50%;

  &.validated {
    background-color: var(--background-success-high);
  }

  &.ghost {
    background-color: var(--background-error-high);
  }
}

.item-emitter-name {
  display: grid;
  justify-content: center;
  text-align: left;
}

.item-emitter-name-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-row:last-child > .cell {
  border-bottom: none;
}

@keyframes fadein-item {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  26% {
    display: none;
  }

  68% {
    display: block;
  }

  69% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
