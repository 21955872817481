.wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
  height: 100%;
  border-top: 1px solid var(--border-tertiary);
}

.section-title {
  display: flex;
  align-items: center;
}

.menu {
  z-index: 51;
}

.workflow {
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  width: 100%;
}

.subtitle {
  color: var(--content-secondary);
}

.dropdown-menu {
  margin-left: auto;
}

.dropdown-option {
  display: block;
  padding: 8px 16px;
  color: var(--content-primary-a);
  text-decoration: none;

  &:hover {
    background-color: var(--background-secondary);
  }
}
