.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: calc(var(--z-index-above) + 1);
  overflow-y: auto;
  background-color: var(--elevation-default);
  box-shadow: var(--shadow-high);
}
