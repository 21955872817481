.company-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 80px;
  padding: 16px;
  text-decoration: unset;
  appearance: none;
  cursor: pointer;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  outline: none;
  box-shadow: var(--shadow-low);
  transition: all var(--transition-duration-short) ease-in-out;

  &:hover {
    box-shadow: var(--shadow-high);
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}

.chevron-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 16px;
  height: 16px;
  background: transparent;

  svg {
    width: auto;
    height: 16px;

    path {
      fill: var(--content-secondary);
    }
  }
}

.loader-line {
  margin: 8px 0;
}

.text-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-height: 46px;
}
