.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;

  svg {
    width: 16px;
    height: 16px;
  }
}
