.header-cell {
  composes: caption-bold from global;
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding-right: 25px;
  color: var(--content-secondary);
  text-align: left;
  white-space: nowrap;
  background: var(--elevation-default);
  border-top: 1px solid var(--border-tertiary);
  border-bottom: 1px solid var(--border-tertiary);
}

.empty {
  width: 48px;
}
