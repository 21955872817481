.signatureWarning {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  width: 504px;
  height: 100%;
}

.buttons {
  display: flex;
  gap: 16px;
}
