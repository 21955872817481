.steps-container {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.step-container {
  display: flex;
  gap: 12px;
  width: 100%;
}
