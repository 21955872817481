.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 24px 0 0;
  text-align: left;
  white-space: nowrap;
  background: var(--elevation-default);
  border-top: 1px solid var(--border-tertiary);
  border-bottom: 1px solid var(--border-tertiary);
}

@media only screen and (width <= 1368px) {
  .header-cell {
    border-top: none;
  }
}

.empty {
  width: 48px;
  min-width: 48px;
}

.emitter {
  min-width: 28%;
}

.date {
  width: 160px;
}

.amount {
  width: 128px;
}

.status {
  width: 180px;
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
}

.header-content.active {
  color: var(--content-primary-a);
}
