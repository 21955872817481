.forecast-entry-loading-state-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 19px 24px;
  margin-top: 16px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.loading-state-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
