.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 550px;
  padding: 0 24px;
  text-align: center;
}

.animation {
  opacity: 1;
  transition: opacity 0.2s ease;
  will-change: opacity;

  &.hidden {
    opacity: 0;
  }
}

.image {
  width: 200px;
  height: 200px;
  margin-bottom: 32px;
}

.title {
  margin-top: 0;
  color: var(--content-primary-a);
}

.subtitle {
  margin: 0;
  color: var(--content-primary-a);
}

.buttons {
  display: flex;
  align-items: center;
  margin-top: 32px;
}
