.processing-repayment {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.processing-repayment-title {
  color: var(--content-secondary);
}

.processing-repayment-description {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 4px 0;
  color: var(--content-secondary);
}

.processing-repayment-illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}
