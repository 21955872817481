.container {
  display: flex;
    gap: 32px;
    margin-bottom: 16px;

  > div {
    width: 100%;
    min-width: 256px;
    max-width: 100%;
  }
}

.title-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.amount-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: baseline;
}
