.handle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  cursor: grab;
  color: var(--content-tertiary);
  border-radius: 4px;
}

.handle[aria-disabled="true"] {
  cursor: not-allowed;
}

.handle.dragging {
  cursor: grabbing;
}
