.card-icon {
  width: 32px;
  height: 20px;
  margin-right: 16px;
}

.cell-content {
  display: flex;
  align-items: center;
}
