.attachment {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--content-primary-a);
  a {
    text-decoration: none;
    color: var(--content-primary-a);
  }
}

.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--border-tertiary);
  padding: 16px;
  &:hover {
    border: 1px solid var(--content-primary-a);
    background: var(--background-tertiary);
  }
  &.error {
    border-color: var(--border-error);
  }
}

.info {
  flex: 1;
  overflow: hidden;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .fileSize {
    color: var(--content-secondary);
  }
  .errorMessage {
    color: var(--content-error);
    margin-top: 4px;
  }
}

.removeButton:focus-visible {
  box-shadow: none;
}
