.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: opacity 0.3s ease;
}

.lottie {
  width: 200px;
  height: 200px;
  margin-bottom: 16px;
}

.content {
  margin-bottom: 32px;
}

.hidden {
  visibility: hidden;
}
