.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 37px 0 0;
  text-align: left;
  white-space: nowrap;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
}

.header-content.active {
  color: var(--content-primary-a);
}

.empty {
  width: 48px;
  min-width: 48px;
}

.type {
  min-width: 27%;
}

.requester {
  width: 18%;
}

.request-date {
  width: 13%;
}

.amount {
  width: 13%;
  padding: 0;
  text-align: right;
}

.receipt {
  width: 13%;
  padding: 0;
  text-align: center;
}

.quick-actions {
  width: 232px;
}

@media only screen and (width < 1368px) {
  .requester {
    width: 120px;
  }

  .amount {
    width: 140px;
  }

  .request-date {
    width: 110px;
  }
}
