.item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.title-sub-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  margin-left: 16px;
}

.icon-title-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.icon {
  width: 16px;
  height: 16px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: var(--background-tertiary);
  border-radius: 50%;
}

.title {
  composes: body-1 from global;
  color: var(--content-primary-a);
}

.subtitle {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.amount {
  composes: body-1 from global;
  color: var(--content-primary-a);
}
