.dropzone {
  border-radius: 4px;
  border: 1px dashed var(--border-secondary);
}

.dropzone.error {
  border-color: var(--border-error);
}

.dropzone .icon {
  flex-shrink: 0;
}

.dropzone .fileUploader {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--content-tertiary);
  padding: 24px;
  width: 100%;
  &:focus-visible {
    box-shadow: none;
  }
}

.placeholder {
  text-align: start;
}

.fileList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.errorMessage {
  color: var(--content-error);
  margin-top: 4px;
}
