.refunds-total {
  color: var(--content-success);
}

.switch-recurrence {
  display: flex;
  justify-content: flex-end;
}

.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promo-code,
.promo-code-total,
.others,
.others-total,
.subscription-detail,
.total-vat {
  color: var(--content-secondary);
}

.fee-description {
  margin-right: 24px;
}
