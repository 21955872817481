.wrapper {
  padding: 24px;
}

.date {
  composes: caption from global;
  color: var(--content-secondary);
}

.title {
  composes: body-2 from global;
  flex: 1;

  &.rewarded {
    margin-bottom: 4px;
  }
}

.amount {
  color: var(--content-secondary);
}

.referral-item {
  display: flex;
  justify-content: space-between;
}
