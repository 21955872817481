:root {
  --radio-size: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--content-primary-a);
  font-size: 14px;

  .error {
    color: var(--background-error-high);
    display: flex;
    align-items: center;
    line-height: 24px;
  }

  .tooltip {
    background: none;
    border: none;
    color: var(--content-secondary);
    padding: 0;
  }
}
