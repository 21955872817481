.btn {
  gap: 8px;

  svg {
    transition: transform 0.2s;
    rotate: -90deg;
  }
}

.is-open {
  transform: rotate(180deg);
}
