.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  dl > div {
    display: grid;
    grid-template-columns: 0.7fr 1.3fr;
    gap: 16px;
  }
}
