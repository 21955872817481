.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

.content,
.illustration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 80px 48px;
  background: var(--elevation-low);
}

.content > section,
.content > div {
  width: 100%;
  max-width: 504px;
}

.learn-more-cta {
  width: unset;
}

.illustration {
  position: relative;
  padding: 160px 92px;
  background: var(--background-secondary);
}

.lottie {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
  height: 360px;
}
