.select[data-focus-visible] .popover-trigger[aria-expanded='true'] {
  box-shadow: 0 0 0 4px var(--border-accent-low);
}

.popover-trigger {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  padding: 0 4px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: var(--state-primary-a-hover);
  }

  &:focus-visible {
    outline: none;
    box-shadow: none;

    &[data-focused] {
      outline: none;
      box-shadow: none;
    }

    &[data-focus-visible] {
      background-color: var(--state-primary-a-hover);
      outline: none;
      box-shadow: 0 0 0 4px var(--border-accent-low);
    }
  }

  &[aria-expanded='true'] {
    background-color: var(--state-primary-a-hover);
    border-color: var(--border-accent-high);
    box-shadow: 0 0 0 4px var(--border-accent-low);

    .arrow-icon {
      transition: 0.15s transform;
      transform: rotate(180deg);
    }
  }

  .arrow-icon {
    width: 16px;
    height: 16px;
    transition: 0.15s transform;
  }
}

.list-box {
  width: 330px;
  padding: 8px 0;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.list-item {
  display: flex;
  align-items: center;
  width: 330px;
  height: 40px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: var(--elevation-high);

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
  }

  &[data-hovered='true'] {
    background-color: var(--background-secondary);
  }

  &[data-selected='true'] {
    background-color: var(--state-selected);
  }
}

.btn-disabled {
  &:hover {
    cursor: not-allowed;
    background-color: var(--bg-color);
    opacity: var(--disabled-opacity);
  }

  &::after {
    background-color: var(--overlay-primary-b-color);

    --overlay-primary-a-color: var(--primary-white);
    --overlay-opacity: var(--disabled-opacity);
  }
}
