.item {
  display: flex;
  gap: 16px;
  align-items: center;
  align-self: stretch;
  width: 100%;
  padding: 8px 16px;
  cursor: grab;
}

.item-disabled {
  color: var(--content-tertiary);
  cursor: not-allowed;
}

.item:not(.item-disabled):active {
  cursor: grabbing;
}

.icon {
  color: var(--content-secondary);
}

.icon-disabled {
  color: var(--content-tertiary);
}

.action {
  right: 0;
  width: 28px;
  height: 28px;
  margin-right: 16px;
  opacity: 0;
}

.item:hover .action {
  opacity: 1;
}

.button {
  width: 24px;
  height: 24px;
}
