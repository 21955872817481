.tooltip {
  z-index: 1001;
  position: absolute;
  padding-top: 16px;
  padding-bottom: 0;
  color: var(--content-secondary);
  background-color: var(--elevation-mid);
  width: 288px;
  border-radius: 8px;
  box-shadow: var(--shadow-high);
  pointer-events: none;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 12px;
  padding-right: 12px;
  justify-content: space-between;
}

.divider {
  border-top: 1px solid var(--border-tertiary);
  padding-top: 16px;
}

.value-column {
  color: var(--content-secondary);
}

.date-with-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.arrow-icon {
  color: var(--text-tertiary);
  width: 16px;
  height: 16px;
}
