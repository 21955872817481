.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 32px 48px;
  background-color: var(--elevation-default);
}

.header {
  padding: 32px 48px;
}

.insights {
  padding: 0 48px;
  margin-bottom: 16px;
}

.tabs-container {
  padding: 0 48px;
}

.tabs-item {
  padding: 16px 0;
}

.empty-state {
  height: 100%;
  padding: 0 48px;
}
