.card {
  composes: body-2 from global;
  display: grid;
  grid-template: repeat(3, auto) / 1fr auto;
  grid-template-areas: 'title amount' 'subtitle subtitle' 'actions actions';
  color: var(--content-primary-a);
  column-gap: 16px;
}

.title {
  grid-area: title;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.amount {
  composes: body-1 from global;
  grid-area: amount;
  white-space: nowrap;
  justify-self: end;
}

.subtitle {
  grid-area: subtitle;
  color: var(--content-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 16px;
}

.actions {
  white-space: nowrap;
  grid-area: actions;
  display: flex;
  gap: 8px;
}
