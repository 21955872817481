.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.datefield {
  width: 100%;
}

.calendarIcon {
  position: absolute;
  color: var(--content-primary-a);
  margin-right: 6px;
  top: 4px;
}

.readonly {
  background-color: var(--elevation-low);
  box-shadow: none;
}
