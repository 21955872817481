.card {
  width: 100%;
  padding: 16px;
  color: var(--content-secondary);
  background: var(--elevation-default);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.cardHeader {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  min-height: 26px;
  margin-bottom: 10px;
}

.row {
  composes: title-2 from global;
  display: flex;
  color: var(--content-primary-a);
}

.strikeThrough {
  color: var(--content-tertiary);
  text-decoration: line-through;
}
