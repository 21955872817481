.category-icon-wrapper {
  position: relative;
  width: 30px;
  padding: 3px;
  border-radius: 30px;

  &::before {
    position: absolute;
    inset: 0;
    padding: 1px;
    pointer-events: none;
    content: '';
    background: linear-gradient(
      90deg,
      var(--content-accent) 0,
      var(--dataviz-slice-7) 50%,
      var(--dataviz-slice-5) 100%
    );
    border-radius: inherit;

    --gradient: conic-gradient(#000 0 0);

    mask:
      var(--gradient) content-box exclude,
      var(--gradient);
  }
}

.notice-button {
  width: 100%;
  border: none;

  &::after {
    background-color: unset;
  }

  svg {
    color: inherit;
  }
}

.tooltip {
  display: -webkit-box;
  max-width: 304px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
