.container {
    display: flex;
    justify-content: center;
    margin: 16px 0;
    gap: 4px;
}

.dot {
    height: 6px;
    width: 6px;
    background-color: var(--content-tertiary);
    transition: background-color, width 200ms ease-in-out;
    border-radius: 9999px;
}

.dot.current {
    width: 18px;
    background-color: var(--content-primary-a);
}
