.supportCard {
  composes: body-1 from global;
  max-width: 504px;
  padding: 24px;
  color: var(--content-secondary);
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.title {
  color: var(--content-primary-a);
}

.icon {
  path {
    fill: var(--content-accent);
  }
}

.container {
  display: flex;
  align-items: center;
}
