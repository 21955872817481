.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 504px;
  height: 100%;
  margin: auto;
}

.content {
  > p {
    display: flex;
    justify-content: space-between;
  }
}

.row {
  display: flex;
  justify-content: space-between;
}

.divider {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid var(--border-tertiary);
}

.cta {
  width: max-content;
}
