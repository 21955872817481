.wrapper {
  position: relative;
  width: 504px;
  padding: 40px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 24px;
  box-shadow: var(--shadow-high);
  color: var(--content-primary-a);

  /* If there is a promotional illustration as a child of the popup, we need to style the close icon differently */
  &:has(.promotionalIllustration) {
    .close svg {
      color: var(--content-primary-a-static);
    }
  }
}

.dialog {
  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: none;
  }
}

.header {
  margin-bottom: 16px;
}

.badge {
  margin-bottom: 16px;
}

.title {
  margin-top: 0;
  margin-bottom: 16px;
  overflow-wrap: break-word;
}

.subtitle {
  overflow-wrap: break-word;
}

.body {
  margin-top: 24px;
}

.footer {
  display: flex;
  margin-top: 24px;
  gap: 16px;
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;
  color: var(--content-primary-a);

  svg {
    width: 16px;
    height: 16px;
  }

  /* This selector can be removed once Popup.tsx is removed in favor of the Composable Popup */
  &.withIllustration svg {
    color: var(--content-primary-a-static);
  }
}

:global(.react-aria-ModalOverlay) {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-above);
  width: 100%;
  height: 100%;
  background-color: var(--background-modal-overlay);
  animation: fadeIn 0.5s ease-in-out;
}

:global(.react-aria-ModalOverlay) > div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.illustration {
  margin: 0;
}

.promotionalIllustration {
  box-sizing: border-box;
  margin: -40px -40px 24px;
}

.promotionalIllustration > * {
  width: 100%;
  border-radius: 23px 23px 0 0;
}

@media (max-width: 776px) {
  .wrapper {
    width: 100%;
  }
}
