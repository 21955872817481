.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:active::after {
    --overlay-opacity: var(--hover-opacity);
  }
}

.infos {
  display: flex;
  flex-direction: column;
  padding: 8px 24px;
}

.key {
  color: var(--content-secondary);
}

.container:not(:hover) .action {
  display: none;
}

.action {
  right: 0;
  margin-right: 16px;
}
