.wrapper {
  position: relative;
  overflow: hidden;
  background-color: var(--elevation-low);
  border-radius: 100%;
}

.large {
  width: 40px;
  height: 40px;
}

.small {
  width: 24px;
  height: 24px;
}

.x-small {
  width: 16px;
  height: 16px;
}

.logo {
  display: block;
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  inset: 0;
  border-radius: 100%;
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  box-shadow: inset 0 0 0 1px rgb(from var(--primary-black) r g b / 0.2);
}
