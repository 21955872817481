.container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.label {
  color: var(--content-secondary);
  white-space: nowrap;
}

.value {
  display: flex;
  align-items: center;
  margin: 0;
  overflow: hidden;
}

.link {
  overflow: hidden;
  color: var(--content-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copy-btn {
  display: flex;
  align-items: center;
}
