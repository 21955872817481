.container {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 7;
  gap: 0;
  width: 290px; /* copied from Figma, fixed on purpose (side-scroll) */
  max-width: 290px;
  box-sizing: border-box;
  padding: 24px;
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
  transition: box-shadow var(--transition-duration-short) ease-in-out;
  background-color: var(--elevation-mid);
  color: var(--content-primary-a);

  &:hover {
    box-shadow: var(--shadow-high);
  }
}

.subgrid-section {
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 7;
}

.heading {
  margin: 0;
}

.feature-heading {
  margin: 16px 0;
}

.price-info {
  margin-bottom: 5px;
}

.badge {
  max-height: 24px;
}

.badge:empty {
  max-height: initial;
}

.button {
  margin-top: 11px;
}

.button:empty {
  display: none;
}

.price-monthly {
  color: var(--content-secondary);
  text-decoration: line-through;
}

.billing {
  color: var(--content-secondary);
  line-height: 24px;
}

.list {
  margin: 0;
  padding-left: 20px;
  list-style-type: square;
}

.soon-list {
  color: var(--content-secondary);
}

div.divider {
  background: linear-gradient(90deg, var(--border-tertiary) 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 10px 1px;
  margin: 8px 0;
}

@media only screen and (max-width: 777px) {
  .container {
    height: 100%;
  }
}
