.aside-section {
  padding: 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.lines {
  position: relative;
  display: flex;
  flex-direction: column;
}

.wrapper-line {
  display: flex;
  gap: 12px;
  align-items: flex-end;
}

.input {
  display: flex;
  flex-direction: column;
}

.label {
  margin: 8px 0;
}

.title {
  margin: 4px 0 20px;
}
