.footerSelector button > span {
  /* targeting the selected option when dropdown is closed */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footerSelector [data-invalid='false'] {
  /* bug where the border color shows red when [data-invalid="false"] */
  border-color: var(--border-secondary);
}

.selectOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.bankAccountBalance {
  color: var(--content-secondary);
}

.approveButton {
  color: var(--content-primary-b-static);
  background: var(--background-success-high);
}

.footerButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;

  button {
    width: 100%;
  }
}
