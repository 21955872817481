.sticky-panel {
  position: sticky;
  bottom: 0;
  padding-bottom: 24px;
  padding-left: 12px;
  margin-right: -12px;
  margin-left: -12px;
  background-color: var(--elevation-low);

  &::after {
    position: absolute;
    top: -32px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 32px;
    content: '';
    background: linear-gradient(180deg, transparent 0%, var(--elevation-low) 90%);
  }
}
