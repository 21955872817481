.itemSkeleton {
  display: flex;
  padding: 16px;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
}

.column:first-child {
  align-items: flex-start;
}

.column:last-child {
  align-items: flex-end;
}

.column > div:first-child {
  margin-bottom: 10px;
}
