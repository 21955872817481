.cell-content {
  composes: body-1 from global;
  display: flex;
  align-items: center;
}

.expense-report-icon {
  display: flex;
  align-items: center;
  width: unset;
  margin-right: 16px;
}
