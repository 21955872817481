.disclaimer {
  width: 100%;
  margin: 16px 0;
}

.ghost {
  color: var(--content-tertiary);
  text-decoration: line-through;
}

.view {
  composes: body-2 from global;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  color: var(--content-primary-a);
  text-decoration: none;
  border-top: 1px solid var(--border-tertiary);

  svg {
    color: var(--content-accent);
  }
}
