.wrapper {
  display: flex;
  align-items: center;
}

.dots-wrapper {
  display: flex;
  align-items: center;
}

.dot {
  display: block;
  width: 4px;
  height: 4px;
  margin-right: 2px;
  background-color: var(--content-primary-a);
  border-radius: 50%;
}
