.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  align-self: stretch;
  width: 274px;
  height: 100%;
  padding: 24px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
}

.illustration {
  width: 80px;
  height: 80px;
}

.content,
.title,
.subTitle {
  display: block;
}

.title {
  composes: title-3 from global;
  color: var(--content-primary-a);
  text-align: center;
}

.subTitle {
  composes: body-1 from global;
  color: var(--content-primary-a);
  text-align: center;
}
