.discount-field {
  display: flex;
  flex-direction: row;
}

.amount-field {
  flex-grow: 1;

  > input {
    position: relative;
    margin-right: -1px;
    border-right: 0;
    border-radius: 4px 0 0 4px;

    &:focus-within {
      z-index: 1;
      border-right: 1px solid var(--border-accent-high);
      border-radius: 4px;
    }

    &:disabled {
      width: 339px;
    }
  }
}

.select {
  min-width: 80px;

  button {
    border-radius: 0 4px 4px 0;

    &:focus {
      border-radius: 4px;
    }

    &:focus-within {
      z-index: 1;
      border-right: 1px solid var(--border-accent-high);
    }
  }
}

.trash-btn {
  margin: 4px 0 0 8px;
}
