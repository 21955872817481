.tag {
  display: inline-flex;
  align-items: center;
  padding: 3px 5px;
  gap: 6px;
  font-size: var(--caption-font-size);
  font-weight: var(--caption-font-weight);
  line-height: var(--caption-line-height);
  border: 1px solid var(--border-tertiary);
  color: var(--content-primary-a);
  background-color: var(--elevation-default);
  border-radius: 4px;
}

.icon {
  display: flex;
}

.green {
  color: var(--content-success);
}

.red {
  color: var(--content-error);
}

.purple {
  color: var(--content-accent);
}

.orange {
  color: var(--content-warning);
}

.gray {
  padding: 4px 6px;
  border: none;
  color: var(--content-primary-a);
  background: var(--background-tertiary);
}
