.container {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
}

.icon {
  display: flex;
}

.title-group {
  min-width: 0; /* Need this for ellipsis because the container is using flex */
  text-align: left;
}

.subtitle {
  composes: caption from global;
  color: var(--content-secondary);
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.info {
  color: var(--content-tertiary);
}

.error {
  color: var(--content-error);
}

.error.subtitle {
  color: var(--content-error);
}
