.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--elevation-default);
}

.wrapper figure {
  margin: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 28px 0;
  background: var(--elevation-mid);
  border-bottom: 1px solid var(--border-tertiary);
}

.lh24 {
  line-height: 24px;
}

.content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin: 0 36px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  width: 35vw;
  min-width: 504px;
}

.illustration {
  width: 200px;
  height: 200px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  color: var(--content-primary-a);
}
