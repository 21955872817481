.container {
  background: var(--elevation-default);
}

.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.main {
  display: flex;
  flex-direction: column;
  width: 504px;
}

.description {
  color: var(--content-primary);
}

.description a {
  color: var(--content-accent);
}

.calculation-item {
  display: flex;
  justify-content: space-between;
}

.total-km {
  color: var(--content-secondary);
}

.calculation-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.btn {
  align-self: flex-start;
}
