.sectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 504px;
  height: 100%;
  margin: auto;
}

.subtitle {
  align-self: start;
  width: 90%;
}
