.step {
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4px;
  margin-right: 12px;
}

.dot-wrapper {
  width: 4px;
  height: 4px;
  margin: 4px 0;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: var(--background-secondary);
  border-radius: 50%;

  &.green {
    background-color: var(--background-primary);
  }
}

.join {
  width: 4px;
  height: 100%;
  min-height: 36px;
  background-color: var(--background-secondary);
  border-radius: 20px;

  &.green {
    background-color: var(--background-primary);
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-top: -6px;
}
