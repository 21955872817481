.category-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1 / 1;
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  color: var(--primary-black);
  border-radius: 50%;

  svg:not([data-test-status-avatar-icon]) {
    width: 58%;
    height: 58%;
  }

  svg[data-test-status-avatar-icon] {
    right: -4px;
    bottom: -4px;
    width: 16px;
    height: 16px;
  }
}
