.marketingButton {
  display: inline-flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 10px;
  box-shadow: var(--shadow-high);
}
