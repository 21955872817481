.menu-trigger {
  width: 32px;
  height: 32px;
  margin-left: 16px;
}

.menu-popover {
  min-width: 208px;
  padding: 8px 0;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);

  &:focus-visible {
    box-shadow: none;
  }
}

.menu:focus-visible {
  box-shadow: none;
}

.menu-item {
  composes: body-2 from global;
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  color: var(--content-primary-a);
  cursor: pointer;

  &:hover {
    background-color: var(--background-secondary);
  }

  &:focus-visible {
    box-shadow: none;
  }
}

.destructive {
  color: var(--content-error);
}
