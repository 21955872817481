.icon {
  flex-shrink: 0;
  margin-right: 16px;
}

.cell {
  border-bottom: 1px solid var(--border-tertiary);
}

.cellContent {
  display: flex;
  align-items: center;
}

.alignRight {
  margin-left: auto;
}

.hidden {
  display: none;
}
