.page-header {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.page-header > * {
  flex-shrink: 0;
}

.section-title {
  display: flex;
  align-items: center;
}

.title {
  composes: title-1 from global;
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: var(--content-primary-a);
}

.title a {
  color: var(--content-tertiary);
  text-decoration: none;
}

.title :global(.tooltip-target) {
  display: flex;
  color: var(--content-primary-a);
}

.title svg {
  width: 16px;
  height: 16px;
  color: var(--content-secondary);
}

.spacer {
  flex-grow: 1;
}
