.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 219px;
  height: 216px;
  padding: 24px;
  color: var(--content-primary-a);
  cursor: pointer;
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}

.container:hover {
  box-shadow: var(--shadow-high);
}

.selected {
  border-color: var(--border-accent-high);
}

.illustration {
  width: 171px;
  height: 120px;
}

.title {
  display: flex;
  align-items: center;
}
