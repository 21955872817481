.highlight {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px 6px;
  white-space: nowrap;
  cursor: default;
  user-select: none;
  border-radius: 4px;
  color: var(--content-primary-a);
  background-color: var(--background-secondary);
}
