.empty {
  width: 48px;
  min-width: 48px;
  padding: 0;
}

.supplier {
  padding-right: 32px;
}

.completedOn {
  padding-right: 24px;
}

.totalRepayment {
  padding-left: 24px;
  text-align: right;
}

.headerContent {
  composes: caption-bold from global;
  display: inline-flex;
  color: var(--content-secondary);
}

.active {
  color: var(--content-primary-a);
}

.headerCell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  padding: 11px 0;
  text-align: left;
  white-space: nowrap;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}
