.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.icon {
  width: 16px;
  height: 16px;

  &.not-paid {
    color: var(--content-secondary);
  }

  &.paid {
    color: var(--content-success);
  }
}

.title {
  display: flex;
  gap: 8px;
  align-items: center;
}

.content-tertiary {
  color: var(--content-tertiary);
}

.overdue {
  color: var(--content-error);
}

.at-risk {
  color: var(--content-warning);
}
