.details-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 32px 0;
}

.details-main {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 48px 16px 16px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.details-secondary {
  display: flex;
  gap: 16px;
}

.details-avatar {
  position: absolute;
  top: -32px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.name-field {
  input {
    height: 40px;
  }
}

.select-trigger {
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  height: 40px;
  padding: 0 12px;
  margin-top: 4px;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
}

.select-type-trigger {
  composes: select-trigger;
  width: 100%;
}

.select-trigger-content {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.select-trigger-text {
  composes: body-1 from global;
  flex-grow: 1;
  text-align: start;
}

.select-trigger-arrow {
  flex-shrink: 0;
}

.select-popover {
  padding: 8px 0;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.select-type-popover {
  composes: select-popover;
  width: var(--trigger-width);
}

.select-listbox-item {
  composes: body-2 from global;
  height: 40px;
  padding: 8px 16px;

  &:hover {
    background-color: var(--state-primary-a-hover);
  }

  &[data-selected='true'] {
    background-color: var(--state-selected);
  }
}

.select-color-trigger {
  composes: select-trigger;
  width: auto;
}

.select-color-popover {
  composes: select-popover;
  width: auto;
}

.color-option {
  display: flex;
  flex-shrink: 0;
  gap: 12px;
  align-items: center;
  width: 100%;
}

.color-dot {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.select-trigger-content .color-name {
  display: none;
}

.select-trigger-content .color-dot {
  width: 20px;
  height: 20px;
}

.select-icon-trigger {
  composes: select-trigger;
  width: auto;
}

.select-icon-popover {
  composes: select-popover;
  width: auto;
  max-height: 230px;
  padding: 16px;
  overflow-y: auto;
}

.select-trigger-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-icon-listbox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
}

.select-icon-listbox-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 4px;

  .icon-option {
    width: 24px;
    height: 24px;
  }

  &[data-selected='true'] {
    background-color: var(--background-secondary);
  }

  &[data-hovered='true'] {
    background-color: var(--state-selected);

    .icon-option {
      /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token -- design requirement */
      color: var(--primary-black);
    }
  }
}

.select-trigger-content .icon-option {
  width: 20px;
  height: 20px;
}
