.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.input-wrapper {
  display: flex;
  align-items: center;
}

.icon {
  position: absolute;
  margin-left: 12px;
  color: var(--content-primary-a);
  pointer-events: none;
}

.input.input {
  padding: 8px 12px 8px 36px;
  width: 100%;
  background-color: var(--background-tertiary);
  border: none;
  pointer-events: all;
}

.input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.container[data-empty='true'] .close {
  display: none;
}

.input.compact {
  height: 32px;
  line-height: 40px;
  padding: 0 36px;
}

.close {
  position: absolute;
  right: 0;

  svg path {
    fill: var(--content-tertiary);
  }
}

.spinner {
  position: absolute;
  right: 35px;
}
