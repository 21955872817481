.row {
  height: 64px;
}

.cell {
  padding: 0 37px 0 0;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);
}

.cell.empty {
  width: 48px;
  padding: 0;
  border-bottom: none;
}

.icon {
  margin-right: 16px;
}

.type {
  display: flex;
  gap: 16px;
  align-items: center;
}

.amount-content {
  margin-left: auto;
}

.cell-approve {
  padding: 0;
}

.approve-content {
  margin-left: auto;
}

/* compact view  - no bank account select */
.row-compact .cell {
  padding: 0 75px 0 0;
}

.row-compact .cell.empty {
  padding: 0;
}

.row-compact .cell-approve {
  padding: 0;
}
