.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: var(--lottie-background-mint);

  &.purple {
    background-color: var(--lottie-background-purple);
  }

  &.mint {
    background-color: var(--lottie-background-mint);
  }

  &.mustard {
    background-color: var(--lottie-background-mustard);
  }

  &.peach {
    background-color: var(--lottie-background-peach);
  }
}

.mainImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 504px;
  color: var(--content-primary-a-static);
  text-align: center;
}

.mainImage {
  width: 360px;
}
