.divider {
  position: relative;
  padding: var(--space-s) 0;
  font-size: var(--font-size-xs);
  color: var(--neutral-500);
  text-align: center;
  text-transform: uppercase;
}

.divider::before,
.divider::after {
  position: absolute;
  top: 50%;
  width: calc(50% - 20px);
  height: 1px;
  content: '';
  background-color: var(--neutral-200);
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}
