.search-input {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  line-height: 40px;
  color: var(--content-primary-a);
  appearance: none;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
  transition: border-color 0.5s;

  &:disabled {
    cursor: not-allowed;
    background-color: var(--elevation-low);
    border-color: var(--border-secondary);
    opacity: 0.45;
  }

  &:read-only {
    background-color: var(--elevation-low);
  }

  &::placeholder {
    color: var(--content-tertiary);
    text-overflow: ellipsis;
    opacity: 1;
  }
}

.search-input--error {
  border: 1px solid var(--border-error);
}
