@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.region {
  position: fixed;
  bottom: 24px;
  left: 24px;
  z-index: var(--z-index-above);

  & > div {
    &[data-animation='entering'],
    &[data-animation='queued'] {
      animation: slideInFromLeft 0.5s ease-out;
    }

    &[data-animation='exiting'] {
      animation: slideOutToLeft 0.5s ease-out;
    }
  }
}

.toast {
  width: 320px;
  padding: 16px 20px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--background-primary);
  color: var(--content-primary-b);
}

.toast-content {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.text {
  flex: 1;
}

.icon-container {
  height: 24px;
  display: flex;
  align-items: center;
}

.close {
  margin-left: auto;
  width: 24px !important;
  height: 24px !important;
}

.close svg {
  width: 16px !important;
  height: 16px !important;
  color: var(--content-primary-b) !important;
}

.error,
.error svg {
  background-color: var(--background-error-high);
  color: var(--content-primary-b-static);
}
