.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  min-height: 404px;
  padding: 24px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);

  @media only screen and (width < 980px) {
    min-height: auto;
  }
}

.metadata {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > * {
    flex: 1;
  }
}

.risks-container {
  flex-basis: 100%;
}

.risks {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 0;
}
