.card {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 16px;
  margin-bottom: 16px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 5px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
}
