.section {
  padding: 12px 24px 24px 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.list-item {
  padding: 8px 16px;
  cursor: pointer;

  &[data-selected='true'] {
    background-color: var(--state-selected);
  }

  &[data-hovered='true'] {
    background-color: var(--elevation-low);
    box-shadow: none;
  }

  &[data-hovered='true'][data-selected='true'] {
    background-color: var(--state-selected-hover);
  }
}
