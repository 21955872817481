.cell {
  height: 64px;
  padding: 0;
  padding-right: 48px;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);
}

.placeholderLine {
  padding: 4px 8px;
}

.counterparty {
  @media only screen and (width > 1368px) {
    width: 50%;
  }
}

.counterpartyContent {
  display: flex;
  gap: 16px;
  align-items: center;
}

.avatarPlaceholder {
  flex-shrink: 0;
}

.amount {
  width: 112px;
}

.removeTransaction {
  width: 84px;
  padding: 0;
}

.empty {
  padding: 0;
}
