.wrapper {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: var(--content-secondary);
  background-color: var(--elevation-default);
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;
  box-shadow: var(--shadow-low);
}

.size-32 {
  min-width: 32px;
  height: 32px;
}

.size-28 {
  min-width: 28px;
  height: 28px;
}
