.total {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 0 16px;
}

.buttonWidth {
  width: fit-content;
}
