.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--elevation-low);
}

.benefits-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 552px;
  padding-bottom: 32px;
  margin: auto;
  margin-top: 80px;
  text-align: left;
}

.list {
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 24px;
  list-style: none;
}

.card {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px;
  margin-bottom: 12px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.icon {
  width: 32px;
  height: 32px;
}

.btn-keep-current {
  align-self: center;
}
