.operation-select-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  padding: 8px 12px;
  margin-right: 8px;

  &:global(.power-select-customs-trigger.block.ember-basic-dropdown-trigger--below) {
    box-shadow: none;
  }
}
