.header {
  z-index: var(--z-index-default);
  height: 64px;
}

.container {
  display: grid;
  flex: 1;
  grid-template-columns: 564px 1fr;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.form-section {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--elevation-default);
  border-right: 1px solid var(--border-tertiary);
}

.form-content {
  flex-grow: 1;
  padding: 32px 0 48px;
}

.form-wrapper {
  width: 100%;
}

.form-fields {
  padding: 0 48px 64px 48px;
}

.footer {
  position: fixed;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 564px;
  min-height: 72px;
  padding: 0 48px;
  background-color: var(--elevation-default);
  border-top: 1px solid var(--border-tertiary);
  border-right: 1px solid var(--border-tertiary);
}

.preview {
  padding: 48px;
  overflow-y: auto;
  background-color: var(--elevation-low);

  .preview-content {
    width: 708px;
    margin: 0 auto;
  }
}
