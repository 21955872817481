.row {
  height: 64px;
  vertical-align: middle;
}

.cell {
  border-bottom: 1px solid var(--border-tertiary);
}

.cell-content {
  display: flex;
  align-items: center;
}

.supplier {
  min-width: 240px;
  max-width: 0;
  padding-right: 32px;
}

.next-installment {
  width: 350px;
  min-width: 216px;
  padding-right: 24px;
}

.installment-amount {
  width: 220px;
  min-width: 202px;
}

.total-repayment {
  width: 200px;
  min-width: 140px;
  padding-left: 24px;
}

.cell-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.empty {
  width: 48px;
}

.align-right {
  margin-left: auto;
}
