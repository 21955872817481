.trigger {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.value {
  composes: body-1 from global;
  flex: 1;
  overflow: hidden;
  color: var(--content-primary-a);
  text-overflow: ellipsis;
  white-space: nowrap;

  &.placeholder {
    color: var(--content-tertiary);
  }
}

.clear {
  color: var(--content-secondary);
}

.chevron-down {
  display: none;
  transition: transform 300ms;
}

.empty,
:global(.ember-basic-dropdown-trigger[aria-expanded='true']) {
  .clear {
    display: none;
  }

  .chevron-down {
    display: initial;
  }
}

:global(.ember-basic-dropdown-trigger[aria-expanded='true']) .chevron-down {
  display: initial;
  transform: rotate(180deg);
}
