.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  padding: 0 24px 24px;
  background-color: var(--elevation-low);
}

.close {
  position: absolute;
  top: calc(50% - 32px);
  right: 2px;
  z-index: var(--z-index-default);

  &:hover {
    cursor: pointer;
  }
}

.success {
  color: var(--content-success);
}

.error {
  color: var(--content-error);
}
