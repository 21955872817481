.freemiumUpgrade {
  position: relative;
  width: 100%;
  background: var(--elevation-default);

  .wrapper {
    height: 100%;
    overflow: auto;

    .content {
      display: flex;
      flex-direction: column;
      max-width: 1280px;
      padding: 32px 48px;
      margin: 0 auto;
      text-align: center;

      .marketingCards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        margin-bottom: 40px;

        :first-child {
          grid-row: span 2;
        }
      }

      .upgradeFeatures {
        display: flex;
        gap: 80px;
        margin: 80px 0;
        text-align: left;

        > * {
          flex: 1;
        }

        .upgradeFeaturesText {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h2 {
            max-width: 470px;
          }
        }
      }
    }
  }

  .marketingButtonWrapper {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
}
