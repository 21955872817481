.projected-forecast-toggle {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 12px;
}

.disabled-toggle {
  cursor: not-allowed;
}
