.banner {
  display: flex;
  justify-content: center;
  height: 216px;
  background-color: var(--background-activate);
}

.banner-de {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: var(--background-activate);
}

.audience-1 {
  width: 305px;
  margin-right: 16px;
}

.audience-1-de {
  width: 90%;
}

.audience-2,
.audience-2-de {
  width: 433px;
}

.audience-3 {
  width: 350px;
  padding-top: 30px;
}

.audience-3-de {
  width: 100%;
  padding-top: 16px;
}
