.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
}

.name-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  min-height: 32px;

  .name {
    line-height: 1; /* to avoid excessive spacing with badge (passed as child) when wrapping */
  }
}

.pricing {
  flex-shrink: 0;
}

.subtitle {
  color: var(--content-secondary);
}

.title {
  display: flex;
  gap: 4px;
  align-items: baseline;
  justify-content: space-between;
}
