.transactionPlaceholder {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 16px;
  color: var(--content-primary-a);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}

.transactionPlaceholderLine {
  padding: 8px 0;
}
