.container {
  position: relative;
  display: inline-flex;
  width: 80px;
}

.buttonContent {
  width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.triggerButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  max-height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 0 4px 4px 0;

  svg {
    color: var(--primary-content-b);
  }

  &:active::after,
  &:hover::after,
  &[disabled]::after,
  &[aria-disabled='true']::after {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus {
    z-index: 2;
  }
}

.triggerButtonIcon {
  transition: transform 0.2s ease-in-out;
  transform: rotate(0deg);
}

.triggerButtonOpen {
  border: 1px solid var(--border-accent-high);

  .triggerButtonIcon {
    transform: rotate(180deg);
  }
}

.menu {
  width: 80px;
  color: var(--primary-content-a);
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.menuItem {
  position: relative;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  overflow: hidden;
  color: var(--content-primary-a);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: none;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background-color: var(--overlay-primary-a-color);
    opacity: var(--overlay-opacity);
  }

  &:hover::after,
  &:focus::after {
    --overlay-opacity: var(--hover-opacity);
  }

  &[disabled],
  &[aria-disabled='true'] {
    cursor: not-allowed;

    &::after {
      --overlay-primary-a-color: var(--primary-white);
      --overlay-opacity: var(--disabled-opacity);

      cursor: not-allowed;
    }
  }
}
