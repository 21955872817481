.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.chartOverlay {
  position: absolute;
  top: 0;
  z-index: var(--z-index-above);
  width: 100%;
}
