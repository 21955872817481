.preview-content {
  display: flex;
  height: 100%;

  > div {
    flex: 1;
    justify-content: stretch;
    padding: 80px 48px;

    &:first-child {
      background-color: var(--elevation-default);
    }

    &:last-child {
      background-color: var(--elevation-low);
    }
  }

  .right-column {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
