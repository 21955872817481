.footer p {
  display: flex;
  align-items: baseline;
  justify-content: center;
  padding: 0;
  margin: 0;
  color: var(--content-secondary);

  &.with-payment-link {
    gap: 2px;
    line-height: 16px;
    font-size: 10px;
    color: var(--content-primary-a);
  }

  & svg:not(.mollie-logo) {
    width: 10px;
    height: 8px;
  }

  & .mollie-logo {
    width: 30px;
    height: 8px;
  }
}
