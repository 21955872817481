.container {
  padding: 48px;
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  color: var(--content-primary-a);
  background-color: var(--elevation-default);
  display: grid;

  & > div {
    grid-row: 1;
    grid-column: 1;
  }
}

.overlay {
  background: linear-gradient(to bottom, transparent, var(--elevation-default));
  z-index: 1;
}

.inline {
  display: flex;
  gap: 16px;

  & > div {
    flex: 1;
  }
}
