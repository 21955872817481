.marketingCard {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 26px 24px;
  text-align: center;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  transition: box-shadow 0.25s ease-in-out;

  .subtitle {
    color: var(--content-secondary);
  }

  &:hover {
    box-shadow: var(--shadow-high);
  }

  &.primary {
    flex-direction: column;
    justify-content: center;

    .lottie {
      flex-shrink: 0;
      width: 268px;
      height: 268px;
    }
  }

  &.secondary {
    justify-content: space-between;
    text-align: left;

    .lottie {
      flex-shrink: 0;
      width: 200px;
      height: 200px;
    }
  }
}
