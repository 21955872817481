.calendar {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background-color: var(--elevation-high);
  box-shadow: var(--shadow-high);

  [data-outside-visible-range] {
    display: none;
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid {
  border-spacing: 0 12px;
}

.icon-chevron {
  color: var(--content-primary-a);
}

.header-cell {
  color: var(--content-secondary);
  padding: 0;
  width: 32px;
  height: 32px;
}

.body-cell {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--content-primary-a);
  cursor: pointer;
}

.body-cell::after {
  border-radius: 50%;
}

.body-cell[data-selected] {
  background-color: var(--background-primary);
  color: var(--content-primary-b);
}

.body-cell[data-disabled] {
  opacity: 0.45;
  cursor: not-allowed;
}
