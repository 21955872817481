.container {
  color: var(--content-primary-a);
  border: none;
  padding: 0;
  background-color: var(--elevation-default);
}

.title {
  display: none;
}

.payment-methods-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.payment-methods-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media only screen and (width >= 1369px) {
  .container {
    padding: 48px;
    border: 1px solid var(--border-tertiary);
    border-radius: 8px;
  }

  .title {
    display: block;
  }
}

/* Preview should have desktop styles regardless of screen size */
.container.preview {
  padding: 48px;
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;

  .title {
    display: block;
  }
}
