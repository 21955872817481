.popover {
  max-height: 310px;
  padding: 12px 0;
  overflow: auto;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.section,
.list-box {
  display: flex;
  flex-direction: column;
}

.section-title {
  composes: caption from global;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  color: var(--content-secondary);
}
