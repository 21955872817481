.wrapper {
  position: relative;
}

.mask {
  position: absolute;
  inset: 0;
  overflow: hidden;
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  background-color: var(--purple800);
  filter: blur(8px);
}

.glow {
  position: absolute;
  top: -80%;
  left: -10%;
  width: 120%;
  height: 260%;
  filter: blur(38px);
  border-radius: 50%;
}

.glow--1 {
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  background-color: var(--purple400);
  animation: glow--1 6s infinite linear;
}

.glow--2 {
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  background-color: var(--peach800);
  animation: glow--2 6s infinite linear;
}

.glow--3 {
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  background-color: var(--mint800);
  animation: glow--3 6s infinite linear;
}

@keyframes glow--1 {
  0% {
    transform: translate(-8%, 12%);
  }

  25% {
    transform: translate(8%, 12%);
  }

  50% {
    transform: translate(8%, -12%);
  }

  75% {
    transform: translate(-8%, -12%);
  }

  100% {
    transform: translate(-8%, 12%);
  }
}

@keyframes glow--2 {
  0% {
    transform: translate(70%, 30%);
  }

  25% {
    transform: translate(70%, -30%);
  }

  50% {
    transform: translate(-70%, -30%);
  }

  75% {
    transform: translate(-70%, 30%);
  }

  100% {
    transform: translate(70%, 30%);
  }
}

@keyframes glow--3 {
  0% {
    transform: translate(-60%, 55%);
  }

  25% {
    transform: translate(60%, 55%);
  }

  50% {
    transform: translate(60%, -55%);
  }

  75% {
    transform: translate(-60%, -55%);
  }

  100% {
    transform: translate(-60%, 55%);
  }
}
