.spinner {
  display: flex;
  align-items: center;
}

/* Color */
.primary-a svg path {
  stroke: var(--content-primary-a);
}

.primary-b svg path {
  stroke: var(--content-primary-b);
}

.primary-b-static svg path {
  stroke: var(--content-primary-b-static);
}

.danger svg path {
  stroke: var(--content-error);
}

/* Size */
.small {
  width: 12px;
  height: 12px;
}

.medium {
  width: 16px;
  height: 16px;
}

.large {
  width: 24px;
  height: 24px;
}
