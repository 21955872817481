.button {
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  margin-top: 8px;
  color: var(--content-primary-a);
  text-align: left;
  text-overflow: ellipsis;
  border: none;

  &::after {
    border-radius: 0;
  }
}

.divider {
  padding-top: 8px;
  border-bottom: 1px solid var(--border-tertiary);
}
