.selector {
  display: flex;
  width: 100%;
  min-height: 48px;
  padding: 12px 16px;
  text-decoration: unset;
  background-color: var(--elevation-high);
  border-radius: 4px;
  transition: all var(--transition-duration-short) ease-in-out;
}

.content {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  text-align: left;
}

.icon {
  display: flex;
}
