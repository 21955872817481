.title {
  composes: body-2 from global;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  max-width: 280px;
  overflow: hidden;
  font-size: 12px;
  line-height: 14px;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;
}
