.emblaContainer {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
}

.emblaSlide {
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
}
