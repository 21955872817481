.infobox {
  width: 100%;
}

.account-infobox {
  composes: infobox;
  border-bottom: 1px solid var(--border-tertiary);
}

.check-sidebar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 16px 13px;
  padding-bottom: 24px;
  background-color: var(--elevation-low);
  border-bottom: 1px solid var(--border-tertiary);
}

.type {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
}

.validated {
  color: var(--content-success);
}

.pending,
.received {
  color: var(--content-warning);
}

.canceled,
.declined,
.refunded {
  color: var(--content-error);
}

.date {
  color: var(--content-tertiary);
}

.close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.column-layout {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.container {
  padding-top: 56px;
  margin-top: 32px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;
}

.picto {
  position: absolute;
  top: -32px;
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
}

.avatar svg {
  right: -2px;
  bottom: -2px;
  width: 24px;
  height: 24px;
}

.status {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
}

.general {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.amount {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.counterparty {
  width: 285px;
  overflow: hidden;
  color: var(--content-primary-a);
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
