@keyframes slide {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-above);
  width: 100%;
  height: 100%;
  background-color: var(--background-modal-overlay);
}

.modal[data-entering] {
  animation: slide 250ms ease;
}

.modal[data-exiting] {
  animation: slide 250ms ease reverse;
}

.modal {
  height: 100vh;
  overflow: auto;
  background-color: var(--elevation-low);
  opacity: 1;
}

.main {
  position: relative;
  width: 100%;
  max-width: 504px;
  height: 100%;
  margin: 80px auto 0;
}

.actions {
  position: sticky;
  bottom: 0;
  display: flex;
  width: calc(100% + 30px);
  padding: 30px 0 22px 15px;
  margin-right: -15px;
  margin-left: -15px;
  background: linear-gradient(180deg, transparent 0%, var(--elevation-low) 25%);
}
