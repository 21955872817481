.badge {
  align-self: flex-start;
}

.tooltipContainer {
  align-self: center;
  margin-left: 8px;

  button {
    cursor: default;
  }
}
