.wrapper {
  flex: 1;
  max-width: 552px;
  padding-top: 24px;
  margin-top: 4px;
  margin-right: 24px;
  background: var(--elevations-default);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-high);
}

.cardHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 24px;
}

.subtitleCaption {
  color: var(--content-secondary);
}

.estimatedEarnings {
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 4px;
  column-gap: 24px;
  align-items: center;
}

.progress {
  border-radius: 4px;
}

.progressBar {
  display: grid;
  grid-template-rows: 1fr auto;
}

.progressBarText {
  display: flex;
  justify-content: space-between;
  color: var(--content-primary-a);
}

.subtitle {
  color: var(--content-secondary);
}

.badge {
  max-height: 24px;
}

.coreInformation {
  padding: 24px;
}

.secondaryInformation {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px;
  box-shadow: inset 0 1px 0 var(--border-tertiary);
}

.remunerationTable {
  border-radius: 0 0 8px 8px;
}

.remunerationTable,
.secondaryInformation {
  background-color: var(--elevation-low);
}

.name {
  composes: title-3 from global;
}

.separator {
  padding: 0 4px;
}

.remunerationLine {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.germanDisclaimer {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: baseline;
}

.germanDisclaimer svg {
  color: var(--content-accent);
}

.table {
  display: flex;
  justify-content: space-between;
  max-height: 48px;
  padding: 12px 24px;
  border-top: 1px solid var(--border-tertiary);
  border-right: 1px solid var(--border-tertiary);
}

.tableRight {
  border-right: none;
}
