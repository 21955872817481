.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
}

.select {
  width: 208px;
}

.sectionTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--content-primary-a);
}

.flagIcon {
  margin-right: 12px;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.selectLabel {
  font-size: 14px;
  color: var(--content-primary-a);
}

.actionsContainer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.orSeparator {
  font-size: 14px;
  color: var(--content-secondary);
}
