.steps-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 2px 0;

    &:last-child > div {
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.step-wrapper {
  display: flex;
  gap: 12px;
}

.content-container {
  padding-bottom: 12px;
}
