.meter {
  line-height: 0px;

  svg {
    width: 100%;
  }
}

.meter-value-now {
  fill: var(--content-accent);
}

.meter-value-now.low {
  fill: var(--content-warning);
}

.meter-value-now.high {
  fill: var(--content-error);
}

.meter-value-now.disabled {
  fill: var(--content-tertiary);
}

.meter-background {
  fill: var(--background-secondary);
}
