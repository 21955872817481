.connect-app-header-meta {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.connect-app-header-content-left {
  flex-grow: 1;
}

.application-avatar {
  display: block;
  margin-right: 16px;
}

.title-wrapper {
  display: flex;
  align-items: center;
}

.title {
  composes: title-1 from global;
  margin: 0 16px 8px 0;
}

.tag-line {
  composes: body-2 from global;
  max-width: 600px;
  margin-bottom: 24px;
  line-height: 24px;
  color: var(--content-secondary);
}
