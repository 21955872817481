.asset img {
  width: 265px;
  height: 211px; /* Explicit height avoid layout shift */
}

.back-button {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: var(--z-index-mid);
}

.cards-list {
  display: flex;
  gap: 24px;
  align-items: stretch;
  padding: 0;
  list-style: none;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 80px 64px;
  overflow-y: visible;
}

.selectors-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
