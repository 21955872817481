.filters-wrapper {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: start;

  @media only screen and (width < 1175px) {
    &:not(:only-child) {
      max-width: 70%;
    }
  }

  :global(.filter-select__content) {
    z-index: var(--z-index-mid);
  }
}

.filter-wrapper {
  padding: 4px;
}

.toggle-filter-wrapper {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: start;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
}
