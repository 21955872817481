.downloadButton {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.fileIconWrapper {
  height: 48px;
  padding: 16px;
  background: transparent;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.buttonContent {
  display: flex;
  gap: 16px;
  align-items: center;
}

.fileSize {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.description {
  text-align: left;
}
