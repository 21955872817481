.advantages-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.advantages-table tr td {
  padding: 8px 16px;
}

.advantages-table tr:nth-child(odd) {
  background: var(--elevation-low);
}

.advantages-table__key {
  text-align: left;
}

.advantages-table__value {
  color: var(--content-secondary);
  text-align: right;
}

/* stylelint-disable style-lint-plugin-qonto/require-theme-token */
:global(.premium-theme) .advantages-table tr {
  background: none;
  border-top: 1px solid var(--gray600);
}

:global(.premium-theme) .advantages-table tr:last-child {
  border-bottom: 1px solid var(--gray600);
}

:global(.premium-theme) .advantages-table__value {
  color: var(--gray300);
}
