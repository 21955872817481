.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
  background: var(--elevation-high);
  border-top: 1px solid var(--border-tertiary);
  border-radius: 0 0 4px 4px;
}

.footer-item {
  composes: overlay from global;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 8px 16px;
  color: var(--content-primary-a);
}

.footer-item:focus-visible {
  width: calc(100% - 8px);
  padding-right: 12px;
  padding-left: 12px;
  margin: auto;
}

.add-organization {
  composes: body-1 from global;
  color: var(--content-primary-a);
  text-decoration: none;
}
