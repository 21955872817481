.document {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  max-height: 48px;
}

.document-details {
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-details-amount {
  composes: body-2 from global;
  overflow: hidden;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;

  &.negative {
    color: var(--content-error);
  }

  &.positive {
    color: var(--content-success);
  }

  &.rejected {
    color: var(--content-tertiary);
    text-decoration-line: line-through;
  }
}

.document-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: var(--content-primary-a);
  cursor: pointer;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;

  &:hover.active {
    cursor: pointer;
    background-color: var(--background-secondary);
    border-color: var(--overlay-primary-a-color);
  }
}

.document-details-link {
  composes: body-link from global;
  overflow: hidden;
  color: var(--content-primary-a);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.canceled {
  text-decoration-line: line-through;
}

.status-badge {
  margin-left: auto;
  white-space: nowrap;
}
