.row {
  position: relative;
  height: 64px;

  &:hover {
    cursor: pointer;
    background-color: var(--elevation-low);
  }
}

.row-active {
  background-color: var(--background-tertiary);
}

.cell {
  composes: body-2 from global;
  padding: 0 16px;
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);
}

.cell:last-child {
  padding-right: 48px;
}

.cell:first-child {
  padding-left: 48px;
}

.cell-content {
  display: flex;
  align-items: center;
}

.counterparty {
  display: flex;
  align-items: center;
}

.counterparty-details {
  min-width: 160px;
  margin-left: 16px;
}

.creditor-name {
  color: var(--content-primary);
}

.reference {
  color: var(--content-secondary);
}

.amount-details {
  justify-content: end;
}

.ghost {
  color: var(--content-tertiary);
}

.terminated {
  text-decoration: line-through;
}
