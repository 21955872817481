.row {
  height: 64px;
}

.cell {
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);

  &.price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: inherit;
  }
}

.placeholder {
  margin: 0;
}
