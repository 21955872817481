.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.currency {
  position: absolute;
  margin-left: 12px;
  line-height: 22px;
  color: var(--content-tertiary);
  opacity: 1;
  pointer-events: none;
}

.with-value {
  color: var(--content-primary-a);
}

.input-wrapper .input {
  padding: 8px 12px 8px 47px;
  width: 100%;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
