.wrapper {
  display: flex;
  text-transform: uppercase;
  caret-color: transparent;
}

.wrapper [data-placeholder] {
  color: var(--content-tertiary);
}

.wrapper [data-focused] {
  box-shadow: none;
  border-radius: 4px;
  background-color: var(--background-accent-low);
}

.wrapper [data-readonly] {
  color: var(--content-tertiary);
}

.wrapper[data-focus-within] {
  border-color: var(--border-accent-high);
  box-shadow: 0 0 0 4px var(--border-accent-low);
}
