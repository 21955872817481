.row {
  width: 100%;
  height: 64px;

  td {
    vertical-align: middle;
  }
}

.cell-content {
  display: flex;
  gap: 16px;
  align-items: center;
}

.amount-placeholder {
  margin-left: auto;
}

.underlined-cell {
  border-bottom: 1px solid var(--border-tertiary);
}
