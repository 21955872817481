.beneficiary-information {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.beneficiary-source {
  display: flex;
  align-items: center;
  min-height: 24px;
}

.beneficiary-currency {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 16px;
  padding: 4px;
  text-transform: uppercase;
  border: 1px solid var(--border-secondary);
  border-radius: 2px;
}

.separator {
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: var(--border-tertiary);
  border: 0;
}

.break-word {
  word-break: break-word;
}

.color-secondary {
  color: var(--content-secondary);
}
