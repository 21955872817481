.closeButton {
  z-index: 1;
}

.lottie {
  z-index: 0;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
}

.popupBody {
  margin-top: 0;
}
