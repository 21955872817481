.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.readonly {
  background-color: var(--elevation-low);
}

.disabled {
  cursor: not-allowed;
  background-color: var(--elevation-low);
  opacity: 0.45;
}
