.icon {
  width: 16px;
  height: 16px;

  &.progress {
    color: var(--content-accent);
  }

  &.awaiting,
  &.completed {
    color: var(--content-secondary);
  }

  &.paid {
    color: var(--content-success);
  }
}
