.container {
  all: unset;
  width: 100%;
}

.inputs {
  display: flex;
  gap: 16px;
}

.datePicker {
  flex: 1;
}

.variance {
  flex: 1;
}
