.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
}

.declined,
.reversed {
  color: var(--content-tertiary);
  text-decoration: line-through;
}

.local-amount {
  composes: body-2 from global;
  color: var(--content-secondary);
}
