.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  color: var(--content-primary-a);
  word-break: break-word;
}

.description {
  display: flex;
  align-items: center;
}

.date {
  justify-self: end;
}

.icon {
  color: var(--content-secondary);
}
