.connect-app-sidebar {
  flex-shrink: 0;
  width: 240px;
}

.sidebar-item {
  composes: mb-24 from global;
  display: flex;
  flex-direction: column;
}

.sidebar-title {
  composes: mb-4 body-1 from global;
}

.sidebar-subtitle {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.sidebar-category {
  composes: tag from global;
  margin: 0 4px 10px 0;
}

.sidebar-link {
  display: block;
  width: fit-content;
  word-break: break-all;
}

.external-link-icon {
  margin: 0 0 -1px 5px;
  color: var(--content-accent);
}
