.success {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 48px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 508px;
  text-align: center;
}

.icon {
  width: 200px;
}

.instructions {
  color: var(--content-secondary);
}

.send-address {
  min-width: 508px;
  padding: 16px 24px;
  font-style: normal;
  color: var(--content-primary-a);
  background-color: var(--background-secondary);
  border-radius: 6px;
}
