.vat-amount-field {
  input {
    padding-left: 32px !important;
  }
}

.vat-rate-label {
  margin-bottom: 6px;
}

.error {
  margin-top: 6px;
  color: var(--content-error);
  font-size: var(--caption-font-size);
}
