.wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  width: 64px;
  height: 64px;
  transform: translate(-50%, -50%);

  svg {
    right: -2px;
    bottom: -2px;
    width: 24px;
    height: 24px;
  }
}
