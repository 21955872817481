.wrapper {
  position: relative;
  display: flex;
  gap: 8px;
  min-height: 40px;
}

.wrapperWrap {
  flex-wrap: wrap;
}

.content {
  display: none;
}

.contentVisible {
  display: block;
  flex: auto;
}

.contentSelecting {
  display: none;
  width: 0;
  padding-left: 0;
  opacity: 0;
}
