.container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.avatar {
  flex: 1;
  width: 100%;
  aspect-ratio: 1 / 1;
  margin: -1px; /* to compensate for the border */
  object-fit: cover;
  background: var(--background-secondary);
  border: 1px solid var(--border-tertiary); /* Outlines with radius are not supported in older versions of Safari */
  border-radius: 50%;
}

.letter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  border-radius: 50%;
}
