.email-preview-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .qonto-flower {
    img {
      width: 32px;
      height: 32px;
    }
  }

  .footer-text {
    color: var(--content-secondary);
    text-align: center;

    a {
      color: inherit;
    }
  }
}
