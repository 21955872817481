.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  padding: 8px 24px;
}

.link {
  cursor: pointer;
}
