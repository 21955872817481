.icon-container {
  line-height: 0;
}

.no-attachment-icon {
  color: var(--content-tertiary);
}

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.preview-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 504px;
  text-align: center;
}
