.wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.not-interested {
  margin-left: auto;
}
