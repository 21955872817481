.categoriesTable {
  width: 100%;
  color: var(--content-primary-a);
  table-layout: fixed;
  border-spacing: 0;
  border: 1px solid var(--border-tertiary);
  border-top: 0;
  border-radius: 4px;

  col {
    width: 100%;
    min-width: 100px;
  }

  col:first-of-type {
    width: 264px;
  }

  thead th:first-of-type,
  tbody th {
    position: relative;
  }

  th {
    padding: 12px;

    &:has(.balance-button) {
      padding: 0;
    }
  }

  td,
  th {
    position: relative;
    height: 56px;

    &.hovered {
      background-color: var(--background-secondary);
    }

    &.current {
      background-color: var(--background-tertiary);
    }
  }

  tbody :is(td, th) {
    border-top: 1px solid var(--border-tertiary);

    .animated {
      background-color: var(--elevation-default);
    }
  }

  .animated {
    background-color: var(--elevation-default);
  }

  thead th:not(:first-child):hover {
    cursor: pointer;
    background-color: var(--state-primary-a-hover);
  }

  tr:has(td:hover) td,
  tr:has(td:hover) th {
    background-color: var(--background-secondary);
  }
}

.colHeader {
  composes: caption-bold from global;
  background-color: var(--elevation-low);
  border-top: 1px solid var(--border-tertiary);
}

.colHeader:first-child {
  border-right: 1px solid var(--border-tertiary);
  border-radius: 4px 0 0 0;
}

.colHeader:first-child button {
  display: flex;
  gap: 8px;
  align-content: center;
}

.colHeader:last-child {
  border-radius: 0 4px 0 0;
}

.colHeader.tableCollapsed:first-child {
  border-radius: 4px 0 0 4px;
}

.colHeader.tableCollapsed:last-child {
  border-radius: 0 4px 4px 0;
}

.colHeaderWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

.colHeaderActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.colHeaderActionsLabel {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.categoriesActionButton,
.categoriesActionButtonHidden {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  border-radius: 4px;
}

.categoriesActionButton:hover {
  background-color: var(--state-primary-a-hover);
}

.categoriesActionButton:active,
.categoriesActionButton:focus {
  background-color: var(--state-primary-a-pressed);
}

.categoryCell {
  composes: caption from global;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  cursor: default;
}

.categoryLabel {
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.iconBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
}

.canExpand {
  cursor: pointer;
}

.rowHeader {
  border-right: 1px solid var(--border-tertiary);
}

.rowCell,
.rowHeader {
  /* The following is needed in order to show the "border animated glow effect", without the row content glowing */
  z-index: var(--z-index-default) !important;
}

tr:last-child .rowHeader {
  border-radius: 0 0 0 4px;
}

.rowCell:focus,
.rowCell:focus-visible {
  background-color: var(--background-secondary);
  outline: none;
  box-shadow: none;
}

.rowCell:not(:first-child):hover,
.rowHeader:not(:first-child):hover {
  cursor: pointer;
  background-color: var(--background-secondary);
}

tr:last-child .rowCell:last-child {
  border-radius: 0 0 4px 0;
}

.colorDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.balance-button {
  z-index: var(--z-index-mid);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  transition:
    opacity 0.3s,
    transform 0.3s;

  &:focus-visible,
  &:focus {
    background-color: var(--state-primary-a-hover);
    outline: none;
    box-shadow: none;
  }
}

.balance-button-selected {
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px var(--border-accent-high) !important;
}

.forecastSeparator {
  margin: 0 8px;
}

.forecastContainer {
  composes: caption from global;
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: 100px;
  padding-right: 1px;
  overflow: hidden;
  font-style: italic;
  color: var(--content-secondary);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  .forecastAmount {
    padding-right: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .forecastAmountEditable:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
  }

  .forecastAmountEditable.forecastAmountDisabled:hover {
    text-decoration: initial;
    cursor: not-allowed;
  }
}

.balanceInput {
  width: 100%;
  font-size: var(--caption-font-size);
  font-style: italic;
  font-weight: var(--caption-font-weight);
  color: var(--content-secondary);
  text-align: center;
  background: initial;
  border: none;
}

.balanceInput:focus-visible,
.balanceInput:focus {
  box-shadow: none;
}

.balanceButtonContentWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.balanceButtonContent {
  display: flex;
  gap: 2px;
  justify-content: center;
  max-width: 100%;
  height: 32px;
  overflow: auto;
}

.withSeparator {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 0;
  align-items: center;
  padding: 0 12px;

  .amountContainer {
    text-align: right;
  }
}

.dualContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  align-items: center;
  padding: 0 12px;

  .amountContainer {
    padding-top: 2px;
    text-align: right;
  }
}

.projectedCellContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}

.balanceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.hidden {
  pointer-events: none;
  opacity: 0;
  transform: translateX(-10px);
}

.amountContainer {
  display: inline-block;
  overflow: hidden;
  font-weight: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.fadeOut {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transform: translateX(-5px);
}

.forecastAmountFlash {
  animation: flash-blue 3s ease-in-out;
}

@keyframes flash-blue {
  0% {
    color: var(--content-accent);
  }

  100% {
    color: inherit;
  }
}

.balance-button.isEditing {
  background: var(--state-primary-a-hover);
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px var(--border-accent-high);
}

.flash-forecast {
  position: absolute;
  right: 10px;
  z-index: var(--z-index-high);
  width: 28px;
  height: 28px;
  aspect-ratio: 1;
  padding-top: 4px;
  visibility: hidden;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;
}

.inline-icon {
  position: absolute;
  right: 10px;
  z-index: var(--z-index-high);
  display: none;
  width: 28px;
  height: 28px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;
}

.inline-icon > div {
  display: block;
  width: 28px;
  height: 28px;
  padding-top: 4px;
  content: '';
  background-color: var(--elevation-high);
  border-radius: 6px;

  &:hover {
    background-color: var(--state-primary-a-hover);
  }
}

td:hover {
  .inline-icon {
    display: block;
  }

  .flash-forecast {
    visibility: visible;
  }
}

.balance-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.current-month-tooltip-container {
  composes: body-2 from global;
  display: flex;
  flex-direction: column;
  width: 172px;
}

.current-month-tooltip {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.disabled-cell {
  cursor: not-allowed;
}
