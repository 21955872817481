.table-cell {
  border-bottom: 1px solid var(--border-tertiary);
}

.table-cell:focus-visible {
  box-shadow: 0 0 0 4px inset var(--border-accent-low);
}

.table-cell:hover {
  border-bottom: 1px solid var(--border-secondary);
}

.table-cell:has([data-is-dragging]) {
  position: unset !important;
}

.table-cell-content {
  display: flex;
  align-items: center;
}

.fixed-column {
  display: flex;
  gap: 16px;
}
