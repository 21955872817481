.dropdown {
  width: 100%;
  max-height: fit-content;

  :global(.ember-basic-dropdown-content) {
    position: relative;
    z-index: 0;
    max-height: none;
  }
}

.trigger {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;

  &:hover::after {
    border-radius: 7px;
  }

  &.open {
    border-radius: 8px 8px 0 0;
  }

  &.open:hover::after {
    border-radius: 7px 7px 0 0;
  }
}

.timeline {
  padding: 16px;
  margin-top: 0;
  overflow: visible !important;
  background-color: transparent;
  border: 1px solid var(--border-tertiary);
  border-top-width: 0;
  border-radius: 0 0 8px 8px;
  box-shadow: none;
}
