.checks {
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary {
  padding: 48px 0;
  color: var(--content-primary-a);
}

.title {
  text-align: left;
}

.back-btn {
  display: inline-block;
  color: var(--content-primary-a);
  text-decoration: none;
}

.container {
  max-width: 504px;
}

.table-confirmation {
  width: 100%;
  border-spacing: 0;
}

.table-confirmation tr {
  vertical-align: top;
}

.table-confirmation th,
.table-confirmation td {
  padding: 0 0 16px 0;
  color: var(--content-primary-a);
  text-align: right;
}

.table-confirmation th:first-child,
.table-confirmation td:first-child {
  color: var(--content-secondary);
  text-align: left;
}

.confirm-btn {
  margin-top: 32px;
  margin-right: 16px;
}
