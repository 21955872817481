.container {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  touch-action: none;
  background-color: var(--elevation-high);
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: var(--elevation-low);
  }
}

.container.selected {
  background-color: var(--background-tertiary);
}

.container.expanded {
  border-radius: 4px 4px 0 0;
}

.container.no-hover {
  &:hover {
    cursor: default;
    background-color: transparent;
  }

  .category-details * {
    cursor: default;
  }
}

.subcategory {
  cursor: default;
  border-top: 1px solid var(--border-tertiary);
  border-radius: 0;

  &:hover {
    cursor: default;
  }

  .category-details * {
    cursor: default;
  }
}

.subcategory:not(:has(~ .subcategory)) {
  border-radius: 0 0 4px 4px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
}

.toggle-accordion {
  flex-grow: 1;
  justify-content: flex-start;
  width: auto;
  height: 100%;

  &:hover::after {
    --overlay-opacity: 0;
  }
}

.drag-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: grab;

  &:hover {
    background-color: var(--state-primary-a-pressed);
    border-radius: 4px;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &[aria-pressed='true'] {
    cursor: grabbing;
  }
}

.subcategory .drag-handle {
  visibility: hidden;
}

.subcategory:has(.drag-handle[aria-pressed='true']) {
  cursor: grabbing;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-accent-high);
  border-radius: 4px;

  .drag-handle {
    background-color: var(--state-primary-a-pressed);
    border-radius: 4px;
  }
}

.subcategory:hover .drag-handle,
.subcategory:hover ~ .subcategory .drag-handle,
.subcategory:has(~ .subcategory:hover) .drag-handle {
  visibility: visible;
}

.category-details {
  display: flex;
  flex-grow: 1;
  gap: 12px;
  align-items: center;
}

.category-details svg:not(.icon-container svg) {
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token -- required by design */
  color: var(--primary-black);
}

.menu-block {
  display: flex;
  gap: 8px;
  align-items: center;
}

.menu-popover {
  min-width: 208px;
  padding: 8px 0;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.menu-item {
  composes: body-2 from global;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;

  &:hover {
    background-color: var(--background-secondary);
  }
}

.menu-item-content {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  color: var(--content-primary-a);
}

.delete {
  color: var(--content-error);
}

.tooltip {
  display: -webkit-box;
  max-width: 280px;
  margin-right: 81px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.within-limits-tooltip {
  display: -webkit-box;
  max-width: 147px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.btn-disabled {
  appearance: none;
  pointer-events: auto;

  &:hover {
    background-color: var(--bg-color);
    opacity: var(--disabled-opacity);
  }

  &::after {
    background-color: var(--overlay-primary-b-color);
    border-radius: 4px;

    --overlay-primary-a-color: var(--primary-white);
    --overlay-opacity: var(--disabled-opacity);
  }
}
