.toast {
  display: flex;
  width: 320px;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--background-primary);
  color: var(--content-primary-b);
}

.text {
  flex: 1;
}

.icon-container {
  height: 24px;
  display: flex;
  align-items: center;
}

.close {
  margin-left: auto;
  width: 24px;
  height: 24px;
}

.close svg {
  width: 16px;
  height: 16px;
  color: var(--content-primary-b);
}

.error,
.error svg {
  background-color: var(--background-error-high);
  color: var(--content-primary-b-static);
}
