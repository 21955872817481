.popover {
  display: flex;
}

.wrapper {
  display: flex;
  height: 100%;
  max-height: 342px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.placeholder {
  width: 100%;
  height: 40px;
  background-color: var(--background-accent-low);
  transition: all 0.2s ease-in-out;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 8px 16px;
  background-color: var(--elevation-high);
}

.title {
  color: var(--content-secondary);
}

.scrollable {
  flex: 1;
  overflow-y: auto;

  /* remove browser scrollbar */
  -ms-overflow-style: none;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.separator {
  width: 1px;
  background-color: var(--border-tertiary);
}

.drag-overlay {
  background-color: var(--elevation-high);
  box-shadow: var(--shadow-high);
  opacity: 1;
}
