.button {
  width: 24px;
  height: 24px;
}

.uploading {
  color: var(--content-secondary);
}

.file,
.file:hover {
  padding: 0;
  text-decoration-line: underline;
}
