.recommendation-card {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  min-height: 236px;
  padding: 24px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
}

.content {
  margin-right: 16px;
}

.recommendation-body {
  margin-bottom: 28px;
  color: var(--content-secondary);
}

.recommendation-img {
  width: 118px;
  height: auto;
}
