.page-container {
  position: relative;
  display: flex;
  height: calc(100vh - 50px); /* take banner into account to avoid scrollbar */
  min-height: 100%;
}

.wrapper {
  flex-grow: 1;
  min-height: 100%;
  overflow-y: auto;
  background-color: var(--elevation-default);
}

.side-panel-wrapper {
  position: sticky;
  top: 0;
  flex-shrink: 0;
}

/* stylelint-disable css-modules/no-global-scoped-selector */
button:focus-visible,
[role='option']:focus-visible,
[role='dialog']:focus-visible {
  outline: none;
  box-shadow: none;

  &[data-focused] {
    outline: none;
    box-shadow: none;
  }

  &[data-focus-visible] {
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}
