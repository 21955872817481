.content {
  height: 100%;
}

.actions {
  position: sticky;
  bottom: 0;
  display: flex;
  width: 100%;
}

.toolbar-row {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.search-input {
  width: 100%;
}
