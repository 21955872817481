.check {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  margin-top: 6px;
  margin-right: 12px;
}

.feature {
  composes: body-2 from global;
  display: flex;
  margin-bottom: 12px;
  list-style-type: none;
}

.feature-title {
  flex: 1;
}
