.button {
  border-bottom: none;
}

.button-children {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.button[aria-expanded='true'] {
  border-radius: 4px;
  box-shadow: 0 0 0 4px var(--border-accent-low);
}

.popover {
  background-color: var(--elevation-high);
  box-shadow: var(--shadow-high);
  width: 126px;
}

.list {
  max-height: 180px;
  overflow-y: auto;
}

.item {
  padding: 8px 16px;
  color: var(--content-primary-a);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.item[data-hovered='true'] {
  box-shadow: none;
}
