.promotional-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  width: 100%;
  padding: 16px;
  color: var(--content-primary-a);
  text-align: left;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
  transition: box-shadow 0.2s ease-in-out;
}

.promotional-card-interactive {
  cursor: pointer;
}

.promotional-card-interactive:hover {
  box-shadow: var(--shadow-high);
}

.promotional-card-interactive:focus-visible {
  box-shadow: 0 0 0 5px var(--border-accent-low);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cta-link {
  padding: 0;
  line-height: 23px;
  cursor: pointer;
  border-bottom: 1px solid var(--content-primary-a);
}

.close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}

.close-btn-spacer {
  width: 32px;
  height: 32px;
}
