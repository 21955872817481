.container {
  min-width: 100vw;
  min-height: 100vh;
}

.illustration {
  width: 360px;
  height: 100%;
}

.qr-container {
  display: flex;
  gap: 16px;
  max-width: 346px;

  .qr {
    width: 80px;
    height: 80px;
  }
}

.subtitle {
  color: var(--content-secondary);
}
