.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 24px 0 0;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--elevation-default);
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--border-tertiary);

  &.empty {
    width: 48px;
    min-width: 48px;
    padding: 0;
  }
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);

  &.active {
    color: var(--content-primary-a);
  }
}

.header-type {
  width: calc((214 + 130) / 1052 * 100%);
}

.header-reason {
  width: calc((107 + 113) / 1052 * 100%);
}

.header-status {
  width: calc((100 + 113) / 1052 * 100%);
}

.header-amount {
  padding: 0;
  text-align: right;
}

/* sidebar open view */
.row-sidebar .header-cell {
  padding: 0 10px 0 0;

  &.empty {
    padding: 0;
  }
}

.row-sidebar .header-amount {
  padding: 0;
}

/* animation */
.hidden {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.animated {
  .animated-cell {
    animation: fadein-item 1.3s ease;
  }
}

@keyframes fadein-item {
  0% {
    opacity: 0;
  }

  1% {
    display: none;
  }

  68% {
    display: table-cell;
  }

  69% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
