.wrapper {
  display: flex;
  background-color: var(--elevation-low);
  max-width: 468px;
  min-height: 80px;
  border-radius: 8px;
  gap: 16px;
  padding: 24px;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.product {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  color: var(--content-primary-a);
}

.disabled {
  color: var(--content-tertiary) !important;
}

.checkbox-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  composes: body-1 from global;
  font-size: 16px;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.chevron-icon {
  flex-shrink: 0;
  transition: all 0.2s;
}

.chevron-icon-open {
  transform: rotate(180deg);
}

.chevron-button-disabled {
  &::after {
    background: transparent !important;
  }
}
