.tableCell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.skeletonContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
}
