.header {
  width: 100%;
  padding: 0;
}

.result {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--border-tertiary);
}

.result-value {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.result-value-key {
  display: flex;
  align-items: center;
}

.result-value-icon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.result-value-icon.green {
  color: var(--content-success);
}

.result-value-icon.red {
  color: var(--content-error);
}

.result-value-description {
  color: var(--content-secondary);
}

.result-title {
  margin-right: auto;
  color: var(--content-secondary);
}

.chevron {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  margin-left: 8px;
  color: var(--content-secondary);
  transition: all 250ms ease-out;
}

.chevron svg {
  width: 100%;
  height: 100%;
}

.chevron-rotated {
  transform: rotateZ(180deg);
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-title {
  color: var(--content-secondary);
}

.detail-value {
  margin: 0;
  text-align: right;
}

.tooltip-wrapper {
  display: flex;
  align-items: center;
}

.tooltip-icon {
  display: inline-flex;
  margin-left: 8px;
}

.disclaimer {
  margin-top: 16px;
}

.details {
  margin-bottom: 16px;
}

.detail-mb-16 {
  margin-bottom: 16px;
}

.detail-mb-8 {
  margin-bottom: 8px;
}
