.section {
  padding: 8px 0;
}

.cardInfoSection {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 8px 16px;
}

.cardIcon {
  width: 32px;
  padding: 6px 0;
}

.cardInfo .title {
  display: flex;
  flex-direction: column;
}

.cardInfo .subtitle {
  display: flex;
  gap: 4px;
  color: var(--content-secondary);
  white-space: nowrap;
}

.cardInfo .subtitle .nickname {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
