.animation {
  width: 200px;
  height: 200px;
  margin-bottom: 32px;
}

.subtitle {
  text-align: center;
}

.title {
  margin-bottom: 8px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-child {
    padding: 0;
  }

  h3 {
    font-size: var(--title-3-font-size);
    font-weight: var(--title-3-font-weight);
    line-height: var(--title-3-line-height);
  }

  p {
    text-align: center;
  }
}
