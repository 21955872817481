.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

.date {
  composes: caption from global;
  color: var(--content-secondary);
}

.title {
  composes: body-2 from global;
  flex: 1;
  margin-bottom: 4px;
}

.expired {
  text-decoration: line-through;
}

.amount {
  composes: body-2 from global;
  color: var(--content-secondary);
}
