.previous-requests {
  composes: body-link from global;
  position: absolute;
  top: 16px;
  right: 0;
  margin: 0 48px 0 auto;
  color: var(--content-primary-a);

  &:hover {
    text-decoration: underline;
  }
}

.tabs-container {
  position: relative;
}
