.container {
  position: relative;
  display: inline-flex;
}

.buttonGroup {
  display: inline-flex;
  width: 100%;
}

.mainButton {
  background-color: var(--background-primary);
  min-width: 0;

  &:focus {
    z-index: 2;
  }

  &.mainButtonBorders {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;

    &:active::after,
    &:hover::after,
    &[disabled]::after,
    &[aria-disabled='true']::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.buttonContent {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.triggerButton {
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid var(--border-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: var(--primary-content-b);
  }
  background-color: var(--background-primary);

  &:active::after,
  &:hover::after,
  &[disabled]::after,
  &[aria-disabled='true']::after {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus {
    z-index: 2;
  }
}

.secondary {
  .mainButton {
    color: var(--content-primary-a);
    background-color: var(--background-elevation-default);

    &.mainButtonBorders {
      border: 1px solid var(--border-primary);
      border-right: 0;
    }
    &:hover {
      background: var(--state-primary-a-hover);
    }
  }
  .triggerButton {
    border: 1px solid var(--border-primary);
    background-color: var(--background-elevation-default);
    color: var(--content-primary-a);
    &:hover {
      background: var(--state-primary-a-hover);
    }
  }
}

.spinner {
  margin-left: 8px;
}

.chevron {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  color: var(--primary-content-b);
}

.popover {
  min-width: 208px;
  max-width: 360px;
}

.menu {
  color: var(--primary-content-a);
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.menuItem {
  align-items: center;
  background: transparent;
  border: none;
  box-shadow: none;
  color: var(--content-primary-a);
  cursor: pointer;
  height: 40px;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background-color: var(--overlay-primary-a-color);
    opacity: var(--overlay-opacity);
  }

  &:hover::after,
  &:focus::after {
    --overlay-opacity: var(--hover-opacity);
  }

  &[disabled],
  &[aria-disabled='true'] {
    cursor: not-allowed;

    &::after {
      --overlay-primary-a-color: var(--primary-white);
      --overlay-opacity: var(--disabled-opacity);

      cursor: not-allowed;
    }
  }
}
