.icon {
  width: 12px;
  color: var(--content-secondary);
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
