.container,
.card-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.asides {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.asides > div {
  flex: 1;
  min-width: 200px;
}

.footnote {
  display: flex;
  align-items: center;
  align-self: end;
  gap: 8px;
  color: var(--content-secondary);
}
