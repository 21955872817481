.list {
  padding-left: 0;
  list-style: none;

  li {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .list-item-text {
    margin-left: 12px;
  }

  .bullet-point {
    margin-top: 4px;
    overflow: visible;
  }
}

.list-container {
  padding: 24px;
  background: var(--background-tertiary);
  border-radius: 8px;
}
