.small {
  @media only screen and (width > 374px) {
    display: none !important;
  }
}

.large {
  @media only screen and (width <= 374px) {
    display: none !important;
  }
}
