.wrapper {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 24px;
  align-items: center;
  padding: 16px 0 16px 16px;
  text-align: left;
}

.name {
  display: flex;
  align-items: center;
}

.account {
  overflow-wrap: anywhere;
}

.payment-type {
  color: var(--content-tertiary);
}

.account-number {
  color: var(--content-secondary);
}

.destination {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 12px;
  align-items: center;
}

.currency-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
}

.currency {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 16px;
  font-size: var(--caption-font-size);
  font-weight: var(--caption-font-weight);
  line-height: var(--caption-line-height);
  color: var(--content-secondary);
  text-transform: uppercase;
  border: 1px solid var(--border-secondary);
  border-radius: 2px;
}

.ellipsis {
  position: relative;
}

.ellipsis::before {
  visibility: hidden;
  content: '&nbsp;';
}

.ellipsis > span {
  position: absolute;
  right: 0;
  left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
