.cellWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header {
  composes: caption from global;
  align-self: flex-start;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.projectedCellContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  align-items: center;
  padding: 0 12px;
}

.projectedAmount {
  composes: caption from global;
  padding: 0 6px;
}
