.dataflow-icon {
  width: 16px;
  height: 16px;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.qualities {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  color: var(--content-secondary);
}
