.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
  gap: 4px;
}

.input-container > .input-value {
  flex: 1 0;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &[data-placeholder="true"] {
    color: var(--content-secondary);
  }
}

.popover {
  min-width: 208px;
  max-width: 360px;
  color: var(--primary-content-a);
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
  width: var(--trigger-width);
  overflow: auto;
}

.option {
  align-items: center;
  background: transparent;
  border: none;
  box-shadow: none;
  color: var(--content-primary-a);
  cursor: pointer;
  height: 40px;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background-color: var(--overlay-primary-a-color);
    opacity: var(--overlay-opacity);
  }

  &[aria-selected="true"] {
    background-color: var(--state-selected);
  }

  &:hover::after,
  &:focus::after {
    --overlay-opacity: var(--hover-opacity);
  }

  &[disabled],
  &[aria-disabled='true'] {
    cursor: not-allowed;

    &::after {
      --overlay-primary-a-color: var(--primary-white);
      --overlay-opacity: var(--disabled-opacity);

      cursor: not-allowed;
    }
  }
}
