.transaction-history-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 48px;
  text-align: unset;
}

.transaction-history-row-details {
  flex: 1;
  width: 75%;
}

.transaction-history-row-details-amount {
  composes: body-link from global;
  padding: unset;
  overflow: hidden;
  color: var(--content-primary-a);
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
}

.transaction-history-row-details-description {
  composes: body-2 from global;
  overflow: hidden;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  composes: mr-16 from global;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}
