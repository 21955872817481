.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);

  &:hover {
    cursor: pointer;
    box-shadow: var(--shadow-high);
  }

  button {
    appearance: none;
  }
}

.select-button {
  width: 100%;
  height: 100%;
  padding: 16px;
  margin: 0;
  text-align: left;
}

.edit-button {
  width: 32px;
  height: 32px;
  margin: 16px;

  svg {
    color: var(--content-secondary);
  }
}
