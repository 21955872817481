.confirmLayout {
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: fit-content;
  margin: 80px 122px 48px 122px;

  @media (width <= 900px) {
    margin: 80px auto 48px auto;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 1124px;

    @media (width <= 900px) {
      max-width: 504px;
    }
  }
}

.content {
  display: flex;
  gap: 24px;
  width: 100%;

  @media (width <= 900px) {
    flex-direction: column;
  }
}

.content > *:first-child {
  flex: 6;
}

.content > *:last-child {
  flex: 4;
}

@media (width <= 900px) {
  .content > * {
    flex: 1;
  }
}
