.badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  cursor: default;
  user-select: none;
  border-radius: 50px;
}

.dark {
  color: var(--content-primary-b-static);
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  background: linear-gradient(
    125.43deg,
    var(--gray600) 4.38%,
    var(--gray500) 46.86%,
    var(--gray600) 89.11%
  );
}

.light {
  color: var(--content-primary-a-static);
  /* stylelint-disable-next-line style-lint-plugin-qonto/require-theme-token */
  background: linear-gradient(
    125.43deg,
    var(--gray100) 4.38%,
    var(--gray400) 46.86%,
    var(--gray100) 89.11%
  );
}
