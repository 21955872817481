.contentSpinner {
  position: absolute;
  z-index: var(--z-index-high);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: var(--elevation-low);
}

.bankAccountOption {
  display: flex;
  gap: 8px;
  align-items: center;
}

.bankAccountAvatar {
  width: 16px;
  height: 16px;
}

.paymentMethods {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.lastItemDisclaimer {
  margin-top: 8px;
}
