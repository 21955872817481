.budget-details-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 894px;
  margin: auto;
}

.illustration {
  width: 200px;
  height: 200px;
}
