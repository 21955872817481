.popover-section {
  padding: 0 16px;
  background: var(--elevation-high);
  border-radius: 4px;

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: none;
  }
}
