.illustrationWrap {
  width: 425px;
  height: 118px;
  margin: 0 auto 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faqLink {
  a {
    color: var(--content-accent);
  }
}
