.error-state {
  display: flex;
  margin-top: 8px;
}

.error-icon {
  margin-top: 4px;
  margin-right: 12px;

  svg {
    width: 12px;
    fill: var(--content-error);
  }
}

.error-description {
  composes: body-1 from global;
  color: var(--content-secondary);
}
