.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  display: grid;
  grid-template-columns: 430px auto;
  gap: 258px;
  align-items: center;
  width: 888px;
}

.empty-state {
  width: 200px;
  height: 200px;
}

.subtitle {
  width: 369px;
}

svg.button-icon {
  color: var(--content-primary-b);
}
