.qonto-intelligence-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.popover-wrapper {
  position: relative;
  width: 320px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}
