.envelope {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 85px;
}

.payment-page {
  width: 598px;
  padding: 52px 64px;
  margin-bottom: 32px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  flex-direction: column;
}

.logo {
  width: 80px;
  height: 80px;
  margin-left: 24px;
}

.detail {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 4px;
}

.detail dd {
  margin-left: 3px;
  color: var(--content-primary-a);
}

.dummy-field {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.input {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 8px 12px;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
}

.input--select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input--readonly {
  background-color: var(--elevation-low);
}

.placeholder {
  color: var(--content-tertiary);
}

.columns {
  display: flex;
  justify-content: space-between;
}

.columns > div {
  flex: 1;
}

.mandate {
  width: 592px;
  padding: 0 47px;
}

.mandate-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-chevron {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 16px;
}

.mandate-details {
  display: flex;
  flex-direction: row;
}

.checkbox {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-top: 4px;
  margin-right: 8px;
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
}

.secure-note {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--content-secondary);
}

.icon-lock {
  width: 12px;
  height: 12px;
}

.cta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  color: var(--content-primary-b);
  background-color: var(--background-primary);
  border-radius: 4px;
}
