.declined {
  color: var(--content-tertiary);
}

.button {
  width: 28px;
  height: 28px;
}

.title {
  composes: body-1 from global;
}

.subtitle button {
  cursor: default;
}

.in-dispute-status {
  composes: caption from global;
  color: var(--content-secondary);
}
