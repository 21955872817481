.container {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 228px; /* copied from Figma, fixed on purpose (side-scroll) */
  height: 152px;
  padding: 16px;
  color: var(--content-primary-a);
  cursor: pointer;
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;

  &.empty {
    padding: 0;
  }

  &:hover {
    box-shadow: var(--shadow-high);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  text-align: center;
}

.empty .wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container .name {
  height: 48px;
}

.color-secondary {
  color: var(--content-secondary);
}

.scroll {
  display: flex;
  gap: 8px; /* Space between cards */
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  overflow-x: auto;
  overflow-y: hidden;
}

.illustration {
  width: 40px;
}
