/* ff styles: */
.details.details--ff {
  display: flex;
  flex-direction: column;
}

.details--ff .headerContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 32px;
  margin-bottom: 40px;
}

.details--ff .icon {
  flex-shrink: 0;
  margin-right: 16px;
}

.details--ff .lineContainer {
  display: flex;
  align-items: center;
  height: 24px;
  margin-bottom: 46px;
}

.details--ff .infoLine + .infoLine {
  margin-top: 12px;
}
