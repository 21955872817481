.icon {
  padding-top: 4px;
}

.svg {
  width: 16px;
  height: 16px;
}

.current-icon {
  composes: icon;
  color: var(--content-accent);
}

.step-icon {
  composes: icon;
  color: var(--content-secondary);
}

.mileage-icon {
  composes: icon;
  color: var(--dataviz-outflows);
}

.success-icon {
  composes: icon;
  color: var(--content-success);
}

.reject-icon {
  composes: icon;
  color: var(--content-error);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
}

.line {
  position: relative;
  width: 1px;
  height: 100%;
}

.dashed-line {
  composes: line;
  overflow: hidden;
}

.dashed-line::before {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  content: '';
  background-image:
    linear-gradient(to bottom, var(--content-secondary) 2px, transparent 2px),
    linear-gradient(to bottom, var(--content-secondary) 3px, transparent 3px);
  background-repeat: no-repeat, repeat-y;
  background-position:
    0 0,
    0 5.5px;
  background-size:
    1px 5px,
    1px 6.5px;
}

.solid-line {
  composes: line;
}

.solid-line::before {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  content: '';
  background-color: var(--content-secondary);
}
