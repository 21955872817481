.selector-group {
  gap: 16px;
}

.selector {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}

.selector:hover {
  box-shadow: var(--shadow-high);
}

.selector:focus-visible {
  box-shadow: 0 0 0 4px var(--border-accent-low);
}

.selector-content {
  display: flex;
  align-items: center;
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.reason-error {
  margin-top: 16px;
}
