.mt-16 {
  margin-top: 16px;
}

.title {
  margin-top: 32px;
  margin-bottom: 16px;
}

.edit-organization-form {
  max-width: 504px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.beside-block {
  display: flex;
}

.beside-block div:not(.birthdate-field div) {
  flex-grow: 1;
}

.beside-block > div:first-of-type {
  margin-right: 24px;
}

.beside-block > div {
  width: 50%;
}

.info-circle {
  width: 12px;
  height: 12px;
}

.info-circle.hollow {
  margin-left: 14px;
}

.info-circle.filled {
  flex-shrink: 0;
  margin-right: 16px;
}

.radio-option {
  display: flex;
  gap: 8px;
}

.radio-option:hover {
  cursor: pointer;

  label {
    cursor: pointer;
  }

  .radio-button::before {
    background-color: var(--state-primary-a-hover);
  }
}

.cancel-button {
  margin-right: 16px;
}

.error-message {
  color: var(--content-error);
}

.role-title {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tooltip {
  display: flex;

  svg {
    width: 12px;
    height: 12px;
  }
}
