.theme-selector {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.container-auto {
  width: 219px;
}

.fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

.fieldset[disabled] .automatic {
  cursor: not-allowed;
  opacity: 0.45;
}

.fieldset[disabled] .automatic input {
  cursor: not-allowed;
}

.fieldset[disabled] .automatic:hover {
  box-shadow: none;
}

.warning {
  margin-top: 16px;
}
