.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 504px;
  height: 100%;
  text-align: center;
}

.illustration {
  composes: mb-32 from global;
  width: 120px;
  height: 120px;
}
