.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-container {
  display: flex;
  align-items: center;
  position: relative;
}

.input-container > input {
  flex: 1 0;
}

button.icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 12px;
  color: var(--content-primary-a);
  padding: 0;
  outline: none;
  background: transparent;
  border: none;
}
