.email-container {
  width: 600px;
  padding: 52px 64px;
  margin: 0 auto 48px auto;
  overflow-wrap: anywhere;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;

  img {
    width: 80px;
    height: auto;
  }

  .email-body {
    margin-bottom: 40px;
    color: var(--content-secondary);
  }

  .invoice-info {
    color: var(--content-primary-a);
  }

  .email-subject {
    min-height: 32px;
  }

  .reference-disclaimer {
    margin-top: 24px;

    span {
      color: var(--content-primary-a);
    }
  }
}

.fake-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  line-height: 24px;
  color: var(--content-primary-b);
  background-color: var(--background-primary);
  border-radius: 4px;
}

.einvoice-body {
  color: var(--content-secondary);
}

.disabled-promo-card {
  cursor: default !important;
}
