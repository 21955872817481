.list-item .image {
  width: 12px;
}

.list-item.included {
  .image {
    mask: url('../../assets/icons/checkmark.svg') no-repeat center;
    background: var(--content-primary-a);
  }
}

.list-item.excluded {
  text-decoration: line-through;
  color: var(--content-secondary);

  .image {
    mask: url('../../assets/icons/cross.svg') no-repeat center;
    background: var(--content-secondary);
  }
}

.list-item:last-child {
  margin-bottom: 0;
}
