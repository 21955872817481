.row {
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
}

.fnci {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mb-28 {
  margin-bottom: 28px;
}
