.bankAccountTileLoading {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 204px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
  transition: box-shadow 0.25s ease-in-out;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 24px 24px 0;
  margin-bottom: 24px;
}

.headings {
  display: flex;
  flex-grow: 1;
  gap: 16px;
  align-items: center;
  min-width: 0;
  margin-right: 12px;
}

.menu {
  position: absolute;
  top: 24px;
  right: 24px;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 0 24px 16px;
}

.details {
  display: flex;
  justify-content: space-between;
  height: 12px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 52px;
  padding: 12px 24px 16px;
  background-color: var(--elevation-low);
}
