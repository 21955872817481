.categories-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.categories-list-empty,
.categories-list-error {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 88px;

  .lottie-illustration {
    width: 200px;
    height: 200px;
  }

  .list-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
}

.category-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  margin-bottom: 16px;
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.category-item-details,
.category-item-actions {
  display: flex;
  gap: 12px;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.drag-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: progress;
}

.mr-27 {
  margin-right: 27.5px;
}
