.row {
  height: 64px;
}

.icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.cell {
  color: var(--content-primary-a);
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);
}

.cell:first-of-type {
  padding-left: 48px;
}

.cell:last-child {
  padding-right: 48px;
}

.cell-content {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;
  height: 100%;
}

.align-right {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
}
