.header {
  display: flex;
  gap: 12px;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  margin: 0 -16px 16px -16px;
  border-bottom: 1px solid var(--border-tertiary);
}

.icon {
  width: 16px;
  height: 16px;
}
