.transaction {
  composes: body-2 from global;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  color: var(--content-primary-a);
  text-align: left;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}

.transaction:hover {
  box-shadow: var(--shadow-high);
}

.transaction:active {
  border: 4px solid var(--border-accent-low);
  box-shadow: var(--shadow-high);
}

.transaction-name {
  composes: body-1 from global;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transaction-date {
  color: var(--content-secondary);
}

.transaction-method {
  color: var(--content-secondary);
  text-transform: capitalize;
}

.credit-transaction {
  color: var(--content-success);
}
