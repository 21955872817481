.toggle-btn {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 8px 0;
}

.section {
  padding: 8px 0;
}

.header {
  padding: 12px 16px;
}
