.wrapper {
  display: flex;
  align-items: center;
}

.block {
  max-width: 504px;
  padding: 16px;
  border-radius: 4px;
}

.icon {
  display: flex;
  margin: 6px 12px 0 0;
  align-self: flex-start;
}

.icon > svg {
  width: 12px;
  height: 12px;
}

.icon.errorIcon svg path,
.errorIcon svg path {
  fill: var(--content-error);
}

.icon.infoIcon svg path,
.infoIcon svg path {
  fill: var(--content-accent);
}

.icon.warningIcon svg path,
.warningIcon svg path {
  fill: var(--content-warning);
}

.message {
  margin: 0;
  text-align: left;
}

.block .message {
  color: var(--content-primary-a);
}

.block .message a,
.block .message a:hover {
  color: var(--content-primary-a);
  text-decoration: underline;
  cursor: pointer;
}

.wrapper:not(.block) .message {
  color: var(--content-secondary);
}

.wrapper:not(.block) .message a,
.inline .message a:hover {
  color: var(--content-accent);
  text-decoration: underline;
  cursor: pointer;
}

.errorBlock {
  background-color: var(--background-error-low);
  border: 1px solid var(--border-error);
}

.infoBlock {
  background-color: var(--background-accent-low);
  border: 1px solid var(--border-accent-high);
}

.warningBlock {
  background-color: var(--background-warning-low);
  border: 1px solid var(--border-warning);
}
