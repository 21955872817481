.tile {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  background-color: var(--elevation-low);
  border-radius: 4px;
}
