.errorContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  max-width: 550px;
  height: 100%;
  padding: 0 24px;
  text-align: center;
}

.illustration {
  width: 200px;
  height: 200px;
  padding: 25px;
}
