.step-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 504px;
  text-align: center;
}

.success-icon {
  width: 200px;
  height: 200px;
}
