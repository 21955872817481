.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 37px 0 0;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}

.header-cell.empty {
  width: 48px;
  min-width: 48px;
  padding: 0;
}

.header-bulk-checkbox {
  width: 30px;
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
}

.header-receipt {
  padding: 0;
  text-align: center;
}

.header-amount {
  padding: 0;
  text-align: right;
}

.header-content.active {
  color: var(--content-primary-a);
}

.checkbox {
  justify-self: left !important;
}
