.signature {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 80px;
  overflow: hidden;
}

.iframe-container {
  width: 100%;
  height: 100%;
  border: 0;
}
