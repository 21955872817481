.add-rule-button {
  min-height: 56px;
}

.invite-expense-users-disclaimer {
  max-width: 100%;
}

.submit-button {
  margin-top: 32px;
}
