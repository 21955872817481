.fullscreen-base {
  position: relative;
  width: 100vw;
  min-height: 100vh;
}

.close-wrapper,
.close-wrapper--base {
  top: 0;
  right: 0;
  z-index: 1;
  grid-row: 1;
  grid-column: 1 / -1;
  width: 100%;
  text-align: right;
  pointer-events: none;
}

.close-wrapper--base {
  height: 0;
}

.close-button {
  position: sticky;
  top: 16px;
  right: 16px;
  pointer-events: auto;
}

.close-button--base {
  z-index: 2;
  margin: 16px 16px 0 0;
  pointer-events: auto;
}
