.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: center;
  justify-content: center;
  width: 504px;
  height: 100%;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: unset;
}

.details-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.details-row-text {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin: unset;
}

.account-balance {
  color: var(--content-secondary);
}

.previous-interest {
  color: var(--content-secondary);
  text-decoration: line-through;
}

.separator {
  width: 100%;
  height: 1px;
  margin: 4px 0 3px;
  background-color: var(--border-tertiary);
  border: 0;
}

.button {
  margin-top: 32px;
}

.disclaimer {
  width: 100%;
  margin-top: 16px;
}
