.timeline-item {
  margin: var(--item-margin) 0;
  position: relative;
  display: grid;
  grid-template-columns: var(--media-size) 1fr;
  gap: 12px;
  align-items: start;
  justify-items: start;

  &::before {
    position: absolute;
    top: calc(var(--media-size) + var(--media-line-gap) + var(--media-padding));
    bottom: 0;
    left: calc(var(--media-size) / 2 - 0.5px);
    display: block;
    content: '';
  }

  &.hide-line {
    &::before {
      display: none;
    }

    &:last-child > .item-content {
      padding-bottom: 0;
    }
  }
}

.variant-dashed {
  &::before {
    border-left: 1px dashed var(--content-secondary);
  }
}

.variant-solid {
  &::before {
    border-left: 1px solid var(--content-secondary);
  }
}

.item-title {
  color: var(--content-primary-a);

  &.loading {
    display: flex;
    align-items: center;
    height: 24px;
  }
}

.item-description {
  color: var(--content-secondary);

  &.loading {
    display: flex;
    align-items: center;
    height: 16px;
  }
}

.media-container {
  padding-top: var(--media-padding);
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 12px;
}
