.filters-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: start;

  :global(.filter-select__content) {
    z-index: var(--z-index-mid);
    width: 208px;
  }
}
