.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.toggle {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
}
