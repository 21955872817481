.feature-link {
  composes: body-link from global;
  color: var(--content-primary-a);
}

.metal-design {
  position: relative;
  transition: opacity 0.25s ease-in-out;

  > * {
    z-index: var(--z-index-mid);
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index-default);
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin: -1px;
    content: '';
    background: radial-gradient(
      111.19% 137.36% at 0% 0%,
      #f2f6fb 0%,
      #eef1f6 27.26%,
      #e5e8ed 46.95%,
      #dcdfe3 68.56%,
      #d2d5da 86.19%,
      #cdd0d4 100%
    );
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 var(--shadow-low);
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  &:hover::after {
    opacity: 1;
  }

  /* This prevents the border from being visible at some zoom levels */
  &:hover {
    border: 1px solid transparent;
    transition: border 0.25s ease-in-out;
  }
}

:global(.dark-theme) .metal-design::after {
  background: radial-gradient(
    111.19% 137.36% at 0% 0%,
    #4f5257 0%,
    #4f5257 27.26%,
    #46484d 46.95%,
    #36383b 68.56%,
    #2c2d30 86.19%,
    #2c2d30 100%
  );
}
