.labelContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  composes: body-2 from global;
}

.description {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.radioButton {
  align-items: normal;

  &::before {
    margin: 4px 0;
  }
}

.radioButton:hover {
  .labelContainer::before {
    background-color: var(--state-primary-a-hover);
  }
}
