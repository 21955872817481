.card {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  width: -moz-available;
  padding: 16px;
  margin-bottom: 16px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 5px;
}

.date {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.unselected {
  border-color: var(--border-tertiary);
}

.selected {
  border-color: var(--border-accent-high);
}

.greyedOut {
  color: var(--content-tertiary);
}

.titleRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}

.titleText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.amount {
  white-space: nowrap;
}
