.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 504px;
  color: var(--content-primary-a);
  text-align: left;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  background-color: var(--elevation-low);
  border-bottom: 1px solid var(--border-tertiary);
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 12px 16px;
}
