.sideDrawer {
  --side-drawer-width: 480px;
  --side-drawer-position: -480px;
  --side-drawer-transition-duration: 240ms;

  position: fixed;
  top: 0;
  right: var(--side-drawer-position);
  z-index: var(--z-index-above);
  display: flex;
  flex-direction: column;
  width: var(--side-drawer-width);
  height: 100%;
  background-color: var(--elevation-default);
  box-shadow: var(--shadow-high);
  transition: transform var(--side-drawer-transition-duration) ease-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.sideDrawerVisible {
  transform: translateX(var(--side-drawer-position));
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 12px;
}

.footer {
  margin-top: auto;
}

.overlay {
  position: fixed;
  right: var(--side-drawer-width);
  z-index: var(--z-index-above);
  min-width: 100vw;
  height: 100%;
  visibility: hidden;
  background-color: var(--background-modal-overlay);
  opacity: 0;
  transition:
    opacity var(--side-drawer-transition-duration) ease-out,
    visibility var(--side-drawer-transition-duration) ease-out;

  @media (prefers-reduced-motion) {
    transition: none;
  }
}

.overlayVisible {
  visibility: visible;
  opacity: 1;
}

.scroller {
  padding-top: 4px;
  overflow-y: auto;
}
