.sticky-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;

  button {
    margin: auto;
    margin-bottom: 16px;
  }

  a {
    margin: auto;
  }
}
