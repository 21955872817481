.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 504px;
  max-width: 504px;
  height: 100vh;
  margin: 0 auto;
}

.dueDate {
  color: var(--content-accent);
}
