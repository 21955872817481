.create-form {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 16px 0;
}

.create-form > .comment-text-area {
  height: 40px;
  min-height: 40px;
  max-height: 392px;
  transition: min-height 0.3s ease-in-out;

  &.focused {
    min-height: 104px;
  }
}

.create-button {
  flex-shrink: 0;
  align-self: flex-end;
  color: var(--content-primary-b);
}
