.container {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 8px;
}

.icon {
  width: 12px;
  height: 12px;

  path {
    fill: var(--content-warning);
  }
}

.text {
  color: var(--content-secondary);
}
