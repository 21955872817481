.categories-side-panel {
  flex-shrink: 0;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 16px;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
}

.title {
  composes: body-1 from global;
  flex-grow: 1;
  padding-left: 40px;
  text-align: center;
}

.close-button {
  flex-shrink: 0;
}

.loading-wrapper {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}
