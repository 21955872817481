.variance-amount {
  width: 90px;
  border-radius: 4px 0 0 4px;

  input:not(:focus-visible) {
    border-right-width: 0;
    border-radius: 4px 0 0 4px;
  }
}

.variance-amount-wrapper {
  display: flex;
  gap: 0;
  max-width: 180px;
}
