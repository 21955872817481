.banner {
  background-color: var(--elevation-low);
  color: var(--content-primary-a);
  border-radius: 16px;
  padding: 24px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.bannerMobile {
  padding: 16px 24px;
  flex-direction: column-reverse;
}

.textWrapper {
  max-width: 279px;
}

.bannerMobile .textWrapper {
  max-width: none;
}

.description {
  color: var(--content-secondary);
}

.imageWrapper {
  flex: 0 1 120px;
  text-align: center;
}

.image {
  width: 120px;
  height: 120px;
  align-self: center;
  margin: 0 -16px;
}