.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.content {
  width: 504px;
  padding: 80px 0;
  margin: auto 0;
}

.search input {
  background-color: var(--background-secondary);
}

.placeholder {
  padding: 24px 16px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.step-container .search-empty-state,
.step-container .error-state {
  width: auto;
  height: auto;
  margin-top: 32px;
}

.beneficiaries-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  list-style: none;
}
