.forecast-entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 16px 24px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.forecast-entry-amount-container {
  display: flex;
  flex-direction: column;
}

.forecast-entry-aside-container {
  display: flex;
  align-items: center;
}

.forecast-entry-amount-value {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--content-primary-a);
}

.forecast-entry-amount-source {
  composes: caption from global;
  line-height: 16px;
  color: var(--content-tertiary);
}

.forecast-entry-range {
  composes: body-2 from global;
  line-height: 24px;
  color: var(--content-secondary);
}

.forecast-entry-aside-button {
  composes: body-1 from global;
  color: var(--content-primary-a);
  border: none;
}
