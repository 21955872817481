.success-illustration {
  width: auto;
  height: 200px;
  margin-top: 104px;
  margin-bottom: 32px;
}

.success-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  composes: title-2 from global;
  width: 90%;
  margin-bottom: 8px;
}
