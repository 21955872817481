.negativeMargin {
  margin: 0 -24px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: var(--elevation-default);
  color: var(--content-primary-a);
  padding: 24px;
  border-radius: 8px;
  flex:1;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.ctaBox {
    align-items: center;
    gap: 4px;
    padding-bottom: 16px;
  }

  &.price {
    flex-direction: row;
    align-items: center;
    padding-bottom: 24px;
  }
}

.priceBox {
  padding-top: 24px;
  padding-bottom: 8px;
}

@media only screen and (max-width: 777px) {
  .negativeMargin {
    margin: 0;
  }
}
