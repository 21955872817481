.asset {
  align-self: center;
}

.card {
  display: flex;
  flex-direction: column;
  width: 321px;
  height: 100%;
}

.container {
  position: relative; /* necessary to make intro animation appear below the content */
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}
