.radio {
  --border-color: var(--border-secondary);
  --knob-color: var(--background-accent-high);

  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--content-primary-a);
  forced-color-adjust: none;

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    transition: all 200ms;
    flex-shrink: 0;
  }

  &[data-pressed]:before,
  &[data-selected]:before {
    border-color: var(--knob-color);
    background: radial-gradient(circle at center, var(--knob-color) 40%, transparent 40%);
  }

  &[data-focus-visible]:before {
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }

  &:hover {
    cursor: pointer;
    &:before {
      background-color: var(--state-primary-a-hover);
    }
  }

  &[data-disabled] {
    cursor: not-allowed;
    color: var(--content-secondary);

    &:before {
      opacity: 0.45;
    }

    &:hover {
      &:before {
        background-color: var(--state-primary-a);
      }
    }
  }
}
