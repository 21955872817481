.container {
  display: flex;
  flex-direction: column;
  width: 504px;
  text-align: center;
  align-items: center;
  &.left-align {
    text-align: left;
    align-items: stretch;
  }
}


.lottie-container {
  display: flex;
  width: 504px;
  align-items: center;
  flex-direction: column;
}

.lottie {
  width: 200px;
  height: 200px;
  margin: 0 0 32px 0;
}

.title {
  color: var(--content-primary-a);
  margin: 0 0 16px 0;
}

.subtitle {
  color: var(--content-primary-a);
  margin: 0 0 32px 0;
}

.share-feedback-container {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
}

.subtitle-with-button {
  margin: 0 0 16px 0;
}

.textarea {
  margin-bottom: 32px;
}
