.submit-banner {
  margin-top: 8px;
  margin-bottom: 48px;
}

.banner {
  display: flex;
  gap: 32px;
  align-items: center;
  padding: 24px;
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
}

.content {
  flex-grow: 1;
}

.title-row {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
}

.title {
  composes: title-3 from global;
  color: var(--content-primary-a);
}

.badge {
  flex-shrink: 0;
}

.text {
  composes: body-2 from global;
  color: var(--content-primary-a);
}

.missing-info-disclaimer {
  margin-top: 24px;
}

.button {
  composes: btn btn--primary from global;
  margin-top: 24px;
}

.illustration {
  flex-shrink: 0;
  padding: 0 40px;
}

.image {
  width: 120px;
  height: 120px;
}
