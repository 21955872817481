.sidebar-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 16px 16px;
  background-color: var(--elevation-low);
  border-bottom: 1px solid var(--border-tertiary);
}

.footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding: 12px 16px;
  margin-top: auto;
  border-top: 1px solid var(--border-tertiary);
}

.disclaimer {
  width: 100%;
  margin: 16px 0;
}

.ghost {
  color: var(--content-tertiary);
  text-decoration: line-through;
}

.view {
  composes: body-2 from global;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  color: var(--content-primary-a);
  text-decoration: none;
  border-top: 1px solid var(--border-tertiary);

  svg {
    color: var(--content-accent);
  }
}
