.cards {
  display: flex;
  gap: 24px;
  justify-content: center;

  .card {
    flex: 1;
  }

  .illustration-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 170px;
    padding: 25px;
    border-radius: 8px;
  }

  .card.compliant .illustration-wrapper {
    background-color: var(--lottie-background-mint);
  }

  .card.flexible .illustration-wrapper {
    background-color: var(--lottie-background-purple);
  }

  .card.integrated .illustration-wrapper {
    background-color: var(--lottie-background-mustard);
  }

  .card.accelerate-efficiency .illustration-wrapper {
    background-color: var(--lottie-background-peach);
  }

  .card.keep-track .illustration-wrapper {
    background-color: var(--lottie-background-mustard);
  }

  .card.boost-bookkeeping .illustration-wrapper {
    background-color: var(--lottie-background-mint);
  }

  .illustration {
    width: 120px;
    height: 120px;
  }

  .list {
    padding: 0;
    list-style: none;
  }

  .list > li {
    display: flex;
    align-items: center;
  }

  .list > li:not(:last-child) {
    margin-bottom: 8px;
  }

  .list > li > svg {
    flex-shrink: 0;
  }
}
