.container {
  display: flex;
}

.illustration {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-right: 14px;
  margin-left: 6px;
}

.description {
  color: var(--content-secondary);
}

.description span {
  color: var(--content-accent);
}
