.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: var(--z-index-above);
  width: 100%;
  height: 100%;
  background-color: var(--background-modal-overlay);
}

.modal[data-entering] {
  animation: slide 250ms ease;
}

.modal[data-exiting] {
  animation: slide 250ms ease reverse;
}

.modal {
  height: 100vh;
  overflow: auto;
  background-color: var(--elevation-low);
  opacity: 1;
}

.main {
  position: relative;
  width: 100%;
  max-width: 504px;
  height: 100%;
  margin: 80px auto 0;
}
