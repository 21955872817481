.topbar {
  z-index: var(--z-index-above);

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 980px;
  height: 50px;
}

.info {
  background-color: var(--background-accent-low);
}

.warning {
  background-color: var(--background-warning-low);
}

.error {
  background-color: var(--background-error-low);
}

.wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}

.topbar a,
.message {
  color: var(--content-primary-a);
}

.topbar a:focus-visible {
  box-shadow: 0 0 0 4px var(--border-primary);
  border-radius: 4px;
}

.icon {
  display: flex;
  margin-right: 12px;
  color: var(--content-primary-a);
}

.close {
  margin-right: 8px;
}

.close:focus-visible {
  box-shadow: 0 0 0 4px var(--border-primary);
}
