.promotion-box {
  display: flex;
  justify-content: space-between;
  height: 84px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.promotion-name {
  composes: title-4 from global;
  margin-bottom: 4px;
}

.promotion-date {
  font-size: 14px;
  color: var(--content-secondary);
}

.badge-wrapper {
  display: flex;
  align-items: center;
}
