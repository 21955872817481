.error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 508px;
  text-align: center;
}
