.wrapper {
  max-width: 504px;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (width <= 776px) {
  .wrapper {
    padding: 24px 16px;
  }
}
