.period {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-left: -12px;
}

.periodTitle {
  margin-left: 8px;
  color: var(--content-secondary);
}
