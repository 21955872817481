.header-container {
  display: flex;
  justify-content: space-between;
  padding: 48px 48px 0;

  .header-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  h1 {
    margin-top: 0;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 188px;
    height: 126px;
    margin-right: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right top;
    }
  }

  .details-label {
    display: inline-block;
    width: 180px;
  }
}
