.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
    color: var(--content-primary-a);
}

.products {
    color: var(--content-primary-a);
}

.product {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 8px 0;
}

.container > *:nth-child(2n) {
    justify-self: end;
}

.color-secondary {
    color: var(--content-secondary);
}

.loader-padding {
    padding-top: 4px;
}

.total-padding {
    padding-bottom: 5px;
}
