@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.popup {
  line-height: 24px;

  button:first-of-type {
    z-index: 1;
  }

  :global(.react-aria-ModalOverlay) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: var(--z-index-above);
    width: 100%;
    height: 100%;
    background-color: var(--background-modal-overlay);
    animation: fadeIn 0.5s ease-in-out;
  }

  :global(.react-aria-ModalOverlay) > div {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.popup ul {
  padding-left: 20px;
}

.popup .lottie {
  z-index: 0;
  overflow: hidden;
  border-radius: 24px 24px 0 0;
}
