.container {
  display: flex;
  gap: 12px;
  align-items: center;
}

.avatar-img {
  width: 24px;
  height: 24px;
  border: 0.75px solid var(--content-primary-b);
  border-radius: 50%;
}
