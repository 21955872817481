.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  padding: 24px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
}

.description {
  color: var(--content-secondary);
}
