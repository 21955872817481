.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  place-items: center;
  width: 100%;
  max-width: 504px;
  text-align: center;
}

.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;

  & svg {
    display: block;
  }
}
