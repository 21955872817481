.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.success {
  width: 230px;
  height: 230px;
  margin: 0 auto;
}

.pending {
  width: 170px;
  height: 170px;
  margin: 32px 0;
}

.illustration {
  width: 118px;
  height: auto;
  margin: 0 auto 32px;
}
