.country {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
}

.flag {
  flex: 0 1 auto !important; /* Necessary if component used as a trigger in an `ember-power-select` component */
}

.badge {
  margin-left: auto;
}
