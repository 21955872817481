.counterparty-avatar {
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.counterparty-subtitle {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 105px;
  height: 24px;

  p {
    width: 80px;
    text-align: left;
  }
}

.counterparty-button:focus-visible {
  outline: none;
  box-shadow: none;

  &[data-focused] {
    outline: none;
    box-shadow: none;
  }

  &[data-focus-visible] {
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}
