.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 24px;
  align-items: flex-start;

  dd {
    margin: 0;
  }
}

.term {
  display: flex;
  align-items: center;
}

.description {
  max-width: 312px;
  text-align: right;
}

.tooltip {
  height: auto;
  padding: 0;
  font: inherit;
  vertical-align: middle;
  cursor: unset;
  background: none;
  border: none;
}

.icon {
  color: var(--content-secondary) !important;
}

.capitalize {
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
}
