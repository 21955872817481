.row {
  position: relative;
  height: 64px;

  &:hover {
    cursor: pointer;
    background-color: var(--elevation-low);
  }

  &:global(.ghost) {
    .counterparty-name,
    .counterparty-status,
    .amount,
    .next-date,
    .end-date,
    .frequency {
      color: var(--content-tertiary);
    }

    .amount {
      text-decoration: line-through;
    }
  }
}

.active {
  background-color: var(--background-tertiary);

  &:hover {
    cursor: default;
    background-color: var(--background-secondary);
  }
}

.cell {
  composes: body-2 from global;
  align-content: center;
  padding-right: 24px;
  color: var(--content-primary-a);
  white-space: nowrap;
  border-bottom: 1px solid var(--border-tertiary);

  &.no-padding {
    padding-right: 0;
  }

  @media only screen and (width < 1368px) {
    border-bottom: none;
  }
}

.cell-content {
  display: flex;
  align-items: center;
}

.counterparty {
  display: flex;
  align-items: center;
  color: var(--content-primary-a);
}

.status-avatar {
  width: 32px;
  height: 32px;
  margin-right: 16px;

  svg {
    right: -4px;
    bottom: -4px;
    width: 16px;
    height: 16px;
  }
}

.counterparty-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .beneficiary-name {
    max-width: 200px;
    overflow-x: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }
}

.next-date,
.end-date,
.amount {
  justify-content: flex-end;
  padding-right: 0;
  text-align: right;
}

.frequency,
.next-date,
.end-date,
.amount {
  color: var(--content-primary-a);
}

.amount {
  font-variant-numeric: tabular-nums;
}

.counterparty-status {
  color: var(--content-secondary);
}

.empty {
  width: 48px;
  min-width: 48px;
  padding: 0;
  text-align: center;
}

.disabled {
  .beneficiary-name,
  .cell-content {
    color: var(--content-tertiary);
  }
}

@keyframes fadein-item {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  26% {
    display: none;
  }

  68% {
    display: flex;
  }

  69% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
