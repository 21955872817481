.textarea {
  min-height: 104px;
  box-sizing: border-box;
  /* https://arc.net/l/quote/brwwyium : vertical-align is needed to remove some extra padding at the bottom of the textarea */
  vertical-align: top;
}

.fixed {
  resize: none;
}

.expandable {
  height: 40px;
  min-height: 40px;
  max-height: 104px;
  transition: min-height 0.3s;

  &:focus {
    min-height: 104px;
  }
}
