.wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  /* stylelint-disable style-lint-plugin-qonto/require-theme-token */
  background:
    linear-gradient(var(--elevation-mid) 0 0) padding-box,
    linear-gradient(to bottom right, #b0f0da 0%, #a88dff 50%, #ffbc90 100%) border-box;
  /* stylelint-enable style-lint-plugin-qonto/require-theme-token */
  border: 1px solid transparent;
  border-radius: 4px;
}

.subheading {
  composes: body-1 from global;
  margin-bottom: 16px;
  color: var(--content-secondary);
}

.lightningIcon {
  margin-right: 8px;

  /* !important required for overriding primary button color */
  color: var(--content-primary-b) !important;
}

.button {
  width: 100%;
}
