.row {
  composes: body-2 from global;
  display: flex;
  margin: 0;
  color: var(--content-primary-a);

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.label {
  flex-shrink: 0;
  margin-right: 8px;
  color: var(--content-secondary);

  &.danger {
    color: var(--content-error);
  }
}

.content {
  flex-grow: 1;
  margin: 0;
  overflow-wrap: anywhere;
}
