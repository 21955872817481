.language-trigger {
  display: flex;
  flex: 1;
  align-items: center;
}

.left-items-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.language-text {
  font-size: 14px;
  font-weight: 400;
}

.language-icon-container {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.chevron-down {
  transition: transform 300ms;
}

:global(.ember-basic-dropdown-trigger[aria-expanded='true']) .chevron-down {
  transform: rotate(180deg);
}
