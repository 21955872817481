.expectedDate {
  display: flex;
  align-items: center;
  gap: 8px;
  composes: body-2 from global;
}

.expectedDate.alert {
  color: var(--content-error);
}
