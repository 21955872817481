.container {
  width: 100%;

  .name {
    display: flex;
    gap: 8px;
    align-items: center;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .account {
    display: flex;
    gap: 4px;
    align-items: center;

    span {
      color: var(--content-secondary);
      overflow-wrap: anywhere;
    }

    svg {
      width: 10px;
      height: 10px;

      path {
        fill: var(--content-tertiary);
      }
    }
  }
}
