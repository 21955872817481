.container,
.card-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inline {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  & > div {
    flex: 1;
  }
}

.footnote {
  display: flex;
  align-items: center;
  align-self: end;
  gap: 8px;
  color: var(--content-secondary);
}

@media only screen and (width >= 768px) {
  .card-fields {
    gap: 24px;
  }
}
