.img {
  width: unset;
  height: unset;
}

@media (prefers-color-scheme: dark) {
  .img {
    filter: invert(1);
  }
}
