.participants-list {
  display: flex;
  gap: 8px;
  align-items: center;
}

.text {
  color: var(--content-secondary);
}

.avatar-img {
  width: 16px;
  height: 16px;
  border: 0.75px solid var(--content-primary-b);
  border-radius: 50%;
}

.avatar-item,
.name-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-group {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;

  .avatar-item:not(:first-child) {
    margin-left: -4px;
  }
}

.tooltip-trigger {
  display: flex;
  padding: 0;
}

.names-list {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style-type: none;
}

.name-item {
  margin-right: 4px;
}
