.custom-period {
  position: relative;
  min-width: 407px;
}

.header {
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: var(--separator-bottom);
}

.back-button {
  display: flex;
  align-items: center;
  padding: 0;
  color: var(--content-secondary);
}

.back-button-icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.form-inputs {
  display: flex;
  gap: 12px;
  padding: 8px 12px;
}

.form-inputs > * {
  flex: 1;
}

.form-actions {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  padding: 16px;
}
