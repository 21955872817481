@media only screen and (width > 1368px) {
  .page-header {
    min-height: 72px;
  }
}

@media only screen and (width < 1368px) {
  .header-breadcrumb {
    min-height: 112px;
  }
}

.tabs-container {
  display: flex;
  flex-grow: 1;
  width: unset;
  max-width: unset;
  min-height: 56px;
  padding-left: 48px;
  border-bottom: 1px solid var(--border-tertiary);
}

@media only screen and (width < 1368px) {
  .tabs-container {
    width: 100%;
    max-width: none;
    margin: 0 auto;
  }
}

.tabs-separator {
  display: inline-block;
  width: 1px;
  height: 16px;
  margin-right: 24px;
  background-color: var(--border-tertiary);
  border-radius: 1px;
}

.table-completed {
  margin-top: -1px;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 48px;
}

.filters {
  display: flex;
}
