.cell {
  padding: 8px 24px 7px 0;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);

  &.empty {
    width: 48px;
    min-width: 48px;
    padding: 0;
    border-bottom: none;
  }
}

.supplier {
  min-width: 240px;
  max-width: 0;
}

.supplierContent {
  display: flex;
  align-items: center;
}

.declined.cell:not(.supplier) {
  color: var(--content-tertiary);

  &.totalRepayment {
    text-decoration: line-through;
  }

  .amount {
    color: var(--content-tertiary);
  }
}

.supplierAvatar {
  composes: mr-16 from global;
  flex-shrink: 0;
  width: 32px;
  height: 32px;

  svg {
    right: -4px;
    bottom: -4px;
    width: 16px;
    height: 16px;
  }
}

.financingStatus,
.installments {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.overflowHidden {
  overflow: hidden;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.completedOn {
  width: 350px;
  min-width: 216px;
}

.financedAmount {
  composes: body-2 from global;
  width: 220px;
  min-width: 202px;
  padding-right: 0;

  .amount {
    color: var(--content-primary-a);
  }
}

.totalRepayment {
  composes: body-1 from global;
  width: 200px;
  min-width: 140px;
  padding-right: 0;
  padding-left: 24px;
  text-align: right;
}
