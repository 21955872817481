.tab-panel {
  margin-top: 4px;
  padding: 10px;
  border-radius: 4px;
  outline: none;

  &[data-focus-visible] {
    outline: 2px solid var(--focus-ring-color);
  }
}
