.panel {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.icon {
  width: 48px;
  height: 100%;
  margin-right: 24px;
  object-fit: contain;
}

.description > span {
  display: block;
}

.description > span:nth-child(2) {
  color: var(--content-secondary);
}
