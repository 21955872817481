.negativeMargin {
  margin: 0 -24px;
}

.container {
  display: flex;
  width: 100%;
}

.column {
  flex: 1;
}

.pricePlanWrapper {
  border-right: 1px solid var(--border-tertiary);
  padding: 0 16px;
  display: flex;
  box-sizing: content-box; /* only this one needs box-sizing: content-box; */
}

.pricePlanWrapper:first-child {
  padding-left: 0;
}

.pricePlanWrapper:last-child {
  border-right: none;
  padding-right: 0;
}

.pricePlanWrapper > * {
  box-sizing: border-box; /* need to reset because apps have box-sizing: inherit; globally */
}

.mobileView {
  display: none;
}

.desktopView {
  display: block;
}

@media only screen and (max-width: 777px) {
  .mobileView {
    display: block;
    overflow: hidden;
  }

  .desktopView {
    display: none;
  }

  .negativeMargin {
    margin: 0;
  }
}
