.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.total-amount {
  composes: body-1 from global;
  color: var(--content-primary-a);
}

.rates {
  composes: caption from global;
  color: var(--content-secondary);
  text-align: right;
}

.empty-state {
  composes: body-1 from global;
  color: var(--content-secondary);
  text-align: right;
}
