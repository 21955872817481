.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.align-start {
  align-items: start;
}

.align-center {
  align-items: center;
}

.animation {
  opacity: 1;
  transition: opacity 0.2s ease;
  will-change: opacity;

  &.hidden {
    opacity: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 384px;
  max-width: 550px;
  text-align: center;
}

.title {
  margin: 0 0 8px 0;
  color: var(--content-primary-a);
}

.buttons {
  display: flex;
  gap: 16px;
  align-items: center;
}

.image {
  width: 200px;
  height: 200px;
  margin-bottom: 32px;
}

.subtitle {
  margin-top: 0;
  color: var(--content-primary-a);
}
