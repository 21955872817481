.edit {
  justify-self: end;
}

.options {
  padding: 0;
}

.option {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  color: var(--content-primary-a);
  word-break: break-word;

  &:last-child {
    margin-bottom: 0;
  }
}

.option-icon {
  justify-self: end;
  width: 16px;
  height: 16px;
  margin-top: 4px;
  color: var(--content-tertiary);

  svg {
    width: 100%;
    height: 100%;
  }

  .option-enabled {
    color: var(--content-accent);
  }
}
