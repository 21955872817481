.period-wrapper {
  position: relative;
}

.dropdown {
  padding: 8px 0;
  background-color: var(--elevation-high);
  border-radius: var(--radius-dropdown);
  box-shadow: var(--shadow-high);
}

.invoices-entry-container {
  display: flex;
  flex-direction: column;
  width: 360px;
}

.dropdown-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 24px;
  color: var(--content-primary-a);
  text-decoration: none;
  white-space: nowrap;

  &:focus {
    box-shadow: none;
  }
}

.dropdown-button-current {
  background-color: var(--state-selected);
}

.transactions-entry-container {
  width: 432px;
}
