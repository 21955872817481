.active-image-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
  background: var(--background-tertiary);
  border-radius: 8px;
}

.active-image {
  position: absolute;
  top: 0;
  left: 0;
}

.image-button-container {
  display: flex;
  justify-content: center;
  margin: 0 -12px;
}

.image-button {
  width: 130px;
  min-width: 130px;
  height: 73px;
  padding: 0;
  margin: 0 12px;
  overflow: hidden;
  background: var(--background-tertiary);
  border: 2px solid transparent;
  border-radius: 8px;
  transition: box-shadow 0.4s ease;

  &:hover {
    box-shadow: var(--shadow-high);
  }
}

.active {
  border-color: var(--border-primary);
  box-shadow: var(--shadow-high);
}
