.trigger {
  height: 32px;
  padding: 6px 16px;
  color: var(--content-primary-a);
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-secondary);
  border-radius: 38px;

  &:hover::after {
    background-color: var(--overlay-primary-a-color);
    border-radius: 38px;
    opacity: var(--overlay-opacity);
  }
}

.trigger-content {
  display: flex;
  gap: 8px;
  align-items: center;
}

.icon {
  color: var(--content-primary-a);
}
