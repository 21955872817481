.popover {
  width: fit-content;
  min-width: 208px;
  height: fit-content;
  padding: 8px;
  padding: 0;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.checkboxes-wrapper {
  padding: 8px 0;
}

.item {
  display: block;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
}

.footer {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 16px;
  border-top: 1px var(--border-tertiary) solid;
}
