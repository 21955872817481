.line {
  position: relative;
  display: block;
  width: 100%;
  height: 8px;
  margin-top: 8px;
  overflow: hidden;
  background: var(--background-placeholder);
  background-size: 1000px 104px;
  border-radius: 50px;
  animation-name: backgroundAnimation;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.standalone {
  margin-top: 0;
}

@keyframes backgroundAnimation {
  0% {
    background-position: -500px;
  }

  100% {
    background-position: 500px;
  }
}
