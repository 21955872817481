.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: fit-content;
  padding: 16px;
  background: var(--elevation-default);
  border-radius: 10px;
}
