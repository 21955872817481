.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
}

.smsLabel {
  color: var(--content-secondary);
}
