.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 504px;
  height: 80px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
}
