.container {
  display: flex;
  justify-content: center;
}

.title {
  color: var(--content-primary-a);
}

.form-container {
  width: 504px;
  max-width: 504px;
}

.form,
.amount-row,
.date-row,
.actions,
.primary-btns {
  display: flex;
  gap: 16px;
}

.form {
  flex-direction: column;
}

.amount-row,
.date-row,
.actions {
  justify-content: space-between;
}

.date-row {
  align-items: center;
}

.actions {
  margin-top: 16px;
  align-items: center;
}

.full-width {
  width: 100%;
}

.side {
  display: flex;
  margin-top: 28px;
}
