.table-header {
  border-top: 1.25px solid var(--border-tertiary);
  border-bottom: 1.25px solid var(--border-tertiary);
}

.table-header:focus-visible {
  box-shadow: 0 0 0 4px inset var(--border-accent-low);
}

.table-header:hover .resizer {
  opacity: 1;
}

.table-header-content {
  composes: caption from global;
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 40px;
  color: var(--content-secondary);
  text-align: left;
  cursor: grab;
}

.header-title {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-title-pinned {
  display: flex;
  gap: 16px;
  align-items: center;
}

.table-header-content:has(.arrow-icon) {
  color: var(--content-primary-a);
}

.fixed-column {
  display: flex;
  gap: 16px;
}

.right {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.table-header-content:hover {
  color: var(--content-primary-a);
}

.table-header-content button {
  position: absolute;
  top: 50%;
  right: 4px;
  display: flex;
  opacity: 0;
  transform: translateY(-50%);
}

.table-header-content:hover button,
.table-header-content button[data-pressed] {
  opacity: 1;
}

.on-drag {
  cursor: grabbing;
}

.resizer {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 100%;
  touch-action: none;
  cursor: col-resize;
  user-select: none;
  opacity: 0;
}

.middle-resizer {
  width: 2px;
  height: 100%;
  background: var(--border-accent-high);
}

.border-resizer {
  width: 2px;
  height: 16px;
  background: var(--border-accent-high);
  border-radius: 15px;
}

.resizer.ltr {
  right: -4px;
}

.resizer.rtl {
  left: 0;
}

.resizer-visible {
  opacity: 1;
}

.header-title:has(+ button[data-pressed]),
.table-header-content:hover .header-title,
.table-header-content.sorted .header-title,
.right.table-header-content:hover .header-title {
  max-width: calc(100% - 24px);
}

.right .header-title {
  min-width: 0;
  max-width: 100%;
  text-align: right;
}

.right.table-header-content button {
  right: 8px;
  left: auto;
}
