.header {
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: var(--elevation-default);
}

.heading {
  flex-grow: 1;
  text-align: center;
}

.title {
  composes: body-1 from global;
  color: var(--content-primary-a);
}

.subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--content-secondary);
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.back-button,
.close-button {
  flex-shrink: 0;
}
