/* stylelint-disable style-lint-plugin-qonto/require-theme-token */
.background {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--x-card-black);
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 504px;
  color: var(--gray100);
}

.illustration {
  width: 200px;
  height: 200px;
}

.hidden {
  display: none;
}

.exit {
  color: var(--content-primary-a-static);
  background-color: var(--content-primary-b-static);
}

.close {
  position: absolute;
  top: 16px;
  right: 16px;

  svg {
    color: var(--gray100);
  }
}
