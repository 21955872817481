.sidepanel-tabs-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidepanel-tabs {
  height: 100%;
}

.sidepanel-tab-list {
  flex-shrink: 0;
  padding-left: 0;
}

.sidepanel-tab-panel {
  height: 100%;
  padding: 0;
  margin-top: 0;
}

.tab-panel-container {
  height: 100%;
}

.sidepanel-tabs-loading-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid var(--border-tertiary);
}
