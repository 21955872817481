.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.title {
  composes: body-2 from global;
  margin-bottom: 8px;
  color: var(--content-secondary);
}

.timeline-item {
  position: relative;
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 20px;
  align-items: start;
  justify-items: start;

  &:last-of-type {
    &::before {
      display: none;
    }
  }
}

.timeline-item::before {
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 8px;
  display: block;
  content: '';
  border-left: 1px dotted var(--border-secondary);

  &:last-of-type {
    display: none;
  }
}

.timeline-icon-container {
  display: flex;
  align-items: end;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-top: 4px;
  background-color: var(--elevation-default);
  border-radius: 50%;
}

.timeline-item-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 6px;
}

.timeline-item-title {
  composes: body-2 from global;
  color: var(--content-primary);
}

.timeline-item-subtitle {
  composes: caption from global;
  padding-bottom: 4px;
  color: var(--content-secondary);
}
