.ocr-pending {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 304px;
  text-align: center;

  .ocr-illustration {
    width: 200px;
    height: 200px;
    margin-bottom: 32px;
  }
}
