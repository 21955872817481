.activity-tag-container {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 24px;
  background-color: var(--elevation-low);

  /** !important because of :last-child selector applying wrong style on this element */
  background-clip: border-box !important;
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
}

.icon {
  composes: mr-8 from global;
  flex-shrink: 0;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 12px;
}

.label {
  width: 120px;
  max-width: 100%;
  overflow: hidden;
  font-size: var(--body-1-font-size);
  font-weight: var(--body-1-font-weight);
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
