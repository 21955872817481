.integrations-searchbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px 16px 24px;
}

.scrolled {
  z-index: var(--z-index-default);
  box-shadow: var(--shadow-low);
}

.integrations-searchbar {
  width: 100%;
}

.integration-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 12px 0 12px;
  overflow-y: auto;
}

.tile-container {
  padding: 8px 12px 12px 12px;
  list-style: none;
}

.empty-state-container {
  margin-top: auto;
}
