.statusDescription {
  composes: body-2 from global;
  margin-top: 12px;
  color: var(--content-secondary);
}

.amount {
  composes: body-1 from global;
  display: flex;
  color: var(--content-primary-a);
}

.amountRate {
  margin-left: 8px;
}

.vatSuggestedDescription {
  composes: caption from global;
  color: var(--content-secondary);
}

.actions {
  display: flex;
  margin-top: 16px;
}

.actionsLeft {
  flex: 1;
  margin-right: 8px;
}

.actionsRight {
  flex: 1;
  margin-left: 8px;
}
