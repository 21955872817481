.card-sidebar-header {
  padding: 0 16px 13px;
  margin: 0;
  background-color: var(--elevation-low);
  border-bottom: 1px solid var(--border-tertiary);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header-type {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.close {
  position: absolute;
  right: 0;
}

.close-icon {
  width: 12px;
}

.header-infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.title {
  composes: body-1 from global;

  &.danger {
    color: var(--content-error);
  }

  &.success {
    color: var(--content-success);
  }

  &.warning {
    color: var(--content-warning);
  }
}

.date {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 352px;
  margin-top: -96px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.spending-reason {
  width: 100%;
  padding: 16px;
  margin-top: 112px;
  border-top: 1px solid var(--border-tertiary);
}

.spending-reason-label {
  composes: body-2 mr-8 from global;
  color: var(--content-secondary);
}

.spending-reason-note {
  composes: body-2 from global;
  color: var(--content-primary-a);
}
