.row {
  height: 64px;
}

.cell {
  padding: 0 48px 0 0;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-tertiary);

  &.review {
    padding: 0;
  }

  &.empty {
    width: 48px;
    padding: 0;
    border-bottom: none;
  }
}

.cellContent {
  display: flex;
  align-items: center;
}

.justifyContentRight {
  justify-content: right;
}
