.container {
  width: 100%;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  list-style-type: none;
}

.itemDescription {
  color: var(--content-secondary);
}
