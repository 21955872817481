.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.list-item {
  padding: 8px 16px;
  cursor: pointer;

  &[data-selected='true'] {
    background-color: var(--state-selected);
  }

  &[data-hovered='true'] {
    background-color: var(--elevation-low);
  }

  &[data-hovered='true'][data-selected='true'] {
    background-color: var(--state-selected-hover);
  }
}

.attachment-request-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.tooltip {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 304px;
  padding: 8px 12px;
}

.tooltip-trigger {
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: unset;
  background: none;
  border: none;
}

.icon {
  color: var(--content-secondary);
}
