.edit-vehicle {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 80px;
  padding: 16px;
  text-decoration: unset;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  outline: none;
  transition: all var(--transition-duration-short) ease-in-out;
}

.header {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title {
  color: var(--content-primary-a);
  text-align: left;
}

.subtitle {
  color: var(--content-secondary);
  text-align: left;
}
