.company-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 80px;
  padding: 16px;
  text-decoration: unset;
  appearance: none;
  cursor: pointer;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  outline: none;
  box-shadow: var(--shadow-low);
}

.chevron-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 16px;
  height: 16px;
  background: transparent;

  svg {
    width: auto;
    height: 16px;

    path {
      fill: var(--content-secondary);
    }
  }
}

.subtitle {
  width: 432px;
  overflow: hidden;
  color: var(--content-secondary);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title {
  width: 432px;
  overflow: hidden;
  color: var(--content-primary-a);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
