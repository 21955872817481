.choice-chip {
  position: relative;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.choice-chip--label {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background-color: var(--overlay-primary-a-color);
    opacity: var(--overlay-opacity);
  }

  &:hover::after {
    --overlay-opacity: var(--hover-opacity);
  }

  &:active::after {
    --overlay-opacity: var(--pressed-opacity);
  }

  &[disabled] {
    cursor: not-allowed;

    &::after {
      --overlay-primary-a-color: var(--elevation-default);
      --overlay-opacity: var(--disabled-opacity);

      cursor: not-allowed;
    }
  }

  display: block;
  padding: 8px 16px;
  color: var(--content-primary-a);
  cursor: pointer;
  background-color: var(--background-secondary);
  border-radius: 4px;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:active::after,
  &:hover::after {
    border-radius: 4px;
  }
}

.choice-chip--input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;

  &:active + .choice-chip--label,
  &:focus + .choice-chip--label {
    outline: none;
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }

  &:checked + .choice-chip--label {
    color: var(--content-primary-b-static);
    background-color: var(--background-accent-high);
  }

  &:checked:hover:not(:disabled) + .choice-chip--label {
    color: var(--content-primary-b-static);
    background-color: var(--background-accent-high);
  }

  &:checked:active + .choice-chip--label {
    color: var(--content-primary-b-static);
    background-color: var(--background-accent-high);
  }

  &:disabled + .choice-chip--label {
    color: var(--content-primary-a);
    cursor: not-allowed;
    background-color: var(--background-secondary);
    box-shadow: none;
    opacity: var(--disabled-opacity);
  }
}
