.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: var(--content-secondary);
  background-color: var(--elevation-low);
  border-radius: 6px;
}
