.container {
  display: grid;
  width: 272px;
  height: 100%;
  padding: 32px 24px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);

  &:hover {
    box-shadow: var(--shadow-high);
  }
}

.type-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--content-primary-a);
  text-align: center;
}

.type-card,
.spinner {
  grid-row: 1;
  grid-column: 1;
  place-self: center;
}

.type-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 16px;
}

.loading {
  visibility: hidden;
}
