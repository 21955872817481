.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.title {
  font-weight: 400;
  line-height: 24px;
  color: var(--content-primary-a);
}

.closeButton {
  position: absolute;
  right: 14px;
}
