.container {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 16px 16px;

  .name {
    display: flex;
    gap: 8px;
    align-items: center;

    span {
      word-break: break-word;
    }

    svg {
      flex-shrink: 0;
      width: 12px;
      height: 12px;
    }
  }

  .account {
    display: flex;
    gap: 4px;
    align-items: center;

    span {
      color: var(--content-secondary);
      overflow-wrap: anywhere;
    }

    svg {
      width: 10px;
      height: 10px;

      path {
        fill: var(--content-tertiary);
      }
    }
  }

  .currency {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 16px;
    color: var(--content-secondary);
    text-transform: uppercase;
    border: 1px solid var(--border-secondary);
    border-radius: 2px;
  }
}
