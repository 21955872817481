.client-toggle-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.client-toggle-name-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.toggle-wrapper {
  position: relative;
}

.hidden-popup-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
