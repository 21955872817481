.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.top-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: var(--category-gray);
  border-radius: 50%;
}

.creditor {
  margin-bottom: 14px;
}

.amount {
  margin-bottom: 10px;
}

.creditor,
.date {
  color: var(--content-secondary);
}

.ghost {
  color: var(--content-tertiary);
  text-decoration: line-through;
}
