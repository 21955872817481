.amounts-breakdown {
  all: unset;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  list-style: none;
}

.item {
  composes: body-2 from global;
  display: flex;
  flex: 1;
  justify-content: space-between;
  color: var(--content-primary-a);
}

.label {
  color: var(--content-secondary);
  white-space: nowrap;
}
