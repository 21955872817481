.header-cell {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 40px;
  padding: 0 24px 0 0;
  text-align: left;
  white-space: nowrap;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
}

.header-content.active {
  color: var(--content-primary-a);
}

.empty {
  width: 48px;
  min-width: 48px;
}

.align-right {
  padding-right: 0;
  text-align: right;
}

.type {
  width: 30%;
}

.requester {
  width: 30%;
}

.date {
  width: 110px;
}

.amount {
  width: 20%;
}

.status {
  width: 130px;
  padding: 0 37px;
  text-align: center;
}
