.card {
  display: grid;
  grid-template: repeat(3, auto) / 1fr auto;
  grid-template-areas: 'title amount' 'subtitle subtitle' 'actions actions';
  column-gap: 16px;
  row-gap: 8px;
}

.title {
  grid-area: title;
}

.amount {
  grid-area: amount;
  justify-self: end;
}

.subtitle {
  grid-area: subtitle;
  margin-bottom: 16px;
}

.actions {
  grid-area: actions;
  display: flex;
  gap: 8px;
}
