.comments-list {
  --bubble-size: 24px;
  --bubble-title-gap: 12px;
  --bubble-line-gap: 4px;

  padding-top: 12px;
}

.title-slot {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.avatar,
.note-icon {
  width: var(--bubble-size);
  height: var(--bubble-size);
  border-radius: 50%;
}

.note-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  color: var(--content-secondary);
  background-color: var(--elevation-low);
  border: 0.75px solid var(--border-tertiary);
}

.avatar::before {
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background-color: var(--elevation-default);
  border-radius: 50%;
}

.full-name {
  composes: body-1 from global;
}

.time-created {
  composes: caption from global;
  color: var(--content-secondary);
}

.message-content {
  composes: body-2 from global;
  margin-bottom: 16px;
  word-break: break-word;
}
