.accordion-trigger {
  display: flex;
  align-items: center;
  padding: 0;
  overflow: visible;
  color: var(--content-secondary);

  &:hover {
    color: var(--content-primary-a);

    path {
      fill: var(--content-primary-a);
    }
  }
}

.content-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.25s ease-in-out;
}

.expanded {
  grid-template-rows: 1fr;
  margin-bottom: 12px;
}

.quality-item {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  overflow: hidden;
}

.chevron {
  display: flex;
  color: var(--content-secondary);
  transition: 0.2s ease-in-out;

  svg {
    width: 12px;
    height: 12px;
  }
}

.chevron-down {
  transform: rotateZ(180deg);
}
