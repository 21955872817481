.card {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  height: 134px;
  padding: 24px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.header {
  display: flex;
  flex-shrink: 0;
  gap: 16px;
  align-items: flex-end;
  align-self: stretch;
  height: 48px;
}

.amountSpent {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}

.amountBudgeted {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
