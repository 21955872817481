.row {
  height: 64px;
  vertical-align: middle;
}

.col {
  border-bottom: 1px solid var(--border-tertiary);
}

.counterparty {
  display: flex;
  align-items: center;
  color: var(--content-primary-a);
}

.header {
  display: flex;
  align-items: center;
  padding-right: 24px;
}

.icon {
  margin-right: 16px;
}

.col-12 {
  width: 50%;
}

.col-8 {
  width: 33.33333%;
}

.col-6 {
  width: 25%;
}

.col-4 {
  width: 16.66667%;
}

.col-2 {
  width: 8.33333%;
}

.hide {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
