.flowmain {
  background-color: var(--elevation-default);
}

.flowillustration {
  background-color: var(--elevation-low);
}

.description {
  color: var(--content-secondary);
}

.illustration {
  position: relative;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
}

.proposal,
.contact {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contact {
  padding: 24px;
  background-color: var(--elevation-low);
}

.submit {
  display: flex;
  gap: 16px;
}

.error {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--content-error);
}
