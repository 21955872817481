.business-cards p {
  max-width: 544px;
  color: var(--content-secondary);
}

.business-cards-item {
  display: flex;
  margin-bottom: 32px;
  text-align: left;
}

.business-cards-icon {
  width: 48px;
  height: 48px;
  margin-right: 24px;
}

/* stylelint-disable style-lint-plugin-qonto/require-theme-token */
:global(.premium-theme) .business-cards p {
  color: var(--gray300);
}
