.products {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: none;
}

.item {
  width: 100%;
  max-width: 468px;
}

.disclaimer {
  /* Account for borders */
  height: 57px;
  margin-bottom: 16px;
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 140px);
}

.emptyContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
}

.loadingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: calc(100vh - 208px);
  background-color: var(--elevation-default);
}

.skeletonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
