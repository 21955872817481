.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 48px;
  margin-top: 24px;
  background: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;

  :last-child {
    background-clip: content-box;
  }
}

.picto {
  position: absolute;
  top: -24px;
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
}

.picto-status {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
}
