.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
}

.close {
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  z-index: var(--z-index-default);
}

.closeIcon {
  path {
    fill: var(--content-primary-a);
  }
}

.title {
  composes: body-1 from global;
}

.subtitle {
  composes: body-2 from global;
  color: var(--content-secondary);
}

.success {
  color: var(--content-success);
}

.error {
  color: var(--content-error);
}
