.placeholder-sidebar {
  padding: 24px;
}

.details {
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;
}

.details-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0 32px;
  border-bottom: 1px solid var(--border-tertiary);
}

.details-content {
  padding: 24px 16px;
}

.details-footer {
  display: flex;
  justify-content: center;
  padding: 24px 0;
  border-top: 1px solid var(--border-tertiary);
}
