.illustrationWrap {
  width: 425px;
  height: 118px;
  margin: 0 auto 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.faqLink {
  color: var(--content-accent);
}

/* The following styles are used only in the enforce QR code component */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
}

.insetBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: auto;
  padding: 24px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.insetBox img {
  width: 160px;
  height: 160px;
  aspect-ratio: 1;
}

.separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 16px;
  color: var(--border-tertiary);
}

.separator:before,
.separator:after {
  content: '';
  flex-grow: 1;
  border-top: 1px solid var(--border-tertiary);
}
