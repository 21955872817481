.card {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 16px;
  margin-bottom: 32px;
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
}
