.details-container {
  composes: mb-16 from global;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 16px;
  background-color: var(--elevation-low);
  border-radius: 8px;
}

.payment-detail {
  composes: body-2 from global;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
  color: var(--content-primary-a);
}
