.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.illustration {
  display: block;
  margin-bottom: 32px;
}

.illustration--error {
  width: 200px;
  height: 200px;
}

.illustration--retry {
  width: 90px;
}
