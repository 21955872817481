.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  height: 100%;
  overflow-y: auto;
}

.illustrationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--background-free-trial);
}

.img {
  height: 480px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 504px;
  margin-inline: 48px;
}

.introContent a {
  color: var(--content-accent);
}

.subDetails {
  margin-bottom: 24px;
  color: var(--content-secondary);
}

.subDetails:last-of-type {
  margin-bottom: 32px;
}
