.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 24px 0 0;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--elevation-default);
  border-top: 1px solid var(--border-tertiary);
  border-bottom: 1px solid var(--border-tertiary);

  &.align-right {
    padding-right: 0;
    text-align: right;
  }
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);

  &.active {
    color: var(--content-primary-a);
  }
}

.empty {
  width: 48px;
  min-width: 48px;
  padding: 0;
  text-align: center;
}

.col-12 {
  width: 50%;
}

.col-8 {
  width: 33.33333%;
}

.col-6 {
  width: 25%;
}

.col-4 {
  width: 16.66667%;
}

.col-2 {
  width: 8.33333%;
}

.hide {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
