.header-cell {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
  height: 40px;
  padding: 0 24px 0 0;
  text-align: left;
  white-space: nowrap;
  vertical-align: middle;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
}

.header-content {
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);
}

.header-content.active {
  color: var(--content-primary-a);
}

.empty {
  width: 48px;
  min-width: 48px;
}

.amount {
  padding: 0;
  text-align: right;
}

.header-status {
  padding: 0 37px;
  text-align: center;
}
