.container {
  position: relative;
  display: inline-flex;
  height: 40px;
  color: var(--content-primary-a);
  background-color: var(--background-tertiary);
  border: 1px solid var(--border-tertiary);
  border-radius: 56px;
}

.accent {
  border: 1px solid var(--border-accent-static);
}

.disabled {
  opacity: 0.45;

  .label {
    cursor: not-allowed;
    user-select: none;
  }

  .grid:hover {
    background-color: var(--background-tertiary);
  }

  .grid:active {
    background-color: var(--background-tertiary);
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 1px;
  border-radius: 56px;

  &:hover {
    background-color: var(--state-primary-a-hover);
  }

  &:active {
    background-color: var(--state-primary-a-pressed);
  }
}

.background {
  /* 
    1px and 2px offsets are to mimic an invisible border around the background
    since the background is animated and we had to use an absolute positioning
  */
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-default);
  width: 50%;
  height: calc(100% - 2px);
  margin-top: 1px;
  background: var(--background-toggle-button);
  border-radius: 56px;
  transition: transform 0.2s ease-in-out;
  transform: translateX(1px);

  &.right {
    transform: translateX(calc(100% - 1px));
  }

  .accent > .grid > & {
    background: var(--background-accent-static);
  }
}

.input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:focus-visible + .label {
    border-radius: 58px;
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}

.label {
  z-index: var(--z-index-default);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 20px;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  border-radius: 56px;
  transition: color 0.2s ease-in-out;
}

.selected {
  color: var(--content-primary-a-static);
}
