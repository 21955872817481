.selector-group {
  gap: 16px;
}

.selector-wrapper {
  background-color: var(--elevation-high);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);

  &:hover {
    box-shadow: var(--shadow-high);
  }

  &:focus-within {
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}

.selector {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 16px;
}

.selector-content {
  display: flex;
  gap: 16px;
  align-items: center;

  svg {
    height: 40px;
  }
}

.selector-disclaimer {
  padding: 16px;
  border-top: 1px solid var(--border-tertiary);
}
