.data-object-trigger {
  display: inline-flex;
  align-items: center;
  padding: 2px 4px;
  gap: 8px;
  overflow: visible;
  height: 24px;
  background: transparent;
  border: none;
  color: var(--content-secondary);
  border-radius: 4px;

  transition: color 0.2s ease;

  &:hover {
    color: var(--content-primary-a);

    svg {
      color: var(--content-primary-a);
    }
  }
}

.chevron {
  display: flex;
  color: var(--content-secondary);
  transition: 0.2s ease-in-out;

  width: 12px;
  height: 12px;
}

.chevron-down {
  transform: rotateZ(180deg);
}
