.content {
  max-width: 304px;
  padding: 8px 12px;
  word-wrap: break-word;
  color: var(--content-primary-b);
  background-color: var(--background-primary);
  border-radius: 4px;
}

.button {
  all: unset;
  display: flex;
  cursor: pointer;
}
