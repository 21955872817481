.container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 980px;
  margin: 180px auto 48px;
}

.image {
  width: 200px;
  height: 200px;
  margin: 0 0 32px 0;
}
