.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 24px 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.header-section {
  gap: 4px;
  align-items: center;
}

.header-title {
  display: flex;
  align-items: center;
  height: 32px;
}

.header-item {
  display: flex;
  align-items: center;
  height: 24px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  height: 24px;
}

.center-align {
  align-items: center;
}

.content-gap {
  gap: 8px;
}

.cta-footer {
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 16px;
  align-items: flex-end;
  padding: 16px 24px;
  border-top: 1px solid var(--border-tertiary);
}

.section-content {
  margin: 24px 24px;
  border: 1px solid var(--border-tertiary);
  border-radius: 6px;
}

.placeholder-sidebar {
  position: relative;
  height: 100%;
}

.details {
  height: 100%;
}
