.container {
  background-color: var(--elevation-default);
  color: var(--content-primary-a);
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex:1;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
}

.title {
  margin: 0;
}

.strikethroughtPrice {
  color: var(--content-secondary);
  text-decoration: line-through;
  margin-right: 8px;
}

.priceDescription {
  color: var(--content-secondary);
}

.description {
  min-height: 72px;
}

.ctaDescription {
  margin: 8px 0 24px 0;
  text-align: center;
  color: var(--content-secondary);
}

.benefitsGroups {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bottomSection {
  margin-top: auto;
}

@media only screen and (max-width: 777px) {
  .container {
    padding: 24px 16px;
  }

  .priceDescriptionBlock {
    display: block;
  }
}