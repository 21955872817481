.animation-poster {
  position: absolute;
  top: 0;
  display: none;
}

.asset {
  position: relative;
  width: 100%;
}

.fallback {
  display: block;
  width: 100%;
}

.video {
  display: block;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .animated .animation-poster {
    display: block;
    width: 100%;

    /* animation must be synced with those in app/components/flows/cards/choose-card.module.css */
    animation: var(--text-animation-duration) ease-in var(--text-animation-delay) forwards fadeOut;
  }

  .animated .video {
    opacity: 0;

    /* animation must be synced with those in app/components/flows/cards/choose-card.module.css */
    animation: var(--text-animation-duration) ease-out var(--text-animation-delay) forwards fadeIn;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
