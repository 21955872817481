.item {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 12px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.line {
  margin-right: auto;
}

.icon {
  width: 16px;
  height: 16px;
  color: var(--content-tertiary);
}

.content {
  padding: 12px;
}

.section {
  padding: 8px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--border-tertiary);
  }
}
