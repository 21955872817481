.basket-header {
  display: none;
  background-color: var(--background-tertiary);

  th {
    padding: 16px;
  }

  .item-header {
    text-align: left;
  }

  .quantity-header,
  .vat-header,
  .total-header {
    text-align: right;
  }

  @media only screen and (width >= 1369px) {
    display: revert;
  }

  /* Preview mode needs to apply desktop styles regardless of screen size */
  &.preview {
    display: revert;
  }
}
