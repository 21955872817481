.contactSection {
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;

  p {
    margin: 0;
  }
}

.title2 {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.body1 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.address {
  display: inline-block;
  flex-basis: 0;
  flex-grow: 1;
  vertical-align: top;
}
