.card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  padding: 16px;
  background: var(--elevation-mid);
}

.footer {
  padding: 12px 16px;
  color: var(--content-secondary);
  background: var(--background-tertiary);
}

.details {
  composes: body-1 from global;
  display: flex;
  flex-direction: column;
  gap: 2px;

  :last-child {
    color: var(--content-secondary);
  }
}

.supplier-name span:first-child {
  color: var(--content-secondary);
}

.edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: var(--background-secondary);
  border-radius: 4px;
}
