.cell-content {
  composes: body-1 from global;
  display: flex;
  align-items: center;
}

.request-mileage-icon {
  display: flex;
  align-items: center;
  margin-right: 16px;

  img {
    width: unset;
  }
}

.subtitle {
  overflow: hidden;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell-info {
  overflow: hidden;
}
