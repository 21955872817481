.document-collection-banner {
  margin-top: 8px;
  margin-bottom: 48px;
}

.banner {
  display: flex;
  gap: 32px;
  align-items: center;
  padding: 24px;
  background-color: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 8px;
  box-shadow: var(--shadow-low);
}

.inner {
  flex: 1;
}

.header {
  composes: title-3 from global;
  margin-bottom: 8px;
  color: var(--content-primary-a);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
}

.subtitle {
  composes: body-1 from global;
  color: var(--content-primary-a);
  white-space: pre-line;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
}

.item {
  composes: body-1 from global;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--content-primary-a);
}

.icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.cta {
  composes: btn btn--primary from global;
}

.spinner {
  margin-left: 8px;
}

.illustration {
  flex-shrink: 0;
  padding: 0 40px;
}

.image {
  width: 120px;
  height: 120px;
}
