.container {
  width: 100%;
  padding: 120px 65px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.form {
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto 120px;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 48px;

  svg {
    width: 100px;
    height: auto;
  }
}

.form-subtitle {
  margin: 32px 0 16px;
}

.form-input {
  width: 100%;
}

.left-input {
  width: 50%;
  margin-right: 16px;
}

.right-input {
  width: 50%;
}

/* TODO: remove label alignment fix */
.label-fix {
  display: flex;
  flex-direction: column;
}

.label-fix .x-validated-input__header {
  flex: 1;
}

.form-dropzone :global(.q-file-uploader__dropzone) {
  width: 100%;
}

.form-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 48px;
  background: var(--elevation-default);
  border-top: 1px solid var(--border-secondary);
}

.submit-cta {
  margin-left: 16px;
}

.checklist {
  background: var(--elevation-low);

  a {
    color: var(--content-accent);
  }
}

.about-you-form {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}

.name-inputs,
.birth-inputs {
  display: flex;
}
