.baseOverlay {
  --first-column: 264px;

  position: absolute;
  right: 0;
  width: 53%;
  height: 235px;
}

.overlay-5-col {
  --covered-columns: 2;

  width: calc(calc((100% - var(--first-column)) / 4) * var(--covered-columns));
}

.overlay-7-col {
  --covered-columns: 4;

  width: calc(calc((100% - var(--first-column)) / 6) * var(--covered-columns));
}

.overlayGradient {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  width: 100%;
  height: 205px;
  padding-right: 32px;
  /* stylelint-disable style-lint-plugin-qonto/require-theme-token */
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, var(--elevation-default) 25%);
}
