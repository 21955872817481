.emptyStateEducation {
  margin: auto 0;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  text-align: center;
  align-self: center;
  min-width: 300px;
  max-width: 550px;
}

.title {
  color: var(--content-primary-a);
}

.actions {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.footer {
  width: 100%;
  max-width: 1068px;
  margin: 0 auto;
  border-top: 1px solid var(--border-tertiary);
}

.footerContent {
  max-width: 550px;
  margin: auto;
  height: 120px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--content-primary-a);
}

.footerSubtitle {
  color: var(--content-secondary);
}
