.view-all-transactions-button {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 318px;
  height: 48px;
  padding: 0 16px;
  margin: 16px -16px 0 -16px;
  font-weight: 300;
  background-color: var(--background-secondary);

  &:hover {
    background-color: var(--state-primary-a-hover);
  }

  &:focus {
    border-radius: 4px;
    outline: none;
    box-shadow: 0 0 0 4px var(--border-accent-low);
  }
}
