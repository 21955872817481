.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 600px;
}

.top {
  margin-bottom: 30px;
}

.price {
  display: flex;
  align-items: center;
  gap: 8px;
}
