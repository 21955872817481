.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.hidden {
  visibility: hidden;
}

.lottie {
  width: 200px;
  height: 200px;
}
