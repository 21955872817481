.monthly-transfer-limit-label {
  width: 100%;
}

.monthly-transfer-limit-label,
.available-amount {
  display: flex;
  justify-content: space-between;
}

.bar-container {
  position: relative;
  width: 100%;
  height: 6px;
  margin: 8px 0;
  overflow: hidden;
  background-color: var(--background-secondary);
}

.bar-container,
.bar {
  border-radius: 4px;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--background-warning-high);
}

.bar.danger {
  background-color: var(--background-error-high);
}

.available-amount {
  margin-bottom: 16px;
  color: var(--content-secondary);
}
