.wrapper {
  position: relative;
  display: inline-flex;
}

.attachment-icon {
  color: var(--content-secondary);
}

.status-icon {
  position: absolute;
  right: -4px;
  bottom: -4px;
}
