.step-container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  max-width: 504px;
  text-align: center;
}

.error-image {
  width: 200px;
  height: 200px;
}
