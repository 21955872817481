.checks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 48px 0;
  background-color: var(--elevation-default);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 504px;
  margin: 0 auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.title {
  display: flex;
  width: 100%;
  color: var(--content-primary-a);
}

.wrapper-body {
  width: 100%;
}

.aside {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8%;
  background-color: var(--elevation-low);
}

.steps-container {
  width: 100%;
}

.step {
  display: flex;
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:not(:last-child) {
    min-height: 88px;
  }
}

.container-dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  background-color: var(--elevation-high);
  border-radius: 50%;
  box-shadow: var(--shadow-high);
}

.text {
  width: 330px;
  padding-top: 4px;
}

.text-title {
  color: var(--content-primary-a);
}

.text-desc {
  color: var(--content-secondary);
}

.start-button:focus-visible {
  box-shadow: inset 0 0 0 4px var(--border-accent-low);
}
