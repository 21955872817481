.instructions {
  composes: mb-16 from global;
  width: 100%;
  color: var(--content-secondary);
}

.list {
  composes: body-2 from global;
  padding: 0;
  list-style: none;
}

.list > li {
  display: grid;
  grid-template-columns: 12px 1fr;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.icon {
  margin-top: 6px;
}
