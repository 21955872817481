.container {
  height: 100vh;
  margin-top: 32px;
}

.overview {
  display: flex;
  gap: 32px;
  margin-right: 48px;
  margin-bottom: 12px;
  margin-left: 48px;

  > div {
    width: 100%;
    min-width: 256px;
    max-width: 100%;
  }
}

.chartContainer {
  position: relative;
  top: 20px;
  z-index: var(--z-index-mid);
  margin-right: 48px;
  margin-bottom: 16px;
  margin-left: 48px;
  background: var(--elevation-default);

  svg {
    cursor: pointer;
  }
}

/* stylelint-disable-next-line media-feature-range-notation */
@media screen and (max-height: 670px) {
  .overview {
    display: none;
  }
}

.paginationButtons {
  position: absolute;
  bottom: -9px;
  z-index: 1001;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 25px;

  button {
    background: transparent !important;

    &:hover {
      background: var(--state-primary-a-hover) !important;
    }
  }
}
