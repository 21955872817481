.container {
  transform: scale(var(--scale));
  transform-origin: left top;
  width: calc(1 / var(--scale) * 100%);
  border: 1px solid var(--border-tertiary);
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--content-primary-a);

  .header {
    height: 70px;
    background-color: var(--background-tertiary);
    padding: 16px 260px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circles {
    display: flex;
    gap: 12px;
    position: absolute;
    left: 24px;
  }

  .circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &.red {
      background-color: #f95656;
    }

    &.orange {
      background-color: #ec9b50;
    }

    &.green {
      background-color: #45b184;
    }
  }

  .address {
    background-color: var(--background-secondary);
    border-radius: 8px;
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .content {
    flex: 1;
  }
}
