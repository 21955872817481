.steps {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 0;
  list-style: none;
  counter-reset: step;
  gap: 32px;
}

.step {
  display: flex;
  align-items: center;
  gap: 16px;
}

.step::before {
  content: counter(step);
  counter-increment: step;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  color: var(--content-primary-a);
  border: 1px solid;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}
