.list-item {
  padding: 16px 24px;
  margin: 0 -24px;
  cursor: pointer;

  &[data-selected='true'] {
    background-color: var(--state-selected);
  }

  &[data-hovered='true'] {
    background-color: var(--elevation-low);
  }

  &[data-hovered='true'][data-selected='true'] {
    background-color: var(--state-selected-hover);
  }
}

.search {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 6px 0;

  input {
    width: 100%;
    padding: 4px 8px;
    background-color: var(--elevation-default);
    border: none;
    border-radius: 4px;
  }

  svg {
    color: var(--content-secondary);
  }
}
