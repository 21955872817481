.cell-container {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.icons-container {
  position: relative;
  width: 16px;
  height: 16px;
}

.comment-icon {
  color: var(--content-tertiary);
}

.status-icon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 6px;
  height: 6px;
  color: var(--content-accent);
}
