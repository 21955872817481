.wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
}

.table-header {
  position: sticky;
  top: 0;
  z-index: var(--z-index-default);
}

.table-wrapper {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 400px;

  tr {
    th:nth-last-child(2) {
      text-align: right;
    }
  }

  tr {
    td:nth-last-child(2) {
      text-align: right;
    }
  }
}

.empty-header {
  width: 48px;
  border-bottom: 1px solid var(--border-tertiary);
  border-top: 1px solid var(--border-tertiary);
}

.empty-cell {
  width: 48px;
}

.table {
  width: 100%;
  border-spacing: 0;
  background: var(--elevation-default);
  table-layout: fixed;
}

.button {
  padding: 0;
}

.header-cell {
  text-align: left;
  z-index: var(--z-index-default);
  height: 40px;
  white-space: nowrap;
  background: var(--elevation-default);
  border-bottom: 1px solid var(--border-tertiary);
  border-top: 1px solid var(--border-tertiary);
  composes: caption-bold from global;
  padding: 0;
  color: var(--content-secondary);

  &.active {
    color: var(--content-primary-a);
  }
}

.table-row {
  height: 64px;
}

.table-row-hover {
  &:hover {
    cursor: pointer;
    background-color: var(--elevation-low);
  }
}

.table-cell {
  padding: 8px 0;
  color: var(--content-primary-a);
  white-space: nowrap;
  border-bottom: 1px solid var(--border-tertiary);
}
