.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 504px;
  text-align: center;
}

.illustration {
  width: 200px;
  height: 200px;
}
