.code {
  width: 112px;
  height: 56px;
  padding: 0 0 4px 16px;
  letter-spacing: 14px;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
}

.code[type='text'] {
  padding-bottom: 1px;
  letter-spacing: 10px;
}

.error {
  border-color: var(--border-error);
}

.error-message {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: var(--content-error);
}

.input-wrapper {
  position: relative;
}

.input-container {
  display: flex;
  align-items: center;
  text-align: center;
}
