.row {
  height: 64px;
  vertical-align: middle;
}

.table-header {
  height: 40px;
  vertical-align: middle;
}

.empty {
  width: 48px;
}

.col {
  border-bottom: 1px solid var(--border-tertiary);
}

.header {
  display: flex;
  align-items: center;
  padding-right: 24px;
}

.block {
  min-width: 32px;
  min-height: 32px;
  margin-right: 16px;
}

.col-5 {
  width: 20.83333%;
}

.col-6 {
  width: 25%;
}

.col-9 {
  width: 37.5%;
}

.col-12 {
  width: 50%;
}

.hide {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
