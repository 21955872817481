.input-field {
  padding: 8px 12px;
  font-size: var(--body-1-font-size);
  font-weight: var(--body-1-font-weight);
  line-height: 22px;
  color: var(--content-primary-a);
  background-color: var(--elevation-default);
  border: 1px solid var(--border-secondary);
  border-radius: 4px;
  transition: border-color 0.5s;

  &::placeholder {
    line-height: 24px;
    color: var(--content-tertiary);
    opacity: 1;
  }

  &:focus-visible {
    border-color: var(--border-accent-high);
  }
}

.input-field[data-disabled] {
  cursor: not-allowed;
  background-color: var(--elevation-low);
  opacity: 0.45;
}

.input-field[data-invalid] {
  border-color: var(--border-error);
}

.input-field[readonly] {
  background-color: var(--elevation-low);
}
