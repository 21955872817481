.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 290px; /* copied from Figma, fixed on purpose (side-scroll) */
  min-height: 319px;
  padding: 24px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
  box-shadow: var(--shadow-low);
  transition: box-shadow var(--transition-duration-short) ease-in-out;
  background-color: var(--elevation-mid);
  color: var(--content-primary-a);

  &:hover {
    box-shadow: var(--shadow-high);
  }
}

.container h3 {
  margin: 0 0 12px;
}

.color-secondary {
  color: var(--content-secondary);
}

.crossed {
  text-decoration: line-through;
}

.price {
  line-height: 1.5;
}

.button {
  display: flex;
  align-items: end;
}
