.container {
  display: flex;
  gap: 12px;
  align-items: center;
  align-self: stretch;
  padding: 8px 16px;

  &:active::after {
    --overlay-opacity: var(--hover-opacity);
  }
}

.filename,
.filename:hover {
  text-decoration-line: underline;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.action {
  right: 0;
  height: unset;
  padding: 2px;
  margin-right: 16px;
  opacity: 0;
}

.container:hover .action,
.action:focus-within {
  opacity: 1;
}

.button {
  width: 28px;
  height: 28px;
}
