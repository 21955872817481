.table-container {
  flex-grow: 1;
  margin-top: 28px;
  overflow-y: auto;

  > div > div {
    max-height: calc(100vh - 425px) !important;
  }

  [data-dropdown-button] {
    display: none;
  }

  tr:hover [data-dropdown-button] {
    display: block;
  }

  tr:hover [data-more-button] {
    display: none;
  }

  .isOpened {
    [data-more-button] {
      display: none;
    }

    [data-dropdown-button] {
      display: block;
    }
  }
}

.buttonContent {
  width: 100%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.menu {
  width: 208px;
  color: var(--primary-content-a);
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.menuItem {
  position: relative;
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px 16px;
  overflow: hidden;
  color: var(--content-primary-a);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: none;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    background-color: var(--overlay-primary-a-color);
    opacity: var(--overlay-opacity);
  }

  &:hover::after,
  &:focus::after {
    --overlay-opacity: var(--hover-opacity);
  }
}

.menuDelete {
  color: var(--content-error);
}
