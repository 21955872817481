.placeholder {
  position: relative;
  padding: 14px 48px;
  background-color: var(--elevation-default);
  border-top: 1px solid var(--border-tertiary);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left,
.right {
  display: flex;
  gap: 16px;
  align-items: center;
}

.buttons {
  flex-shrink: 0;
}
