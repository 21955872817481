.insetBox {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  height: 208px;
  justify-content: space-between;
  padding: 24px;
}

.insetBox img {
  width: 160px;
}

.separator {
  color: var(--gray400);
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.separationBar {
  border-left: 1px solid var(--gray300);
  width: 0;
  flex-grow: 1;
}

.separationText {
  height: 27px;
  line-height: 27px;
}

.steps {
  gap: 16px;
}

.regenerateLink {
  padding: 0;
  border: none;
  background-color: transparent;
  margin-left: 4px;
  cursor: pointer;
}

.backButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinnerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
