.title {
  composes: caption-bold from global;
  padding-bottom: 18px;
  color: var(--content-secondary);
}

.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.select [data-placeholder='true'] {
  color: var(--content-tertiary);
}

.trigger {
  display: flex;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  max-width: 100%;
  padding: 8px 12px;
  background-color: var(--elevation-default);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;

  .select-placeholder {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:global(.overlay[disabled]::after) {
    --overlay-primary-a-color: transparent;
  }
}

.search {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 16px;
  margin-bottom: 8px;
  background-color: var(--elevation-high);
  border-bottom: 1px solid var(--border-tertiary);

  svg {
    color: var(--content-secondary);
  }
}

.arrow-icon {
  margin-left: auto;
}

.popover {
  width: 360px;
  height: 230px;
  overflow: auto;
  background-color: var(--elevation-high);
  border-radius: 4px;
  box-shadow: var(--shadow-high);
}

.input {
  width: 100%;
  padding: 4px 8px;
  background-color: var(--elevation-high);
  border: none;
  border-radius: 4px;
}
