.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  max-width: 504px;
  text-align: center;
}

.lottie {
  width: 200px;
  height: 200px;
}
