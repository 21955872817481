.details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  width: 100%;
}

.item {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.wrapper {
  padding: 16px;
  border: solid 1px var(--border-tertiary);
  border-radius: 6px;
}
