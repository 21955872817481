.amount-field {
  width: 100%;

  span {
    max-width: 100%;
  }

  label {
    margin-bottom: 8px;
  }
}

.condition-selector {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.condition-type {
  width: 148px;
}

.condition-operator {
  margin: 8px 16px 0 16px;
  white-space: nowrap;
}

.condition-value {
  width: 100%;
  min-width: 0;
}
