.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: var(--content-primary-a);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 888px;
  height: 100%;
  min-height: 100%;
  max-height: 500px;
}

.compact {
  gap: 24px;
  width: auto;
}

.full-width {
  width: 100%;
}

.left {
  max-width: 416px;
}

.right-image {
  width: auto;
  height: 193px;
}

.right-lottie {
  width: 193px;
  height: 193px;
}

.badge {
  width: max-content;
  margin-bottom: 28px;
}

.badge-icon {
  width: 10px;
  height: 10px;
  margin-right: 4px;

  path {
    fill: currentcolor;
  }
}

.animation {
  opacity: 1;
  transition: opacity 0.2s ease;
  will-change: opacity;

  &.hidden {
    opacity: 0;
  }
}
