.table {
  width: 100%;
  border-collapse: collapse;
}

.row {
  height: 64px;
  min-height: 64px;
}

.cell {
  padding: 8px 0;
  border-bottom: 1px solid var(--border-tertiary);
  border-top: 1px solid var(--border-tertiary);
}

.cell:first-child {
  padding-left: 48px;
}

.cell:last-child {
  padding-right: 48px;
  text-align: right;
}

.counterPartyContainer {
  display: flex;
  justify-content: start;
  gap: 16px;
  align-items: center;
}

.counterPartyNameSkeleton {
  margin-top: 0;
}

.balanceSkeletonContainer {
  display: flex;
  justify-content: end;
}
