.free {
  display: inline-flex;
  align-items: center;

  .icon {
    width: 12px;
    height: 12px;
    margin-left: 4px;
    color: var(--content-secondary);
  }
}
