.header {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  width: 100%;
  padding: 32px 48px;
}

.title-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tooltip {
  display: -webkit-box;
  max-width: 180px;
  margin-left: 81px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.btn {
  flex-shrink: 0;
}

.btn-disabled {
  appearance: none;
  pointer-events: auto;

  &:hover {
    background-color: var(--bg-color);
    opacity: var(--disabled-opacity);
  }

  &::after {
    background-color: var(--overlay-primary-b-color);
    border-radius: 4px;

    --overlay-primary-a-color: var(--primary-white);
    --overlay-opacity: var(--disabled-opacity);
  }
}
