.card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: center;
  width: 240px;
  height: 134px;
  padding: 24px;
  background: var(--elevation-mid);
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.managers {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
}
