.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  width: 100%;
  padding: 0 24px;
  overflow: hidden;
}

.title {
  composes: caption-bold from global;
  position: sticky;
  top: 0;
  z-index: 1;
  color: var(--content-secondary);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  align-self: stretch;
  padding: 0;
  overflow-y: auto;
  list-style: none;
}

.list::-webkit-scrollbar {
  display: none;
}
