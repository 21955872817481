.title {
  margin: 0;
  margin-bottom: 8px;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;

  .image {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    align-self: flex-start;
  }

  span {
    align-self: center;
  }
}
