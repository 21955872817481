.status {
  padding: 24px;
}

.status-title {
  margin-bottom: 14px;
  color: var(--content-secondary);
}

.address {
  color: var(--content-accent);
}

.info {
  margin-left: 4px;
  color: var(--content-primary-a);
}

.blurred {
  color: var(--content-tertiary);
}

.title {
  display: flex;
  color: var(--content-primary-a);
}

.description {
  max-width: 280px;
  color: var(--content-secondary);
}
