.label-tile {
  display: flex;
  flex-direction: column;
  min-width: 352px;
  padding: 16px;
  border: 1px solid var(--border-tertiary);
  border-radius: 4px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.actions button {
  white-space: no-wrap;
}
