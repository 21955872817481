.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.lottie {
  max-width: 120px;
  max-height: 120px;

  &.lg {
    max-width: 200px;
    max-height: 200px;
  }
}
