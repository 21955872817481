.section {
  padding: 24px;
  border-bottom: 1px solid var(--border-tertiary);
}

.title {
  composes: caption-bold from global;
  padding-bottom: 18px;
  color: var(--content-secondary);
}
