.container {
  width: 100%;
}

.label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  color: var(--content-primary-a);

  &.disabled {
    color: var(--content-tertiary);
  }
}

.small {
  composes: caption from global;
  display: flex;
  justify-content: space-between;
}

.large {
  composes: body-2 from global;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
}

.caption {
  word-break: break-word;
}

.amount.right-aligned {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.amount.expanded {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  .amount--current {
    position: absolute;
    left: 0;
  }

  .amount--total {
    position: absolute;
    right: 0;
  }
}

.amount--sep {
  margin: 0 4px;
}
