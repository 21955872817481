.button {
  width: 24px;
  height: 24px;
}

.uploading {
  color: var(--content-secondary);
}

.file {
  color: var(--content-tertiary);
}
