.overlayContent {
  position: relative;
  top: 50%;
  display: flex;
  gap: 24px;
  width: fit-content;
  text-align: right;
  transform: translateY(-50%);
}

.heading {
  composes: title-4 from global;
  color: var(--content-primary);
}

.subHeading {
  composes: caption from global;
  color: var(--content-secondary);
}

.cta {
  align-self: center;
  width: max-content;
}
