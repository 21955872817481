.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 48px 0;
  margin: auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1128px;
  padding: 0 48px;
  margin-bottom: 32px;
}

.title {
  composes: title-1 from global;
  margin-bottom: 32px;
  color: var(--content-primary-a);
}

.video-wrapper {
  display: flex;
  margin-right: 24px;

  video {
    border-radius: 4px;
  }
}

.content-block {
  padding: 16px 24px;
}
