.cash-flow-sidepanel-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 24px 16px;
}

.cash-flow-sidepanel {
  z-index: calc(var(--z-index-above));
}
