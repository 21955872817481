.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 504px;
  height: 100%;
  margin: auto;
  text-align: center;
}

.lottie {
  width: 310px;
  height: 310px;
  margin-right: auto;
  margin-left: auto;
}

.title {
  margin-top: -20px;
}
