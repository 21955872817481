.basket-summary {
  & :is(td, th) {
    padding: 16px;

    &:last-child {
      padding-right: 0;
    }
  }

  .subtotal-value,
  .vat-value,
  .total-value {
    text-align: right;
  }

  .subtotal-label,
  .vat-label,
  .total-label {
    text-align: right;
  }

  .total-row {
    background-color: var(--background-tertiary);
    font-weight: 600;
  }

  .empty-cell {
    display: none;
  }

  @media only screen and (width >= 768px) {
    & :is(td, th) {
      &:last-child {
        padding-right: 16px;
      }
    }
  }

  @media only screen and (width >= 1369px) {
    .subtotal-label,
    .vat-label,
    .total-label {
      text-align: left;
    }

    .empty-cell {
      display: revert;
    }
  }

  /* Preview mode needs to apply desktop styles regardless of screen size */
  &.preview {
    & :is(td, th) {
      &:last-child {
        padding-right: 16px;
      }
    }

    .subtotal-label,
    .vat-label,
    .total-label {
      text-align: left;
    }

    .empty-cell {
      display: revert;
    }
  }
}
